import { NgModule } from '@angular/core';
import { KpiService } from '@app/modules/finish-phase/state/kpi/kpi.service';
import { SetupFooterService } from '@app/shared/components/setup-footer/setup-footer.service';
import { SharedModule } from '@app/shared/shared.module';
import { EndComponent } from './containers/end/end.component';
import { OverviewComponent } from './containers/overview/overview.component';
import { FinishPhaseComponent } from './finish-phase.component';
import { FinishPhaseService } from './services';
import { KpiQuery } from './state/kpi/kpi.query';
import { KpiStore } from './state/kpi/kpi.store';
import { DismountContainerModalComponent } from './containers/overview/components/dismount-container-modal';

@NgModule({
  declarations: [FinishPhaseComponent, OverviewComponent, EndComponent, DismountContainerModalComponent],
  imports: [SharedModule],
  providers: [SetupFooterService, KpiService, KpiQuery, KpiStore, FinishPhaseService]
})
export class FinishPhaseModule {}
