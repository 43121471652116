<section class="run-phase">
  <header>
    <app-phase-header></app-phase-header>
  </header>
  <div class="content">
    <div class="p-grid p-nogutter">
      <div class="header p-col-12 p-grid p-nogutter">
        <div class="p-col-4 z-layer">
          <div class="run-phase__header">
            <i [ngClass]="inputPalletsExpanded ? 'icon-collapse-all' : 'icon-expand-all'" (click)="expandInputPallets()"></i>
            <h2 class="run-phase__title">{{ 'RUN_PHASE.INPUT' | translate }}</h2>
          </div>
        </div>
        <div class="p-col-1">
          <app-counter-header [counter]="counter$ | async"></app-counter-header>
        </div>
        <div class="p-col-7 z-layer">
          <div class="run-phase__header">
            <i [ngClass]="outputPalletsExpanded ? 'icon-collapse-all' : 'icon-expand-all'" (click)="expandOutputPallets()"></i>
            <h2 class="run-phase__title">{{ 'RUN_PHASE.OUTPUT' | translate }}</h2>
            <div class="run-phase__search-bar">
              <app-search-bar
                [searchBarPlaceholder]="'RUN_PHASE.SEARCH_BAR_PLACEHOLDER' | translate"
                (searchBarValueChanged)="filterOutputContainersList($event)"
              ></app-search-bar>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-4 z-layer component-wrapper--left">
        <app-input></app-input>
      </div>
      <div class="p-col-1">
        <app-counter></app-counter>
      </div>
      <div class="p-col-7 z-layer component-wrapper--right">
        <app-output></app-output>
      </div>
    </div>
  </div>
</section>
