<div class="quantity-pool">
  <div class="pallet-card">
    <div class="pallet-card__body pallet-card__body_margin">
      <lib-pallet-number class="pallet-card__number"></lib-pallet-number>

      <div class="quantity">
        <div class="quantity__wrapper">
          <div class="quantity__label">{{ 'PALLET_CARD.GROSS_QUANTITY' | translate }}</div>
          <div class="quantity__value quantity_pool_value">
            {{ grossQuantity | formatQuantity: false }}
            <span class="quantity__units">{{ grossQuantity.unitId }}</span>
          </div>
        </div>
      </div>
      <div class="quantity">
        <div class="quantity__wrapper">
          <div class="quantity__label">{{ 'PALLET_CARD.QUANTITY_POOL' | translate }}</div>
          <div class="quantity__value quantity_pool_value">
            {{ quantityPool | formatQuantity: false }}
            <span class="quantity__units"></span>
          </div>
        </div>
      </div>
      <div class="quantity">
        <div class="quantity__wrapper">
          <div class="quantity__label">{{ 'PALLET_CARD.TARGET_QUANTITY' | translate }}</div>
          <div class="quantity__value quantity_pool_value">
            {{ targetQuantity | formatQuantity: false }}
            <span class="quantity__units"></span>
          </div>
        </div>
      </div>

      <div *ngIf="showButtons" class="pallet-card__actions">
        <div class="form-group">
          <app-split-button class="quantity-pool__action" [loadingTopic]="LOADING_TOPIC" [items]="poolButtons"></app-split-button>
        </div>
      </div>
    </div>
  </div>
</div>
