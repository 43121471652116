import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ActiveWorkCenterService, WorkCenterDsService } from '@app/core/workcenter';
import { nav } from '@app/shared/utils';
import { WorkCenterData } from 'chronos-core-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkCenterGuard implements CanActivateChild {
  private readonly WORK_CENTER_QUERY = 'wcid';

  constructor(
    private workCenterDsService: WorkCenterDsService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private router: Router
  ) {}

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<UrlTree> {
    const externalWorkCenterId = route.queryParamMap.get(this.WORK_CENTER_QUERY);

    if (externalWorkCenterId) {
      return this.workCenterDsService.getWorkCenters().pipe(
        map((workCenters) => this.isWorkCenterAvailable(workCenters, externalWorkCenterId)),
        map((workCenter) => this.redirectToWorkCenter(workCenter, state))
      );
    } else {
      return true;
    }
  }

  private isWorkCenterAvailable(workCenters: WorkCenterData[], externalId: string): WorkCenterData {
    return workCenters.find((workCenter) => workCenter.externalWorkCenterId === externalId);
  }

  private redirectToWorkCenter(workCenter: WorkCenterData, state: RouterStateSnapshot): UrlTree {
    if (workCenter) {
      const path = state.url.split('?')[0];
      this.activeWorkCenterService.setWorkCenterId(workCenter.id);

      return this.router.createUrlTree([path]);
    } else {
      return this.router.createUrlTree([nav.workCenterSelection]);
    }
  }
}
