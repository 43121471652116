import { ResourceConfigurationService } from '@app/modules/resource-configuration/services';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Setting, SettingValuesViewModel, SettingEntityInfoViewModel, SettingValueRaw } from 'chronos-core-client';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { AppSettingsQuery, LoadingNotificationService, LogService } from 'chronos-shared';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationHistoryComponent } from '../../components/configuration-history';
import { ImportSettingModalComponent } from '../../components/import-setting-modal';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { FormStatus, SettingModel } from '../../models';
import { notificationTopic } from '@app/shared/utils';
import { HeaderService } from '@app/core/services';

@Component({
  selector: 'app-resource-configuration',
  templateUrl: './resource-configuration.component.html',
  styleUrls: ['./resource-configuration.component.scss']
})
export class ResourceConfigurationComponent implements OnInit, OnDestroy {
  public settingsValues$: Observable<SettingValuesViewModel[]>;
  public settingEntityInfo: SettingEntityInfoViewModel;
  public defaultSettingList: Setting[];
  public isAdmin = false;
  public selectedWorkCenter: string;
  public enableEditSettingSubject = new BehaviorSubject<boolean>(true);
  public enableEditSetting$: Observable<boolean> = this.enableEditSettingSubject.asObservable();
  public form: UntypedFormGroup;
  public childFormsValid: FormStatus[] = [];
  public isFormValid = true;
  public isUpdating = false;
  public isDisabled: boolean;
  public isError = false;

  private readonly DEFAULT_HIERARCHY_TYPE = 'WorkCenter';
  private readonly EXPORT_SETTING_FILENAME = 'ExportedSettings';
  public readonly LOADING_TOPIC_UPDATE = notificationTopic.updateResourceSettings;

  private tenantName: string;
  private entityName: string;
  private workCenterGroupName: string;
  private workCenterName: string;
  private hierarchyLevel: string;
  private settingValues: SettingValueRaw[] = [];

  private subscriptions = new Subscription();

  constructor(
    private resourceConfigurationService: ResourceConfigurationService,
    private appSettingsQuery: AppSettingsQuery,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private headerService: HeaderService,
    private formBuilder: UntypedFormBuilder
  ) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({});

    this.subscriptions.add(
      this.resourceConfigurationService
        .getSettings()
        .pipe(
          tap((list) => {
            this.defaultSettingList = list;
          })
        )
        .subscribe(() => {
          this.setErrorFlag(false);
        },
        (error) => {
          LogService.error('ERROR_MESSAGE.UNABLE_TO_FETCH_SETTINGS', error);
          this.setErrorFlag(true);
        })
    );

    this.subscriptions.add(
      this.appSettingsQuery.isEnableResourceConfiguration$
        .pipe(
          tap((value: boolean) => {
            this.isAdmin = value;
          })
        )
        .subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.enableWorkCenter();
    this.subscriptions.unsubscribe();
  }

  public workCenterChange(data: any): void {
    this.settingValues = [];
    this.childFormsValid = [];
    this.isFormValid = true;

    this.tenantName = data.tenantEntity.name;
    this.hierarchyLevel = data.entity.type;
    this.entityName = data.entity.name;

    if (this.hierarchyLevel === this.DEFAULT_HIERARCHY_TYPE) {
      this.workCenterName = data.entity.name;
      this.workCenterGroupName = data.entity.parentKey.split('-')[1];
    } else if (this.hierarchyLevel !== data.tenantEntity.type) {
      this.workCenterName = '';
      this.workCenterGroupName = data.entity.name;
    } else {
      this.workCenterName = '';
      this.workCenterGroupName = '';
    }

    this.settingsValues$ = this.resourceConfigurationService.getSettingValues(
      this.DEFAULT_HIERARCHY_TYPE,
      data.entity.type,
      data.entity.name
    );

    if(this.settingsValues$){
      this.settingsValues$.subscribe((settingValues) => {
        console.info(settingValues);
        this.setErrorFlag(false);
      },
      (error) => {
        LogService.error('ERROR_MESSAGE.UNABLE_TO_FETCH_SETTING_VALUES', error);
        this.setErrorFlag(true);
      });
    }

    this.resourceConfigurationService
      .getSettingEntityInfo(this.DEFAULT_HIERARCHY_TYPE, data.entity.type, data.entity.name)
      .subscribe((settingHierarchy) => {
        this.settingEntityInfo = settingHierarchy;
        this.setErrorFlag(false);
      },
      (error) => {
        LogService.error('ERROR_MESSAGE.UNABLE_TO_FETCH_SETTING_ENTITY_INFO', error);
        this.setErrorFlag(true);
      });
  }

  public viewHistory(setting: any) {
    this.dialogService.open(ConfigurationHistoryComponent, {
      header: this.translateService.instant('RESOURCE_CONFIGURATION.HISTORY'),
      data: {
        historySetting: setting,
        tenantName: this.tenantName,
        workCenterGroupName: this.workCenterGroupName,
        workCenterName: this.workCenterName,
        hirarchyLevel: this.hierarchyLevel
      }
    });
  }

  public updateSetting(setting: SettingModel) {
    this.updateSettingBatchItem(setting);
  }

  public formStatusChange(status: FormStatus) {
    const existingIndex = this.childFormsValid.findIndex((formStatus) => formStatus.settingId === status.settingId);
    if (existingIndex !== -1) {
      this.childFormsValid[existingIndex] = status;
    } else {
      this.childFormsValid.push(status);
    }

    this.isFormValid = this.childFormsValid.every((validStatus) => validStatus.status);
  }

  public importSetting() {
    this.dialogService
      .open(ImportSettingModalComponent, {
        header: this.translateService.instant('RESOURCE_CONFIGURATION.IMPORT_CONFIGURATION')
      })
      .onClose.subscribe((isImported) => {
        if (isImported) {
          this.settingsValues$ = this.resourceConfigurationService.getSettingValues(
            this.DEFAULT_HIERARCHY_TYPE,
            this.hierarchyLevel,
            this.entityName
          );
        }
      });
  }

  public editSetting(): void {
    this.headerService.enableDisbaledWorkCenter(true);
    this.enableEditSettingSubject.next(false);
    this.isDisabled = true;
  }

  public save(): void {
    this.isUpdating = true;
    LoadingNotificationService.publish(this.LOADING_TOPIC_UPDATE, true);
    this.resourceConfigurationService
      .updateSettingsBatch(this.hierarchyLevel, this.entityName, this.settingValues, '')
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC_UPDATE, false);
          this.enableEditSettingSubject.next(true);
          this.isUpdating = false;
          this.isDisabled = false;
          this.enableWorkCenter();
        })
      )
      .subscribe(() => {
        LogService.success('SUCCESS_MESSAGE.UPDATED_SUCCESSFULLY');
        this.setErrorFlag(false);
      }, (error) => {
        LogService.error('ERROR_MESSAGE.UNABLE_TO_UPDATE_SETTING', error);
        this.setErrorFlag(true);
      });
  }

  public cancel(): void {
    this.enableWorkCenter();
    this.enableEditSettingSubject.next(true);
    this.isDisabled = false;
  }

  public exportSetting(): void {
    this.resourceConfigurationService
      .exportSettings()
      .pipe(
        tap((val) => {
          this.downloadCsv(val.csvFileContent, this.EXPORT_SETTING_FILENAME);
        })
      )
      .subscribe();
  }

  private enableWorkCenter(): void {
    this.headerService.enableDisbaledWorkCenter(false);
  }

  private downloadCsv(content: string, fileName: string): void {
    const blob = new Blob([content], { type: 'text/csv' });

    const link = document.createElement('a'); // html anchor element
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private updateSettingBatchItem(setting: SettingModel): void {
    const existingIndex = this.settingValues.findIndex((row) => row.settingId === setting.settingId);

    const item: SettingValueRaw = {
      boolValue: setting.boolValue ?? null,
      dateTimeValue: setting.dateTimeValue ?? null,
      intValue: setting.intValue ?? null,
      settingId: setting.settingId ?? null,
      stringValue: (setting.stringValue || setting.enumValue || setting.enumValueMultiselect?.join(',')) ?? null,
      timeSpanValue: setting.timeSpanValue ?? null
    };

    if (existingIndex !== -1) {
      this.settingValues[existingIndex] = item;
    } else {
      this.settingValues.push(item);
    }
  }

  private setErrorFlag(isError: boolean): void {
    this.isError = isError;
  }
}
