import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap, switchMap } from 'rxjs/operators';
import { nav } from '@app/shared/utils';
import { WebSocketClientService, PeriodicQaCheck } from 'chronos-core-client';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ActiveOrderQuery } from '@app/core/global-state';
import { ShutdownModalComponent } from '@app/shell/components';
import { ActiveOrderDsService } from '@app/core/data-services';
import { AppSettingsQuery, ModalConfirmComponent } from 'chronos-shared';
import { AutomaticPeriodicQaCheckComponent } from '@app/modules/run-phase/containers/output/components/automatic-periodic-qa-check/automatic-periodic-qa-check.component';
import { QaCheckService } from '@app/core/services';
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, OnDestroy {
  public asideEnabled = true;

  private subscriptions = new Subscription();
  private shutdownModal: DynamicDialogRef;
  private connectionModal: DynamicDialogRef;
  private intervalSeconds = 60;
  private timeLeft = this.intervalSeconds;
  private interval;
  public productionOrderId = null;
  private isDialogOpen = false;
  private runId = null;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private webSocketClientService: WebSocketClientService,
    private dialogService: DialogService,
    private appSettingsQuery: AppSettingsQuery,
    private activeOrderDsService: ActiveOrderDsService,
    private activeOrderQuery: ActiveOrderQuery,
    private qaCheckService: QaCheckService
  ) {}

  public ngOnInit(): void {
    this.isAsideEnabled(this.router.url);
    this.subscriptions.add(
      this.webSocketClientService
        .isDisconnected()
        .pipe(filter((disconnected) => disconnected && !this.connectionModal))
        .subscribe(() => {
          this.openModalForConnectionLost();
        })
    );
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          tap((navigationEndEvent: NavigationEnd) => {
            this.isAsideEnabled(navigationEndEvent.url);
          })
        )
        .subscribe()
    );

    let activeOrder = this.activeOrderQuery.getActiveOrder();
    this.productionOrderId = activeOrder?.productionOrderId;
    this.runId = activeOrder?.runId;

    this.subscriptions.add(this.activeOrderDsService.getActiveOrderNotifications()?.subscribe());

    this.subscriptions.add(
      this.appSettingsQuery.workCenterState$?.subscribe((state) => {
        this.openModalForShutdown(state?.isTurnedOn);
      })
    );

    this.subscriptions.add(
      this.activeOrderQuery.activeOrderId$
        .pipe(
          filter((productionOrderId) => !!productionOrderId),
          switchMap((productionOrderId) => {
            if (!activeOrder) {
              activeOrder = this.activeOrderQuery.getActiveOrder();
              this.productionOrderId = activeOrder?.productionOrderId;
              this.runId = activeOrder?.runId;
            }
            return this.activeOrderDsService.getAutomaticQACheckNotifications(productionOrderId);
          })
        )
        .subscribe((newCheck) => {
          this.getDialogForNewCheck(newCheck);
        })
    );

    this.openPeriodicQACheckDialog(this.productionOrderId);
  }

  private getDialogForNewCheck(newCheck: PeriodicQaCheck) {
    const activeRunId = newCheck.runId === this.runId;
    if (newCheck && !this.isDialogOpen && !this.qACheckIgnoredUrls(this.router.url) && activeRunId) {
      this.pauseTimer();
      this.isDialogOpen = true;
      this.openPeriodicQACheckDialog(this.productionOrderId);
    }
  }

  private startTimer(): void {
    this.isDialogOpen = false;
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        if (!this.qACheckIgnoredUrls(this.router.url)) {
          this.pauseTimer();
          this.openPeriodicQACheckDialog(this.productionOrderId);
        }
      }
    }, 1000);
  }

  private qACheckIgnoredUrls(url: string): boolean {
    if (
      url.indexOf('approve') > 0 ||
      url.indexOf('mounting/overview') > 0 ||
      url.indexOf('mounting/primary') > 0 ||
      url.indexOf('mounting/secondary') > 0 ||
      url.indexOf('mounting/trace') > 0 ||
      url.indexOf('operation-support') > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  private pauseTimer(): void {
    clearInterval(this.interval);
    this.timeLeft = this.intervalSeconds;
  }

  public openPeriodicQACheckDialog(actveProductionId: number): void {
    const activeOrder = this.activeOrderQuery.getActiveOrder();
    if (!actveProductionId) {
      return;
    }

    this.qaCheckService.getActivePeriodicCheck(actveProductionId).subscribe((qaChecks) => {
      if (qaChecks && qaChecks.runId === activeOrder.runId && !this.qACheckIgnoredUrls(this.router.url)) {
        const modal = this.dialogService.open(AutomaticPeriodicQaCheckComponent, {
          header: this.translateService.instant('ACTIVE_ORDER.PERIODICQACHECKS_TITLE'),
          data: {
            productionOrderId: actveProductionId
          },
          closable: false
        });
        modal.onClose.subscribe((result) => {
          if (!result) {
            this.startTimer();
            return;
          }
          if (result) {
            return (this.isDialogOpen = false);
          }
        });

        this.isDialogOpen = true;
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private isAsideEnabled(url: string): void {
    this.asideEnabled = url !== nav.routes.approve;
  }

  private openModalForConnectionLost(): void {
    this.connectionModal = this.dialogService.open(ModalConfirmComponent, {
      header: this.translateService.instant('WEB_SOCKET.CONNECTION_LOST'),
      data: {
        question: this.translateService.instant('WEB_SOCKET.MESSAGE'),
        acceptable: true
      }
    });
    this.connectionModal.onClose.pipe(filter((accepted) => !!accepted)).subscribe(() => {
      location.reload();
    });
    this.connectionModal.onDestroy.subscribe(() => (this.connectionModal = null));
  }

  private openModalForShutdown(isTurnedOn: boolean): void {
    if (isTurnedOn) {
      this.shutdownModal?.close();
      this.shutdownModal = null;
      return;
    }

    if (this.shutdownModal) {
      return;
    }

    this.shutdownModal = this.dialogService.open(ShutdownModalComponent, {
      showHeader: false,
      closable: false,
      closeOnEscape: false
    });
  }
}
