<div class="pallet-card">
  <div class="pallet-card__body">
    <lib-pallet-number class="pallet-card__number"></lib-pallet-number>
    <lib-kpi-control
      (click)="goToPrimaryTab()"
      [value]="amount | formatQuantity: false"
      [title]="'INPUT_QUANTITY_POOL.MOUNTED' | translate"
      [kpiStatus]="getKpiStatus()"
      class="kpi-control--link"
    ></lib-kpi-control>
  </div>
</div>
