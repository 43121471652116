<div class="order-item">
  <div class="order-item__inner section-padding" [ngClass]="{ 'order-item--next': orderItem.isNextProductionOrder }">
    <div class="p-grid">
      <div class="p-col-10 cursor-clickable" (click)="toggleContent()">
        <div class="p-grid">
          <div class="p-col-2">
            <div class="left-indent">
              <div class="status">
                <lib-status-flag
                  *let="STATUS_FLAGS[orderItem.status] as status"
                  class="status__item"
                  [styleClass]="status.labelClass"
                  [statusText]="status.text | translate"
                  size="tiny"
                ></lib-status-flag>
                <lib-status-flag
                  *ngIf="orderItem.isNextProductionOrder"
                  id="next-flag"
                  class="status__item"
                  [styleClass]="'status--next'"
                  [statusText]="'STATUS.NEXT' | translate"
                  size="tiny"
                ></lib-status-flag>
              </div>
              <div class="flag">
                <div *ngIf="orderItem.hasQualityNote" id="quality-note" class="flag__label">QA</div>
                <div *ngIf="orderItem.hasExternalSystemConnection" id="external-system-connection" class="flag__label flag__label--android">
                  <i class="icon-automated"></i>
                </div>
              </div>
              <div class="combination-icon">
                <i [ngClass]="combinationIcon"></i>
              </div>
            </div>
          </div>
          <div class="p-col-5">
            <app-product-order id="product-order" [orderItem]="orderItem" [isItemCollapsed]="isItemCollapsed"></app-product-order>
          </div>
          <div class="p-col-1 padding-0">
            <app-order-primary-material [machineSchedule]="orderItem"></app-order-primary-material>
          </div>
          <div class="p-col-3"></div>
          <div class="p-col-1">
            <div>
              <span>{{ 'ORDER_ITEM.PRE-SETUP' | translate }}</span>
              <i *ngIf="orderItem.isProductionOrderPresetup" class="icon-checked-solid presetup-checked-icon"></i>
            </div>
            <ng-container *ngIf="showInactive">
              <div class="quality-stamp">
                <div class="quality-stamp__grade">A</div>
                <div class="quality-stamp__value">7.042</div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="p-col-2">
        <div class="control-button" *ngIf="isItemCollapsed">
          <ng-container *ngTemplateOutlet="orderItemButtons"></ng-container>
        </div>
      </div>
    </div>
    <div [hidden]="isItemCollapsed" class="collapse-container">
      <div class="p-grid">
        <div class="p-col-2"></div>
        <div class="p-col-4">
          <app-additional-data [title]="'MOUNTING.ADDITIONAL_DATA' | translate">
            {{ orderItem.productionOrderComment }}
          </app-additional-data>
        </div>
        <div class="p-col-3">
          <div *ngIf="orderItem?.productionJobComment" class="attention-label">
            {{ orderItem.productionJobComment }}
          </div>
        </div>
        <div class="p-col-3">
          <dl class="target">
            <dt>{{ 'ORDER_ITEM.SETUP_TIME' | translate }}</dt>
            <dd>
              {{ orderItem.plannedSetupDuration | elapsedTime }}
            </dd>
            <dt>{{ 'ORDER_ITEM.TARGET_SPEED' | translate }}</dt>
            <dd>{{ orderItem.targetSpeed | formatQuantity : true : 0 }}</dd>
          </dl>
        </div>
      </div>
      <div class="order-item__footer">
        <ng-container *ngTemplateOutlet="orderItemButtons"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #orderItemButtons>
  <ng-container *ngIf="!isItemCollapsed">
    <div>
      <lib-input-switch
        [loadingTopic]="loadingTopic"
        [(ngModel)]="isChecked"
        [label]="'ORDER_ITEM.CHECKED' | translate"
        [disabled]="isCheckedDisabled()"
        (ngModelChange)="handleCheckChange()"
      ></lib-input-switch>
    </div>
  </ng-container>
  <div>
    <app-split-button class="order-item__button" [loadingTopic]="loadingTopic" [items]="orderButtons"></app-split-button>
  </div>
</ng-template>
