<section class="trace-table">
  <p-table [value]="tracePatternData">
    <ng-template pTemplate="header">
      <tr>
        <th class="material-table__text-cell">{{ 'SETUP_PATTERN_INFO.KNIFE' | translate }}</th>
        <th class="material-table__text-cell">{{ 'SETUP_PATTERN_INFO.TRACK' | translate }}</th>
        <th class="material-table__text-cell">{{ 'SETUP_PATTERN_INFO.POSITION' | translate }}</th>
        <th class="material-table__text-cell">{{ 'SETUP_PATTERN_INFO.WIDTH' | translate }}</th>
        <th class="material-table__text-cell">{{ 'SETUP_PATTERN_INFO.ORDER' | translate }}</th>
        <th class="material-table__text-cell">{{ 'SETUP_PATTERN_INFO.DIMENSION' | translate }}</th>
        <th class="material-table__text-cell">{{ 'SETUP_PATTERN_INFO.STATION' | translate }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td class="trace-table__text-cell" [attr.rowspan]="rowData.rowSpanKnife" *ngIf="rowData.showRowKnife">
          <span *ngIf="rowData.type === 'Track'">{{ rowData.crossKnifePositionId }}</span>
        </td>
        <td class="trace-table__text-cell">
          <span *ngIf="rowData.type === 'Track'">{{ rowData.trackSequenceNumber }}</span>
          <span *ngIf="rowData.type === 'ProcessingStation'">
            {{ 'PROCESSING_STATION_TYPE.' + rowData.processingStationType | uppercase | translate }}
          </span>
        </td>
        <td class="trace-table__text-cell">
          <span>{{ rowData.absolutePositionWidth.value }} {{ rowData.absolutePositionWidth.unitId }}</span>
        </td>
        <td class="trace-table__text-cell">
          <span *ngIf="rowData.type === 'Track'">{{ rowData.trackWidth.value }} {{ rowData.trackWidth.unitId }}</span>
        </td>
        <td class="trace-table__text-cell">
          <span *ngIf="rowData.type === 'Track'">
            {{ rowData.externalArticleId }} / {{ rowData.externalConfigurationId }} {{ rowData.batchId }}
          </span>
        </td>
        <td class="trace-table__text-cell">
          <span *ngIf="rowData.type === 'Track'">
            {{ rowData.trackWidth.value }} x {{ rowData.sheetLength.value }} {{ rowData.trackWidth.unitId }}
          </span>
        </td>
        <td class="trace-table__text-cell" [attr.rowspan]="rowData.rowSpanStation" *ngIf="rowData.showRowStation">
          <span *ngIf="rowData.type === 'Track'">{{ rowData.stationCode }}</span>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template #noData>
    <span></span>
  </ng-template>
</section>
