<section class="stations">
  <div class="stations__menu">
    <p-tabMenu [model]="outputStationTabs">
      <ng-template pTemplate="item" let-item let-i="index">
        <div class="ui-menuitem-text" [id]="item.automationId">
          {{ item.label | translate }}
        </div>
      </ng-template>
    </p-tabMenu>
  </div>
</section>
