import { Injectable } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import {
  HeaderKpi,
  ProductionOrderService,
  ProductionOrderSetupPhaseState,
  QuantityPool,
  WasteAssignment,
  WasteAssignmentService,
  WasteAssignmentType,
  WorkCenterService
} from 'chronos-core-client';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActiveOrderDataStore } from './active-order-data.store';

@Injectable({ providedIn: 'root' })
export class ActiveOrderDataService {
  constructor(
    private activeWorkCenterService: ActiveWorkCenterService,
    private workCenterService: WorkCenterService,
    private activeOrderDataStore: ActiveOrderDataStore,
    private productionOrderService: ProductionOrderService,
    private wasteAssignmentService: WasteAssignmentService
  ) {}

  public renewWasteAssignment(productionOrderId: number): Observable<WasteAssignment[]> {
    this.activeOrderDataStore.setLoading(true);
    const params: WasteAssignmentService.GetWasteAssignmentParams = {
      productionOrderId,
      wasteAssignmentType: WasteAssignmentType.SETUP_WASTE
    };
    return this.wasteAssignmentService.getWasteAssignment(params).pipe(
      tap((wasteAllocations: WasteAssignment[]) => {
        this.activeOrderDataStore.update({
          wasteAllocations
        });
        this.activeOrderDataStore.setLoading(false);
      })
    );
  }

  public getSetupPhaseStatus(productionOrderId: number): Observable<ProductionOrderSetupPhaseState> {
    this.activeOrderDataStore.setLoading(true);
    const param: ProductionOrderService.GetSetupPhaseStateParams = {
      productionOrderId,
      runId: null
    };
    return this.productionOrderService.getSetupPhaseState(param).pipe(
      tap((setupPhaseStatus) => {
        this.activeOrderDataStore.update({
          setupPhaseStatus
        });
        this.activeOrderDataStore.setLoading(false);
      })
    );
  }

  public updateSetupPhaseStatus(setupPhaseStatus: ProductionOrderSetupPhaseState): void {
    this.activeOrderDataStore.update({ setupPhaseStatus });
  }

  public deleteWasteAssignment(productionOrderId: number): Observable<WasteAssignment[]> {
    return this.renewWasteAssignment(productionOrderId);
  }

  public addWasteAssignment(productionOrderId: number): Observable<WasteAssignment[]> {
    return this.renewWasteAssignment(productionOrderId);
  }

  public getHeaderKpi(): void {
    this.productionOrderService.getHeaderKpi(this.activeWorkCenterService.getWorkCenterId()).subscribe((headerKpi) => {
      this.setHeaderKpi(headerKpi);
    });
  }

  public setHeaderKpi(headerKpi: HeaderKpi): void {
    this.activeOrderDataStore.update({ headerKpi });
  }

  public getQuantityPool(): Observable<QuantityPool> {
    return this.workCenterService.getQuantityPool(this.activeWorkCenterService.getWorkCenterId()).pipe(
      tap((response) => {
        this.updateQuantityPool(response);
      })
    );
  }

  public updateQuantityPool({ inputQuantity, outputQuantity, outputStationQuantities }: QuantityPool): void {
    this.activeOrderDataStore.update({
      inputQuantityPool: inputQuantity,
      outputQuantityPool: outputQuantity,
      outputStationsQuantityPool: outputStationQuantities
    });
  }
}
