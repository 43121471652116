import { Injectable } from '@angular/core';
import { ActiveOrderDsService, ActiveOrderPhaseDsService, QaHistoryDsService, PatternInfoDsService } from '@app/core/data-services';
import { ProductionOrder, ProductionPeriod, WorkCenterService } from 'chronos-core-client';
import { isNil } from 'ramda';
import { Observable, combineLatest } from 'rxjs';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { nav } from '@app/shared/utils';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class ModalProductionPeriodsService {
  constructor(
    private activeOrderPhaseDsService: ActiveOrderPhaseDsService,
    private workCenterService: WorkCenterService,
    private qaHistoryDsService: QaHistoryDsService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private activeOrderDsService: ActiveOrderDsService,
    private patternInfoDsService: PatternInfoDsService
  ) {}

  public startProductionOrderWithRoute(
    productionOrder: ProductionOrder,
    selectedPeriod: ProductionPeriod,
    manualTransport: boolean
  ): Observable<string> {
    const dateSelected = !isNil(selectedPeriod) ? selectedPeriod.periodStart : new Date().toISOString();

    return this.activeOrderPhaseDsService
      .enterSetupPhase(productionOrder, dateSelected, manualTransport)
      .pipe(
        switchMap(() =>
          combineLatest([
            this.qaHistoryDsService.isQaHistoryAvailableForSetup(),
            this.qaHistoryDsService.isLineClearanceEnableForSetup(),
            this.patternInfoDsService.isPatternAvailableForProductionOrder(productionOrder.productionOrderId)
          ]).pipe(
            map(([isQaHistoryAvailable, isLineClearanceAvailable, isPatternInfoAvailable]) =>
              this.getFirstPhaseRoute(isQaHistoryAvailable, isLineClearanceAvailable, isPatternInfoAvailable)
            )
          )
        )
      );
  }

  public startProductionOrderWithAutomaticPeriodSelection(productionOrder: ProductionOrder, manualTransport: boolean): Observable<string> {
    return this.activeOrderPhaseDsService
      .enterSetupPhaseWithAutomaticPeriodSelection(productionOrder, manualTransport)
      .pipe(
        switchMap(() =>
          combineLatest([
            this.qaHistoryDsService.isQaHistoryAvailableForSetup(),
            this.qaHistoryDsService.isLineClearanceEnableForSetup(),
            this.patternInfoDsService.isPatternAvailableForProductionOrder(productionOrder.productionOrderId)
          ]).pipe(
            map(([isQaHistoryAvailable, isLineClearanceAvailable, isPatternInfoAvailable]) =>
              this.getFirstPhaseRoute(isQaHistoryAvailable, isLineClearanceAvailable, isPatternInfoAvailable)
            )
          )
        )
      );
  }

  public setActiveOrderPhase(): Observable<boolean> {
    return this.activeOrderDsService.selectactiveOrderId().pipe(
      filter((activeOrderId) => !!activeOrderId),
      switchMap((activeOrderId) => this.activeOrderPhaseDsService.getFirstSetupPhase(activeOrderId).pipe(map(() => true)))
    );
  }

  private getFirstPhaseRoute(isQaHistory: boolean, isLineClearance?: boolean, isPatternInfoAvailable?: boolean): string {
    if (isLineClearance) {
      return nav.routes.setupLineClearance;
    }

    if (isQaHistory) {
      return nav.routes.setupQaHistory;
    }

    if (isPatternInfoAvailable) {
      return nav.routes.setupPatternInfo;
    }

    return nav.routes.setupParameters;
  }

  public getOpenPeriods(): Observable<ProductionPeriod[]> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.workCenterService.getOpenPeriods(workCenterId).pipe(take(1));
  }
}
