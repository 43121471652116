<form [formGroup]="wasteForm" class="waste-table">
  <div class="p-d-flex p-flex-nowrap p-nogutter waste-table__header add-row" [ngClass]="{ required: errorWasteNotDefined }">
    <div class="form-group--grow-1">
      <div class="form-group form-group--inline" [ngClass]="{ required: errorWasteOrMaculatureNotDefined }">
        <div class="col-form-label">{{ 'WASTE_ASSIGNMENT.MACULATURE' | translate }}</div>
        <div class="form-control form-control--number">
          <lib-input-number
            [min]="NUM_FIELD_MIN_VALUE"
            (touched)="onMaculatureBlur()"
            [inputStyleClass]="'add-row__maculature'"
            [inputStyle]="INPUT_STYLE"
            [formControlName]="'maculature'"
            [maxFractionDigits]="maxFractionDigits"
            [minFractionDigits]="minFractionDigits"
          ></lib-input-number>
        </div>
      </div>
    </div>
    <div class="form-group--grow-1">
      <div class="form-group form-group--inline" [ngClass]="{ required: errorWasteOrMaculatureNotDefined }">
        <div class="col-form-label">{{ 'WASTE_ASSIGNMENT.WASTE' | translate }}</div>
        <div class="form-control form-control--number">
          <lib-input-number
            [inputStyle]="INPUT_STYLE"
            [inputStyleClass]="'add-row__waste'"
            [formControlName]="'waste'"
            [min]="NUM_FIELD_MIN_VALUE"
            (touched)="onWasteBlur()"
            [maxFractionDigits]="maxFractionDigits"
            [minFractionDigits]="minFractionDigits"
          ></lib-input-number>
        </div>
      </div>
    </div>
    <div class="form-group--grow-1">
      <div class="form-group" [ngClass]="isSetupWastePhase === true ? 'setup-waste-unit' : 'waste-unit-span'">
        <span>{{ counterUnitId }}</span>
      </div>
    </div>
    <div class="form-group--grow-2">
      <div class="form-group form-group--inline">
        <div class="form-group--inline form-group--grow-1 form-group--padding" [ngClass]="{ required: errorWasteReasonNotDefined }">
          <div class="col-form-label">{{ 'WASTE_ASSIGNMENT.REASON' | translate }}</div>
          <div class="form-control form-control--select">
            <lib-dropdown
              class="add-row__reason"
              [showDefaultValue]="true"
              [defaultValueLabel]="'DROPDOWN_DEFAULT_LABEL' | translate"
              [options]="reasonOptions"
              [formControlName]="'reason'"
              [inlineStyle]="{ width: '180px' }"
            ></lib-dropdown>
          </div>
        </div>
        <lib-button
          class="add-row__button"
          [loadingTopic]="LOADING_TOPIC"
          [loadingIndicator]="false"
          [disabled]="isAddRowDisabled()"
          (clicked)="addRow()"
          [label]="'WASTE_ASSIGNMENT.OK'"
        ></lib-button>
      </div>
    </div>
  </div>

  <div class="p-grid p-nogutter waste-table__row waste-table__row--total waste-row--total" (click)="toggleData()">
    <div class="p-col-3">
      <div class="form-group form-group--inline">
        <div class="col-form-label no-left-margin">
          <i class="collapse-control-icon" [ngClass]="isDataShown ? 'icon-arrows-right-filled-close' : 'icon-arrow-right-filled-open'"></i>
          {{ 'WASTE_ASSIGNMENT.TOTAL' | translate }}
        </div>
        <div class="col-form-value" [ngClass]="{ 'total-mal-col-padding': isSetupWastePhase === true }">
          <ng-container *ngIf="wasteAndMaculature?.length > 0; else noWasteRecords">
            {{ getTotalMaculature() | number }}
          </ng-container>
          <ng-template #noWasteRecords>-</ng-template>
        </div>
      </div>
    </div>
    <div class="p-col-3" [ngClass]="isSetupWastePhase === true ? 'waste-col-padding' : 'run-waste-col-padding'">
      <div class="form-group form-group--inline">
        <div class="col-form-value">
          <ng-container *ngIf="wasteAndMaculature?.length > 0; else noWasteRecords">
            {{ getTotalWaste() | number }}
          </ng-container>
          <ng-template #noWasteRecords>-</ng-template>
        </div>
      </div>
    </div>
    <div class="p-col-1" [ngClass]="isSetupWastePhase === true ? 'unit-col-padding' : 'run-unit-col-padding'">
      <div class="form-group form-group--inline">
        <div class="col-form-value">
          {{ wasteAssignmentUnitId }}
        </div>
      </div>
    </div>
    <div class="p-col-5"></div>
  </div>

  <ng-container *ngIf="isDataShown">
    <div *ngFor="let item of wasteAndMaculature; trackBy: trackByFunction" class="p-grid p-nogutter waste-table__row waste-row">
      <div class="p-col-1">
        <div class="form-group form-group--inline">
          <div *ngIf="item.isAutoWaste" class="col-form-label">
            <i class="icon-automated icon"></i>
          </div>
        </div>
      </div>
      <div class="p-col-2" [ngClass]="{ 'mal-col-padding': isSetupWastePhase === true }">
        <div class="form-group form-group--inline">
          <div class="col-form-value">{{ item.maculature | formatQuantity : false }}</div>
        </div>
      </div>
      <div class="p-col-3" [ngClass]="isSetupWastePhase === true ? 'waste-col-padding' : 'run-waste-col-padding'">
        <div class="form-group form-group--inline">
          <div class="col-form-value">{{ item.waste | formatQuantity : false }}</div>
        </div>
      </div>
      <div class="p-col-1" [ngClass]="isSetupWastePhase === true ? 'unit-col-padding' : 'run-unit-col-padding'">
        <div class="form-group form-group--inline">
          <div class="col-form-value">{{ counterUnitId }}</div>
        </div>
      </div>
      <div class="p-col-5" [ngClass]="{ 'waste-reason-margin': isSetupWastePhase === true }">
        <div class="form-group form-group--inline">
          <div class="col-form-label no-left-margin">
            {{ getReasonName(item.wasteReasonId) }}
          </div>
          <div *ngIf="!item.isAutoWaste" class="col-form-label">
            <i class="icon-delete remove-waste-row-button" (click)="removeRow(item)"></i>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</form>
