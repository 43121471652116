import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { OutputChangeQuantity, OutputFinish, OutputFinishState } from '@app/modules/run-phase/models';
import { GluingService } from '@app/modules/run-phase/services/gluing/gluing.service';
import { ButtonItem } from '@app/shared/components';
import { ProducedMaterialCheckStatus, producedMaterialStatusFlags, StatusParameter, WasteAssignmentFormEvent } from '@app/shared/models';
import { Outer, OuterLabelType, PrintLabelForOuterEntry, ProducedMaterialStatus, Quantity } from 'chronos-core-client';
import { ListValue, LoadingNotificationService, LogService, ModalConfirmComponent } from 'chronos-shared';
import { notificationTopic } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import { filter, finalize, tap } from 'rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-gluing',
  templateUrl: './gluing.component.html',
  styleUrls: ['./gluing.component.scss']
})
export class GluingComponent implements OnChanges {
  @Input() public outputUnit: Outer;
  @Input() public wasteReasons: ListValue[];
  @Input() public grossQuantity: Quantity = {
    value: 0
  };

  @Output() public finishClicked = new EventEmitter<OutputFinish>();
  @Output() public changeOutputUnitQuantityClicked = new EventEmitter<OutputChangeQuantity>();

  public readonly LOADING_TOPIC = notificationTopic.outputUnitAction;
  public readonly CONTAINER_STATUS_FLAGS = producedMaterialStatusFlags;
  private readonly OUTER_PRINT_LOADING_TOPIC = notificationTopic.outerPrintLabelAction;
  private readonly OUTER_PREPRINT_LOADING_TOPIC = notificationTopic.outerPreprintLabelAction;
  private readonly WASTE_ADD_LOADING_TOPIC = notificationTopic.wasteAssignmentAddAction;
  public readonly INPUT_MIN_QUANTITY = 1;

  public isExpanded = true;
  public gluingButtons: ButtonItem[];
  public containerStatus: StatusParameter;
  public wasteStatus: ProducedMaterialCheckStatus;

  constructor(private gluingService: GluingService, private translateService: TranslateService, private dialogService: DialogService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.outputUnit) {
      this.gluingButtons = this.buildGluingButtons();
      this.containerStatus = this.CONTAINER_STATUS_FLAGS[this.outputUnit.status];
      this.wasteStatus = this.getWasteStatus();
    }
  }

  public toggleContainer(): void {
    this.isExpanded = !this.isExpanded;
  }

  private buildGluingButtons(): ButtonItem[] {
    const isFinished = this.isOrderUnitFinished(); // && this.isOrderUnitFull();
    return [
      {
        label: 'PALLET_CARD.FINISH',
        command: () => {
          this.onFinishClick();
        },
        visible: isFinished,
        primary: isFinished
      },
      {
        command: () => {
          this.onInterruptClick();
        },
        label: 'PALLET_CARD.INTERRUPT',
        visible: isFinished
      },
      {
        label: 'OUTPUT_UNIT.CHANGE_OUTPUT_UNIT_QUANTITY',
        primary: !isFinished,
        command: () => {
          this.onOutputUnitQuantityChanged();
        }
      }
    ];
  }

  public isOrderUnitFinished(): boolean {
    return this.outputUnit.status === ProducedMaterialStatus.FINISHED && this.outputUnit.isLastContainer;
  }

  public isOrderUnitFull(): boolean {
    return this.outputUnit.grossQuantity.value === this.outputUnit.containerQuantity.value;
  }

  public onRowAdded(productionOrderId: number, event: WasteAssignmentFormEvent): void {
    LoadingNotificationService.publish(this.WASTE_ADD_LOADING_TOPIC, true);
    this.gluingService
      .addOuterWasteAssignment(productionOrderId, event.waste, event.maculature, event?.reason)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.WASTE_ADD_LOADING_TOPIC, false);
        })
      )
      .subscribe();
  }

  public onRowRemoved(productionOrderId: number, wasteAssignmentId: number): void {
    this.gluingService.deleteOuterWasteAssignment(productionOrderId, wasteAssignmentId).subscribe();
  }

  public printLabel(productionOrderId: number, printLabel: PrintLabelForOuterEntry): void {
    const loadingTopic = printLabel.reprint ? this.OUTER_PRINT_LOADING_TOPIC : this.OUTER_PREPRINT_LOADING_TOPIC;
    LoadingNotificationService.publish(loadingTopic, true);
    this.gluingService
      .printOutputUnitLabel(productionOrderId, printLabel)
      .pipe(
        tap(() => {
          LogService.success('SUCCESS_MESSAGE.PRINTED_LABELS');
        }),
        finalize(() => {
          LoadingNotificationService.publish(loadingTopic, false);
        })
      )
      .subscribe();
  }

  public quantityPerOuterChange(productionOrderId: number, quantity: number): void {
    this.gluingService
      .saveQuantityPerOuterAndCopies(productionOrderId, OuterLabelType.OUTER, quantity, this.INPUT_MIN_QUANTITY)
      .subscribe();
  }

  public onInterruptClick(): void {
    this.dialogService
      .open(ModalConfirmComponent, {
        header: this.translateService.instant('PALLET_CARD.INTERRUPT_ORDER'),
        data: {
          question: this.translateService.instant('PALLET_CARD.INTERRUPT_QUESTION'),
          acceptable: false
        }
      })
      .onClose.pipe(filter((accepted) => !!accepted))
      .subscribe(() => {
        this.emitFinishEvent(OutputFinishState.Interrupt);
      });
  }

  private emitFinishEvent(state: OutputFinishState): void {
    this.finishClicked.emit({
      producedMaterialId: this.outputUnit.producedMaterialId,
      finishState: state,
      loadingTopic: this.LOADING_TOPIC
    });
  }

  public onFinishClick(): void {
    if (this.grossQuantity.value > 0) {
      this.dialogService
        .open(ModalConfirmComponent, {
          header: this.translateService.instant('PALLET_CARD.FINISH_ORDER_GROSS_QUANTITY_WARNING_HEADER'),
          data: {
            question: this.translateService.instant('PALLET_CARD.FINISH_ORDER_GROSS_QUANTITY_WARNING', {
              amount: this.grossQuantity.value
            }),
            acceptable: true,
            swapButtons: true
          }
        })
        .onClose.pipe(filter((accepted) => !!accepted))
        .subscribe(() => {
          this.emitFinishEvent(OutputFinishState.Finish);
        });
    } else {
      this.emitFinishEvent(OutputFinishState.Finish);
    }
  }

  public onOutputUnitQuantityChanged(): void {
    this.changeOutputUnitQuantityClicked.emit({
      productionOrderId: this.outputUnit.productionOrderId,
      producedMaterialId: this.outputUnit.producedMaterialId,
      quantity: this.outputUnit.containerQuantity
    });
  }

  private getWasteStatus(): ProducedMaterialCheckStatus {
    const result = this.outputUnit.wasteAssignmentsResult;
    const state = this.outputUnit.wasteAssignmentsState;

    return { result, state };
  }
}
