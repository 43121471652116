<div *ngIf="!isDisplayChart && (isChartInSplitDowntime || isChartInEditDowntime)">
  {{ 'MACHINE_CHART.SPLIT_DOWNTIME_MESSAGE' | translate }}
</div>

<div *ngIf="isDisplayChart">
  <div class="adjust_info" style="color: blue">
    {{ editedExternalOrderId }}
  </div>
  <div class="p-grid adjust_info">
    <div class="p-col-10" *ngIf="!isChartInEditDowntime">
      <span>
        {{ 'MACHINE_CHART.EDIT_SETUP_HEADER' | translate }}
      </span>
    </div>
    <div class="p-col-10" *ngIf="isChartInEditDowntime">
      <span>
        {{ 'MACHINE_CHART.EDIT_DOWNTIME_SETUP_HEADER' | translate }}
      </span>
    </div>
    <div class="p-col-2 text-right">
      <span>{{ endTime | utcToLocal | date : 'dd.MM.yyyy HH:mm' }}</span>
    </div>
  </div>
  <br />

  <form [formGroup]="formGroup" (ngSubmit)="editSetupStartEnd()">
    <!-- Start time edit controls-->
    <div class="edit_setup-phase-duration">
      <span *ngIf="isStartTimeEditable" class="edit_setup-phase-duration__col-form-label-start">
        {{ 'SETUP_WASTE_ASSIGNMENT.START' | translate }}
        <lib-calendar
          [showTime]="false"
          [ngModel]="startTime"
          (ngModelChange)="startTimeChanged($event, true)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
          [inputStyle]="{ width: '100px' }"
          [disabled]="!!isChartInEditDowntime"
        ></lib-calendar>
      </span>
      <app-time-input
        *ngIf="isStartTimeEditable"
        class="split_time"
        [showControls]="false"
        [ngModel]="startTime"
        (ngModelChange)="startTimeChanged($event, false)"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        [disabled]="!!isChartInEditDowntime"
      ></app-time-input>
      <span *ngIf="!isStartTimeEditable" class="edit_setup-phase-duration__col-form-label-start split-downtime-label">
        {{ 'SETUP_WASTE_ASSIGNMENT.START' | translate }}
        {{ startTime | utcToLocal | date : 'dd.MM.yyyy HH:mm' }}
      </span>
      <span class="waste-quantity" *ngIf="isChartInEditDowntime">
        {{ 'MACHINE_CHART.EDIT_DOWNTIME_WASTE' | translate }}
        <p-inputNumber
          class="edit_setup-phase-duration__col-form-setup-value setup-value"
          [(ngModel)]="quantity.value"
          [min]="0"
          [size]="5"
          [max]="quantity"
          (focusout)="setupQuantityChanged()"
          formControlName="setupQuantity"
          [locale]="locale"
          [inputStyle]="INPUT_STYLE"
        ></p-inputNumber>
      </span>

      <span *ngIf="isMachineChartInSetupPhase" class="edit_setup-phase-duration__col-form-label-end">
        {{ 'MACHINE_CHART.SETUP_QUANTITY' | translate }}
        <lib-input-number
          class="edit_setup-phase-duration__col-form-setup-value setup-value"
          [(ngModel)]="quantity.value"
          [min]="0"
          [size]="5"
          (focusout)="setupQuantityChanged()"
          formControlName="setupQuantity"
          [inputStyle]="INPUT_STYLE"
        ></lib-input-number>
      </span>

      <span *ngIf="isChartInApproveSetup" class="edit_setup-phase-duration__col-form-label-end">
        {{ 'MACHINE_CHART.WASTE' | translate }}
        <lib-input-number
          class="edit_setup-phase-duration__col-form-setup-value setup-value"
          [(ngModel)]="waste.value"
          [min]="0"
          [size]="5"
          [max]="quantity"
          (focusout)="onWasteChanged()"
          formControlName="waste"
          [inputStyle]="INPUT_STYLE"
        ></lib-input-number>
      </span>
      <span *ngIf="isChartInApproveSetup" class="edit_setup-phase-duration__col-form-label-end">
        {{ 'MACHINE_CHART.MACULATURE' | translate }}
        <lib-input-number
          class="edit_setup-phase-duration__col-form-setup-value setup-value"
          [(ngModel)]="maculature.value"
          [min]="0"
          [size]="5"
          [max]="quantity"
          (focusout)="onMaculatureChanged()"
          formControlName="maculature"
          [inputStyle]="INPUT_STYLE"
        ></lib-input-number>
      </span>

      <span *ngIf="isChartInSplitDowntime" class="edit_setup-phase-duration__col-form-label-end">
        {{ 'MACHINE_CHART.SPLIT_TIME' | translate }}
        <lib-calendar
          [showTime]="false"
          [ngModel]="splitTime"
          (ngModelChange)="splitTimeChanged($event, true)"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        ></lib-calendar>
      </span>
      <app-time-input
        class="split_time"
        *ngIf="isChartInSplitDowntime"
        [showControls]="false"
        [ngModel]="splitTime"
        (ngModelChange)="splitTimeChanged($event, false)"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
      ></app-time-input>

      <!-- End time edit controls-->
      <span *ngIf="isEndTimeEditable" class="edit_setup-phase-duration__col-form-label-end">
        {{ 'SETUP_WASTE_ASSIGNMENT.END' | translate }}
        <lib-calendar
          [showTime]="false"
          [ngModel]="endTime"
          (ngModelChange)="endTimeChanged($event, true)"
          [inputStyle]="{ width: '100px' }"
          [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
        ></lib-calendar>
      </span>
      <app-time-input
        *ngIf="isEndTimeEditable"
        class="split_time"
        [showControls]="false"
        [ngModel]="endTime"
        (ngModelChange)="endTimeChanged($event, false)"
        [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
      ></app-time-input>
      <span *ngIf="!isEndTimeEditable" class="edit_setup-phase-duration__col-form-label-end split-downtime-label">
        {{ 'SETUP_WASTE_ASSIGNMENT.END' | translate }}
        {{ endTime | utcToLocal | date : 'dd.MM.yyyy HH:mm' }}
      </span>
    </div>
  </form>
  <lib-machine-chart
    [workCenterId]="workCenterId"
    [isMachineChartInSetupPhase]="isMachineChartInSetupPhase"
    [setupStartTime]="startTime"
    [setupEndTime]="endTime"
    [splitTime]="splitTime"
    [isChartIsInSplitDowntime]="isChartInSplitDowntime"
    [earlistDateLimit]="earliestLimit"
    [latestDateLimit]="latestLimit"
    [orderSetupPhaseState]="orderSetupPhaseState"
    (setupStartTimeChanged)="startTimeChanged($event, false)"
    (splitTimeChanged)="splitTimeChanged($event, false)"
    (setupEndTimeChanged)="endTimeChanged($event, false)"
    [chartMode]="chartName"
  ></lib-machine-chart>
</div>

<lib-modal-footer
  [submitLabel]="'MODAL_FOOTER.CONFIRM'"
  (submitClicked)="onSubmit()"
  (declineClicked)="onDecline()"
  (resetClicked)="editDowntimeReset()"
  [submitDisabled]="false"
  [whenLoadingTopic]="LOADING_TOPIC"
  [resetButtonLoadingTopic]="LOADING_TOPIC_EDITDOWNTIME"
  [isResetVisible]="!!isChartInEditDowntime"
></lib-modal-footer>
