<div class="pattern-pool" *ngIf="isPatternAvailable">
  <div class="pallet-card">
    <div class="pallet-card__body" style="display: none">
      <div class="pattern">
        <div class="pattern__wrapper">
          <div class="pattern__label">{{ 'PATTERN_INFO.PRODUCED' | translate }}</div>
          <div class="pattern__value">
            {{ patternDetail.producedQuantity?.value }}
            <span class="pattern__units">{{ patternDetail.producedQuantity?.unitId }}</span>
            <span class="pattern__span">/</span>
          </div>
        </div>
        <div class="pattern__wrapper">
          <div class="pattern__label">{{ 'PATTERN_INFO.PATTERN' | translate }}</div>
          <div class="pattern__value">
            {{ patternDetail.patternLength?.value }}
            <span class="pattern__units">{{ patternDetail.patternLength?.unitId }}</span>
          </div>
        </div>
        <div class="pattern__wrapper">
          <div class="pattern__label">{{ 'PATTERN_INFO.PRODUCED_WIDTH' | translate }}</div>
          <div class="pattern__value">
            {{ patternDetail.patternWidth?.value }}
            <span class="pattern__units">{{ patternDetail.patternWidth?.unitId }}</span>
          </div>
        </div>
        <div class="pattern__wrapper">
          <div class="pattern__label">{{ 'PATTERN_INFO.WEIGHT' | translate }}</div>
          <div class="pattern__value">
            {{ patternDetail.patternWeight?.value }}
            <span class="pattern__units">{{ patternDetail.patternWeight?.unitId }}</span>
          </div>
        </div>
      </div>

      <div class="pallet-card__actions" style="display: none">
        <div class="form-group">
          <lib-button class="quantity-pool__action" (clicked)="lastButtonClick()" [label]="'PATTERN_INFO.LAST'"></lib-button>
        </div>
      </div>
    </div>
    <app-pattern-info-table [tableData]="patternDetail.patternInfo"></app-pattern-info-table>
  </div>
</div>
