import { Component, Input } from '@angular/core';
import { PatternTrackInfo } from 'projects/chronos-core-client/src/public-api';

interface PatternTrackInfoWithLayoutData extends PatternTrackInfo {
  rowSpanKnife: number;
  showRowKnife: boolean;
  rowSpanStation: number;
  showRowStation: boolean;
}

@Component({
  selector: 'app-pattern-trace-table',
  templateUrl: './pattern-trace-table.component.html',
  styleUrls: ['./pattern-trace-table.component.scss']
})
export class PatternTraceTableComponent {
  public tracePatternData: PatternTrackInfo[];

  @Input()
  public set tableData(tableData: PatternTrackInfo[]) {
    this.tracePatternData = this.addRenderingData(tableData);
  }

  constructor() {}

  private addRenderingData(tableData: PatternTrackInfo[]): PatternTrackInfo[] {
    let lastKnife = '';
    let lastStation = '';

    tableData.forEach((patternTrackInfo: PatternTrackInfoWithLayoutData, index: number) => {
      patternTrackInfo.rowSpanKnife = this.getNumberOfSameKnives(tableData, index);
      patternTrackInfo.showRowKnife = lastKnife !== patternTrackInfo.crossKnifePositionId;
      patternTrackInfo.rowSpanStation = this.getNumberOfSameStations(tableData, index);
      patternTrackInfo.showRowStation = lastStation !== patternTrackInfo.stationCode && !!patternTrackInfo.stationCode;

      if (!!patternTrackInfo.crossKnifePositionId) {
        lastKnife = patternTrackInfo.crossKnifePositionId;
      }

      if (!!patternTrackInfo.stationCode) {
        lastStation = patternTrackInfo.stationCode;
      }
    });

    return tableData;
  }

  private getNumberOfSameKnives(tableData: PatternTrackInfo[], index: number): any {
    for (let i = index + 1; i < tableData.length; i++) {
      if (tableData[index].crossKnifePositionId !== tableData[i].crossKnifePositionId) {
        return i - index;
      }
    }
    return tableData.length - index;
  }

  private getNumberOfSameStations(tableData: PatternTrackInfo[], index: number): any {
    if (!tableData[index].stationCode) {
      return 1;
    }

    for (let i = index + 1; i < tableData.length; i++) {
      if (tableData[index].stationCode !== tableData[i].stationCode && !!tableData[i].stationCode) {
        return i - index;
      }
    }
    return tableData.length - index;
  }
}
