import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { GuardsUtils } from './guards-utils';

@Injectable()
export class PhaseNavigationGuard implements CanActivate {
  constructor(private guardsUtils: GuardsUtils) {}

  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.guardsUtils.navigateToPhase(next);
  }
}
