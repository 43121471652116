import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { AppSettingsQuery } from 'chronos-shared';

@Injectable()
export class ManualModeGuard implements CanActivate, CanActivateChild {
  constructor(private appSettingsQuery: AppSettingsQuery) {}

  public canActivate(): boolean {
    return !this.appSettingsQuery.isWorkCenterModeManual();
  }

  public canActivateChild(): boolean {
    return !this.appSettingsQuery.isWorkCenterModeManual();
  }
}
