import Two from 'two.js';

class PatternRendererInternal {
  #colorPallet = [
    '#b4b889',
    '#bbc199',
    '#b0d6B8',
    '#CFEFD7',
    '#DCF4E8',
    '#474A2C',
    '#636940',
    '#59A96A',
    '#9BDEAC',
    '#B4E7CE',
    '#404234',
    '#5B5E4A',
    '#6F9577',
    '#ADCDB5',
    '#C2BDCF'
  ];

  #canvasContainer;
  #scaleHeightToContent;

  #textSize = 16;
  #lineThickness = 2;
  #scaleFactorWidth = 1;
  #scaleFactorHeight = 1;

  constructor(canvasContainer, scaleHeightToContent) {
    this.#canvasContainer = canvasContainer;
    this.#scaleHeightToContent = scaleHeightToContent ?? false;
  }

  getWidthCoordinate(coordinate) {
    return coordinate * this.#scaleFactorWidth;
  }

  getHeightCoordinate(coordinate) {
    return coordinate * this.#scaleFactorHeight;
  }

  getPatternWidth(pattern) {
    return pattern.crossKnifePositions.reduce(
      (accumulator, currentValue) =>
        accumulator +
        currentValue.patternTracks.reduce((accumulatorInner, currentValueInner) => accumulatorInner + currentValueInner.trackWidth, 0),
      0
    );
  }

  getMaxPatternSheetLength(pattern) {
    return pattern.crossKnifePositions.reduce(
      (accumulator, currentValue) =>
        Math.max(
          accumulator,
          currentValue.patternTracks.reduce(
            (accumulatorInner, currentValueInner) => Math.max(accumulatorInner, this.getHeightCoordinate(currentValueInner.sheetLength)),
            0
          )
        ),
      0
    );
  }

  getSubProcessingStations(pattern, startAbsoluteWidth, endAbsoluteWidth) {
    const subProcessingStations = pattern.subProcessingStations;
    const stations = [];

    for (const subProcessingStation of subProcessingStations) {
      if (subProcessingStation.absolutePosition > startAbsoluteWidth && subProcessingStation.absolutePosition <= endAbsoluteWidth) {
        stations.push(subProcessingStation);
      }
    }

    return stations;
  }

  getMajorTicks(pattern) {
    const crossKnifePositions = pattern.crossKnifePositions;
    const ticks = [0];
    let absoluteWidth = 0;

    for (const crossKnifePosition of crossKnifePositions) {
      const patternTracks = crossKnifePosition.patternTracks;

      for (const patternTrack of patternTracks) {
        absoluteWidth += patternTrack.trackWidth;
      }

      ticks.push(absoluteWidth);
    }

    return ticks;
  }

  getMinorTicks(pattern) {
    const crossKnifePositions = pattern.crossKnifePositions;
    const ticks = [0];
    let absoluteWidth = 0;

    for (const crossKnifePosition of crossKnifePositions) {
      const patternTracks = crossKnifePosition.patternTracks;

      for (const patternTrack of patternTracks) {
        absoluteWidth += patternTrack.trackWidth;
        ticks.push(absoluteWidth);
      }
    }

    return ticks;
  }

  getSubProcessingStationTicks(pattern, type) {
    return pattern.subProcessingStations.filter((x) => x.type === type).map((station) => station.absolutePosition);
  }

  getColor(nextOrderId, allocatedColors) {
    if (!nextOrderId) {
      return 'white';
    }

    let index = allocatedColors[nextOrderId];
    if (index === undefined) {
      if (allocatedColors.lastIndex === undefined) {
        allocatedColors.lastIndex = -1;
      }

      index = allocatedColors.lastIndex + 1;

      if (index >= this.#colorPallet.length) {
        index = 0;
      }

      allocatedColors.lastIndex = index;
      allocatedColors[nextOrderId] = index;
    }

    return this.#colorPallet[index];
  }

  drawTick(position, rulerHeight, scaleTextOffsetY, scaleOffsetY, isMajor) {
    const majorTickText = new Two.Text(position, this.getWidthCoordinate(position), scaleTextOffsetY, {
      size: this.#textSize
    });

    const tickStart = isMajor ? 0 : rulerHeight / 2;
    const tickLine = new Two.Line(
      this.getWidthCoordinate(position),
      scaleOffsetY + tickStart,
      this.getWidthCoordinate(position),
      scaleOffsetY + rulerHeight
    );

    tickLine.linewidth = this.#lineThickness;

    return new Two.Group(majorTickText, tickLine);
  }

  drawRuler(totalWidth, unitId, majorTicks, minorTicks, renderScale) {
    const rulerHeight = this.#textSize;
    const rulerHeightOffset = this.#textSize; // TODO: text height

    const scaleTextOffsetY = renderScale === 'top' ? this.#textSize / 2 : rulerHeightOffset + rulerHeight;
    const scaleOffsetY = renderScale === 'top' ? rulerHeightOffset : 0;
    const tickLines = [];

    const line = new Two.Line(
      this.getWidthCoordinate(0),
      scaleOffsetY + rulerHeight / 2,
      this.getWidthCoordinate(totalWidth),
      scaleOffsetY + rulerHeight / 2
    );

    line.linewidth = this.#lineThickness;

    const zeroTick = this.drawTick(0, rulerHeight, scaleTextOffsetY, scaleOffsetY, true);

    if (majorTicks) {
      for (const majorTick of majorTicks) {
        tickLines.push(this.drawTick(majorTick, rulerHeight, scaleTextOffsetY, scaleOffsetY, true));
      }
    }

    if (minorTicks) {
      for (const minorTick of minorTicks) {
        if (majorTicks.includes(minorTick)) {
          continue;
        }

        tickLines.push(this.drawTick(minorTick, rulerHeight, scaleTextOffsetY, scaleOffsetY, false));
      }
    }

    const tickGroup = new Two.Group(tickLines);

    const unitText = new Two.Text(unitId, this.getWidthCoordinate(totalWidth + this.#textSize / 2), rulerHeightOffset + rulerHeight, {
      size: this.#textSize,
      alignment: 'left'
    });

    return {
      group: new Two.Group(line, zeroTick, tickGroup, unitText),
      height: rulerHeight + rulerHeightOffset
    };
  }

  drawTracks(pattern) {
    const tracks = [];
    const crossKnifePositionHeight = this.#textSize * 3;
    const allocatedColors = {};
    let absolutePosition = 0;

    const crossKnifePositions = pattern.crossKnifePositions;
    for (const crossKnifePosition of crossKnifePositions) {
      const crossKnifePositionWidth = crossKnifePosition.patternTracks.reduce(
        (accumulator, currentValue) => accumulator + currentValue.trackWidth,
        0
      );

      const crossKnifeHeader = new Two.Rectangle(
        this.getWidthCoordinate(absolutePosition + crossKnifePositionWidth / 2),
        crossKnifePositionHeight / 2,
        this.getWidthCoordinate(crossKnifePositionWidth),
        crossKnifePositionHeight
      );

      crossKnifeHeader.linewidth = this.#lineThickness;

      const positionText = new Two.Text(
        `Knife: ${crossKnifePosition.positionId}`,
        this.getWidthCoordinate(absolutePosition + crossKnifePositionWidth / 2),
        crossKnifePositionHeight / 2,
        {
          size: this.#textSize
        }
      );

      const crossKnifeHeaderGroup = new Two.Group(crossKnifeHeader, positionText);

      const patternTracks = crossKnifePosition.patternTracks;
      const crossKnifeElements = [];

      for (const patternTrack of patternTracks) {
        const trackWidth = patternTrack.trackWidth;
        const sheetLength = patternTrack.sheetLength;
        const sheetLengthScaled = this.getHeightCoordinate(sheetLength);

        const trackText = new Two.Text(
          `${trackWidth} x ${sheetLength}`,
          this.getWidthCoordinate(absolutePosition + trackWidth / 2),
          crossKnifePositionHeight + this.#textSize,
          {
            size: this.#textSize
          }
        );

        const track = new Two.Rectangle(
          this.getWidthCoordinate(absolutePosition + trackWidth / 2),
          crossKnifePositionHeight + sheetLengthScaled / 2,
          this.getWidthCoordinate(trackWidth),
          sheetLengthScaled
        );

        track.linewidth = this.#lineThickness;

        if (patternTrack.nextOrderId) {
          track.fill = this.getColor(patternTrack.nextOrderId, allocatedColors);
        }

        // draw sub-processing stations
        const subProcessingStations = this.getSubProcessingStations(pattern, absolutePosition, absolutePosition + trackWidth);
        const subProcessingStationElements = [];

        for (const subProcessingStation of subProcessingStations) {
          const station = new Two.Line(
            this.getWidthCoordinate(subProcessingStation.absolutePosition),
            crossKnifePositionHeight + 2 * this.#textSize,
            this.getWidthCoordinate(subProcessingStation.absolutePosition),
            crossKnifePositionHeight + sheetLengthScaled
          );

          station.linewidth = this.#lineThickness;

          if (subProcessingStation.type === 'Score') {
            station.dashes = [5, 5];
            station.stroke = 'blue';
          } else if (subProcessingStation.type === 'Crease') {
            station.stroke = 'blue';
          } else if (subProcessingStation.type === 'Thread') {
            station.dashes = [5, 5];
            station.stroke = 'red';
          } else {
            station.dashes = [5, 15];
            station.stroke = 'black';
          }

          subProcessingStationElements.push(station);
        }

        const subProcessingStationsGroup = new Two.Group(subProcessingStationElements);
        const trackGroup = new Two.Group(track, trackText, subProcessingStationsGroup);
        crossKnifeElements.push(trackGroup);

        absolutePosition += trackWidth;
      }

      const tracksGroup = new Two.Group(crossKnifeElements);

      tracks.push(new Two.Group(crossKnifeHeaderGroup, tracksGroup));
    }

    const maxTrackHeight = this.getMaxPatternSheetLength(pattern);
    return {
      group: new Two.Group(tracks),
      height: crossKnifePositionHeight + maxTrackHeight
    };
  }

  draw(pattern) {
    new ResizeObserver((resizeEvent) => {
      console.info(`${resizeEvent[0].contentRect.width} x ${resizeEvent[0].contentRect.height}`);
      this.render(pattern, {
        width: resizeEvent[0].contentRect.width - 4,
        height: resizeEvent[0].contentRect.height - 4
      });
    }).observe(this.#canvasContainer);

    this.render(pattern, {
      width: this.#canvasContainer.clientWidth - 4,
      height: this.#canvasContainer.clientHeight - 4
    });
  }

  render(pattern, renderSize) {
    const params = {
      fullScreen: true,
      width: renderSize.width,
      height: renderSize.height
    };

    const margin = 20;
    const offsetComponents = 20;
    const patternWidth = this.getPatternWidth(pattern);

    this.#scaleFactorWidth = (this.#canvasContainer.clientWidth - 4 * margin) / patternWidth;
    this.#scaleFactorHeight = this.#scaleHeightToContent ? this.#scaleFactorWidth : this.#canvasContainer.clientHeight / patternWidth;

    // clear content of the render container
    while (this.#canvasContainer.firstChild) {
      this.#canvasContainer.removeChild(this.#canvasContainer.firstChild);
    }

    const renderer = new Two(params).appendTo(this.#canvasContainer);

    let yOffset = 0;

    const scaleTracks = this.drawRuler(patternWidth, 'mm', this.getMajorTicks(pattern), this.getMinorTicks(pattern), 'top');
    scaleTracks.group.translation.set(0, yOffset);
    yOffset += scaleTracks.height + offsetComponents;

    const tracks = this.drawTracks(pattern);

    tracks.group.translation.set(0, yOffset);
    yOffset += tracks.height + offsetComponents;

    const subProcessingStationTypes = ['Score', 'Crease', 'Thread', 'Other'];
    const scales = [];

    for (const subProcessingStationType of subProcessingStationTypes) {
      if (pattern.subProcessingStations.some((x) => x.type === subProcessingStationType)) {
        const scaleProcessingStations = this.drawRuler(
          patternWidth,
          'mm',
          this.getSubProcessingStationTicks(pattern, subProcessingStationType),
          [],
          'bottom'
        );

        scaleProcessingStations.group.translation.set(0, yOffset);
        yOffset += scaleProcessingStations.height + offsetComponents;

        scales.push(scaleProcessingStations.group);
      }
    }

    const scaleGroup = new Two.Group(scales);
    const group = new Two.Group(scaleTracks.group, tracks.group, scaleGroup);

    group.translation.set(margin, margin);

    renderer.add(group);
    renderer.update();
  }
}

class PatternRenderer {
  private internal: PatternRendererInternal;

  constructor(canvasContainer, scaleHeightToContent) {
    this.internal = new PatternRendererInternal(canvasContainer, scaleHeightToContent);
  }

  drawPattern(pattern) {
    this.internal.draw(pattern);
  }
}

export { PatternRenderer };
