import { NgModule } from '@angular/core';
import { QaCheckService } from '@app/core/services';
import { ParametersModule } from '@app/modules/parameters/parameters.module';
import { QaHistoryModule } from '@app/modules/qa-history/qa-history.module';
import { SharedModule } from '@app/shared/shared.module';
import { DialogService } from 'primeng/dynamicdialog';
import { SetupInfoComponent } from './containers/parameters/components/setup-info/setup-info.component';
import { SetupParametersComponent } from './containers/parameters/setup-parameters.component';
import { SetupQaHistoryComponent } from './containers/qa-history/setup-qa-history.component';
import { QaCheckListInSetupComponent } from './containers/quality-assurance/components/qa-check-list-in-setup/qa-check-list-in-setup.component';
import { QualityAssuranceComponent } from './containers/quality-assurance/quality-assurance.component';
import { QualityAssuranceService } from './containers/quality-assurance/quality-assurance.service';
import { SetupPhaseComponent } from './containers/setup-phase/setup-phase.component';
import { QuantityAllocationComponent } from './containers/waste-assignment/components/quantity-allocation/quantity-allocation.component';
import { WasteAssignmentComponent } from './containers/waste-assignment/waste-assignment.component';
import { QuantityAllocationService } from './services/quantity-allocation/quantity-allocation.service';
import { SetupPhaseService } from './services/setup-phase/setup-phase.service';
import { SetupParametersService } from '@app/modules/setup-phase/services/setup-parameters/setup-parameters.service';
import { LineClearanceComponent } from './containers/line-clearance/line-clearance.component';
import { LineClearanceService } from './services/line-clearance/line-clearance.service';
import { LineClearanceListInSetupComponent } from './containers/line-clearance/components/line-clearance-list-in-setup.component';
import { PatternTraceTableComponent } from './containers/pattern-info/pattern-trace-table/pattern-trace-table.component';
import { PatternInfoComponent } from './containers/pattern-info/setup-pattern-info.component';
import { SetupDefaultRedirectComponent } from '@app/modules/setup-phase/containers/setup-default-redirect/setup-default-redirect.component';
import { CommonModule } from '@angular/common';
import { PatternVisualizerComponent } from './containers/pattern-info/pattern-visualizer/pattern-visualizer/pattern-visualizer.component';

@NgModule({
  declarations: [
    SetupInfoComponent,
    SetupPhaseComponent,
    SetupParametersComponent,
    WasteAssignmentComponent,
    QuantityAllocationComponent,
    QualityAssuranceComponent,
    QaCheckListInSetupComponent,
    SetupQaHistoryComponent,
    LineClearanceComponent,
    LineClearanceListInSetupComponent,
    PatternInfoComponent,
    PatternTraceTableComponent,
    SetupDefaultRedirectComponent,
    PatternVisualizerComponent
  ],
  imports: [SharedModule, ParametersModule, QaHistoryModule, CommonModule],
  providers: [
    QuantityAllocationService,
    SetupPhaseService,
    SetupParametersService,
    QualityAssuranceService,
    QaCheckService,
    DialogService,
    LineClearanceService
  ]
})
export class SetupPhaseModule {}
