import { Component, OnInit } from '@angular/core';
import { TabTraceService } from '@app/modules/mounting/services/tab-trace/tab-trace.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { notificationTopic } from '@app/shared/utils';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';
import { MaterialAvailableAtLocationItem } from 'chronos-core-client';

@Component({
  selector: 'app-existing-trace-material-assignation-modal',
  templateUrl: './existing-trace-material-assignation-modal.component.html',
  styleUrls: ['./existing-trace-material-assignation-modal.component.scss']
})
export class ExistingTraceMaterialAssignationModalComponent implements OnInit {
  public readonly LOADING_TOPIC: string = notificationTopic.modalExistingTraceAssign;
  public traceMaterial$: Observable<MaterialAvailableAtLocationItem[]>;
  public tabName = '';

  constructor(private tabTraceService: TabTraceService, private ref: DynamicDialogRef, private config: DynamicDialogConfig) {}

  public ngOnInit(): void {
    this.tabName = this.config.data.tabName;
    this.traceMaterial$ = this.tabTraceService.getAvailableTraceMaterial(this.config.data.articleForConsumption.id);
  }

  public onAssignClick([selectedMaterial, materialBlockId]: [MaterialAvailableAtLocationItem, number, number]): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.tabTraceService
      .assignTraceMaterial(selectedMaterial.containerId, materialBlockId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        this.ref.close(true);
      });
  }
}
