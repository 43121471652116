import { NgModule } from '@angular/core';
import { ContainerCollapseComponent } from '@app/modules/run-phase/components';
import { InputService } from '@app/modules/run-phase/containers/input/input.service';
import {
  CollapseExpandNotificationService,
  OutputContainerListService,
  OutputContainerService,
  OutputService,
  OutputUnitListService
} from '@app/modules/run-phase/services';
import { GluingService } from '@app/modules/run-phase/services/gluing/gluing.service';
import { InputPalletsQuery, InputPalletsService, InputPalletsStore, OutputPalletsService } from '@app/modules/run-phase/state';
import { SharedModule } from '@app/shared/shared.module';
import { GluingDocumentsComponent } from './containers/gluing/components/gluing-documents/gluing-documents.component';
import { GluingComponent } from './containers/gluing/gluing.component';
import { InputContainerComponent } from './containers/input/components/input-container/input-container.component';
import { InputQuantityPoolComponent } from './containers/input/components/input-quantity-pool/input-quantity-pool.component';
import { InputComponent } from './containers/input/input.component';
import { OutputContainerListComponent } from './containers/output/components/output-container-list/output-container-list.component';
import { OutputQuantityPoolComponent } from './containers/output/components/output-quantity-pool/output-quantity-pool.component';
import { OutputQuantityChangeComponent } from './containers/output/components/output-quantity-change/output-quantity-change.component';
import { PalletComponent } from './containers/output/components/pallet/pallet.component';
import { QaCheckListInRunComponent } from './containers/output/components/qa-check-list-in-run/qa-check-list-in-run.component';
import { QaChecksModalComponent } from './containers/output/components/qa-checks-modal/qa-checks-modal.component';
import { StatusBarItemComponent } from './containers/output/components/status-bar-item/status-bar-item.component';
import { StatusBarComponent } from './containers/output/components/status-bar/status-bar.component';
import { OutputComponent } from './containers/output/output.component';
import { CounterHeaderComponent } from './containers/run-phase/components/counter/counter-header/counter-header.component';
import { CounterComponent } from './containers/run-phase/components/counter/counter.component';
import { RunPhaseComponent } from './containers/run-phase/run-phase.component';
import { OutputUnitListComponent } from './containers/output/components/output-unit-list/output-unit-list.component';
import { ProductionOrderService } from 'projects/chronos-core-client/src/public-api';
import { PeriodicQaCheckModalComponent } from './containers/output/components/periodic-qa-check-modal/periodic-qa-check-modal.component';
import { AutomaticPeriodicQaCheckComponent } from './containers/output/components/automatic-periodic-qa-check/automatic-periodic-qa-check.component';
import { MountedContainerQaCheckComponent } from './containers/input/components/mounted-container-qa-check/mounted-container-qa-check.component';
import { PatternInfoComponent } from './containers/output/components/pattern-info/pattern-info.component';
import { PatternInfoTableComponent } from './containers/output/components/pattern-info/pattern-info-table/pattern-info-table/pattern-info-table.component';
import { OutputStationTabComponent } from './containers/output/components/output-station-tab/output-station-tab.component';

@NgModule({
  declarations: [
    RunPhaseComponent,
    CounterComponent,
    InputComponent,
    PalletComponent,
    OutputQuantityPoolComponent,
    OutputContainerListComponent,
    OutputComponent,
    ContainerCollapseComponent,
    InputContainerComponent,
    InputQuantityPoolComponent,
    StatusBarComponent,
    StatusBarItemComponent,
    OutputQuantityChangeComponent,
    QaChecksModalComponent,
    QaCheckListInRunComponent,
    GluingDocumentsComponent,
    GluingComponent,
    CounterHeaderComponent,
    OutputUnitListComponent,
    PeriodicQaCheckModalComponent,
    AutomaticPeriodicQaCheckComponent,
    MountedContainerQaCheckComponent,
    PatternInfoComponent,
    PatternInfoTableComponent,
    OutputStationTabComponent
  ],
  imports: [SharedModule],
  providers: [
    OutputService,
    OutputPalletsService,
    OutputContainerService,
    InputPalletsStore,
    InputPalletsService,
    InputPalletsQuery,
    InputService,
    CollapseExpandNotificationService,
    OutputContainerListService,
    OutputUnitListService,
    GluingService,
    ProductionOrderService
  ]
})
export class RunPhaseModule {}
