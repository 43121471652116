import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ActiveWorkCenterService, WorkCenterDsService } from '@app/core/workcenter';
import { nav } from '@app/shared/utils';
import { TranslateService } from '@ngx-translate/core';
import {
  ActiveProductionOrder,
  FrontendConfigService,
  FrontendFeatures,
  WorkCenter,
  WorkCenterState,
  WorkCenterQaHistoryParameter
} from 'chronos-core-client';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ActiveOrderDsService } from '@app/core/data-services';
import { AppSettingsService } from 'chronos-shared';

@Injectable({
  providedIn: 'root'
})
export class WorkCenterInitService {
  constructor(
    private activeWorkCenterService: ActiveWorkCenterService,
    private appSettingsService: AppSettingsService,
    private activeOrderDsService: ActiveOrderDsService,
    private workCenterDsService: WorkCenterDsService,
    private router: Router,
    private titleService: Title,
    private translateService: TranslateService,
    private frontendConfigService: FrontendConfigService
  ) {}

  public initializeWorkCenter(): Promise<any> {
    return this.activeWorkCenterService
      .initializeWorkCenterId()
      .pipe(
        map(() => this.activeWorkCenterService.getWorkCenterId()),
        switchMap((workCenterId) => this.initData(workCenterId)),
        tap(
          ([workCenter, workCenterState, qaHistory, features]: [
            WorkCenter,
            WorkCenterState,
            WorkCenterQaHistoryParameter,
            FrontendFeatures,
            ActiveProductionOrder
          ]) => {
            this.appSettingsService.setWorkCenter(workCenter);
            this.appSettingsService.setWorkCenterState(workCenterState);
            this.appSettingsService.setQaHistoryParameter(qaHistory);
            this.appSettingsService.setAppFeatures(features);
            this.setAppTitle(workCenter.externalWorkCenterId);
          }
        ),
        catchError((error) => this.onCatchError(error))
      )
      .toPromise();
  }

  private onCatchError(error: any): Observable<any> {
    this.router.navigate([nav.workCenterSelection]);
    return of(error);
  }

  private setAppTitle(externalWorkCenterId: string): void {
    const title = this.translateService.instant('APP.TITLE');
    this.titleService.setTitle(`${title} (${externalWorkCenterId})`);
  }

  private initData(
    workCenterId: number
  ): Observable<[WorkCenter, WorkCenterState, WorkCenterQaHistoryParameter, FrontendFeatures, ActiveProductionOrder]> {
    return forkJoin([
      this.workCenterDsService.getWorkCenter(workCenterId),
      this.workCenterDsService.getWorkCenterState(workCenterId),
      this.workCenterDsService.getQaHistoryParameter(workCenterId),
      this.frontendConfigService.getFeatures(),
      this.activeOrderDsService.initActiveProductionOrder()
    ]);
  }
}
