import { Injectable } from '@angular/core';
import { ProducedMaterialStatus } from 'chronos-core-client';
import { OutputPalletsStore } from './output-pallets.store';
import { ProducedMaterialsViewModel, ProducedMaterialLoadingMode } from 'projects/chronos-core-client/src/lib/api/models';

@Injectable({
  providedIn: 'root'
})
export class OutputPalletsService {
  constructor(private outputPalletsStore: OutputPalletsStore) {}

  public setOutputPallets(outputPallets: ProducedMaterialsViewModel): void {
    if (outputPallets.loadingMode !== ProducedMaterialLoadingMode.UPDATE) {
      this.outputPalletsStore.setLoading(true);
      this.outputPalletsStore.set(outputPallets.producedMaterials);
      this.outputPalletsStore.setLoading(false);
    }
  }

  public updateFilter(filter: string): void {
    this.outputPalletsStore.update({ filter });
  }

  public updateStatus(producedMaterialId: number, status: ProducedMaterialStatus): void {
    this.outputPalletsStore.update(producedMaterialId, { status });
  }
}
