import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PatternDetail } from 'chronos-core-client';
import * as render from './patternRenderer';

@Component({
  selector: 'app-pattern-visualizer',
  templateUrl: './pattern-visualizer.component.html',
  styleUrls: ['./pattern-visualizer.component.scss']
})
export class PatternVisualizerComponent implements OnInit, AfterViewInit {
  @Input() public patternDetail: PatternDetail[];
  @ViewChild('renderCanvas') renderCanvas: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (!this.patternDetail) {
      return;
    }

    const renderer = new render.PatternRenderer(this.renderCanvas.nativeElement, false);

    renderer.drawPattern(this.patternDetail);
  }
}
