<section class="secondary-section overview">
  <ng-container>
    <div class="row filters">
      <div class="form-group">
        <p-dropdown
          class="filters__dropdown"
          [options]="shiftList$ | async"
          [(ngModel)]="selectedShift"
          (onChange)="onFilterChange()"
        ></p-dropdown>
      </div>
      <div class="form-group text-search">
        <input
          id="historytextSearch"
          type="text"
          class="search-bar"
          [(ngModel)]="textQuery"
          (input)="onFilterChange()"
          pInputText
          [placeholder]="'EMPLOYEEREGISTRATION.EMPLOYEEHISTORY_SEARCH' | translate"
        />
      </div>
    </div>
    <div class="row">
      <app-employee-registration-history-table
        [employeeHistory]="employeeRegistrationHistoryQuery.history$ | async"
      ></app-employee-registration-history-table>
    </div>
  </ng-container>
</section>
