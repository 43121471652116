import { Injectable } from '@angular/core';
import { CanActivateChild, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { finishPhaseFooterRoutes } from '../utils/nav';
import { GuardsUtils } from './guards-utils';
import { RunPhaseType } from 'chronos-core-client';

@Injectable()
export class FinishSubPhaseNavigationGuard implements CanActivateChild {
  constructor(private guardsUtils: GuardsUtils) {}

  public canActivateChild(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.guardsUtils.navigateToSubPhase(next, RunPhaseType.FINISHING, finishPhaseFooterRoutes);
  }
}
