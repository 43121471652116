import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppSettingsQuery } from 'chronos-shared';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApproveGuard implements CanActivate {
  constructor(private appSettingsQuery: AppSettingsQuery) {}

  public canActivate(): Observable<boolean> {
    return this.appSettingsQuery.isApprovalReportEnabled$;
  }
}
