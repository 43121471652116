import { ProductionOrderListState, ProductionOrderListStore } from './production-order-list.store';
import { QueryEntity } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { MachineSchedule } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class ProductionOrderListQuery extends QueryEntity<ProductionOrderListState, MachineSchedule> {
  constructor(protected productionOrderListStore: ProductionOrderListStore) {
    super(productionOrderListStore);
  }
}
