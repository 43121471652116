import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { GuardsUtils } from '@app/shared/guards/guards-utils';
import { finishPhaseFooterRoutes } from '@app/shared/utils';
import { Observable } from 'rxjs';

@Injectable()
export class FinishingEndSubPhaseNavigationGuard implements CanActivate {
  constructor(private guardsUtils: GuardsUtils) {}

  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.guardsUtils.currentSubPhaseIsActive(next, finishPhaseFooterRoutes);
  }
}
