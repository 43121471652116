import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabSecondaryComponent } from '@app/modules/mounting/containers/tab-secondary/tab-secondary.component';
import { MaterialTraceTableComponent } from '@app/modules/mounting/containers/tab-trace/components/material-trace-table/material-trace-table.component';
import { DummyContainerDirective } from '@app/modules/mounting/directives';
import { EmptyContainerDirective } from '@app/modules/mounting/directives/empty-container.directive';
import { ItemContainerDirective } from '@app/modules/mounting/directives/item-container.directive';
import {
  MountingService,
  MountPalletCardsService,
  MountPalletItemService,
  MountPalletListService,
  TabOverviewService,
  TabPrimaryService,
  TabSecondaryService,
  WarehouseStockModalService
} from '@app/modules/mounting/services';
import { TabTraceService } from '@app/modules/mounting/services/tab-trace/tab-trace.service';
import {
  ConsumedMaterialQuery,
  ConsumedMaterialService,
  ConsumedMaterialStore,
  PrimaryMountedMaterialQuery,
  PrimaryMountedMaterialService,
  PrimaryMountedMaterialStore,
  RecentlyConsumedMaterialQuery,
  RecentlyConsumedMaterialService,
  RecentlyConsumedMaterialStore
} from '@app/modules/mounting/state';
import { SharedModule } from '@app/shared/shared.module';
import { MountingComponent } from './containers/mounting/mounting.component';
import { OrderDetailsComponent } from './containers/tab-overview/components/order-details/order-details.component';
import { TabOverviewComponent } from './containers/tab-overview/tab-overview.component';
import { ActivePalletAnimationComponent } from './containers/tab-primary/components/active-pallet-animation/active-pallet-animation.component';
import { ConsumptionIndicatorComponent } from './containers/tab-primary/components/consumption-indicator/consumption-indicator.component';
import { ConsumptionPalletDummyComponent } from './containers/tab-primary/components/consumption-pallet-dummy/consumption-pallet-dummy.component';
import { ConsumptionPalletEmptyComponent } from './containers/tab-primary/components/consumption-pallet-empty/consumption-pallet-empty.component';
import { ConsumptionPalletItemComponent } from './containers/tab-primary/components/consumption-pallet-item/consumption-pallet-item.component';
import { ConsumptionPalletListComponent } from './containers/tab-primary/components/consumption-pallet-list/consumption-pallet-list.component';
import { ContainerMaterialViewComponent } from './containers/tab-primary/components/container-material-view/container-material-view.component';
import { DummyPalletCreatingFormComponent } from './containers/tab-primary/components/dummy-pallet-creating-form/dummy-pallet-creating-form.component';
import { MountPalletCardItemComponent } from './containers/tab-primary/components/mount-pallet-card-item/mount-pallet-card-item.component';
import { MountPalletCardsComponent } from './containers/tab-primary/components/mount-pallet-cards/mount-pallet-cards.component';
import { MountPalletListItemComponent } from './containers/tab-primary/components/mount-pallet-list-item/mount-pallet-list-item.component';
import { MountPalletListTemplateComponent } from './containers/tab-primary/components/mount-pallet-list-template/mount-pallet-list-template.component';
import { MountPalletListComponent } from './containers/tab-primary/components/mount-pallet-list/mount-pallet-list.component';
import { PrimaryMaterialConsumptionModalComponent } from './containers/tab-primary/components/primary-material-consumption-modal/primary-material-consumption-modal.component';
import { TabPrimaryComponent } from './containers/tab-primary/tab-primary.component';
import { MaterialOverviewTableComponent } from './containers/tab-secondary/components/material-overview-table/material-overview-table.component';
import { WarehouseStockModalComponent } from './containers/tab-secondary/components/warehouse-stock-modal/warehouse-stock-modal.component';
import { ExistingTraceMaterialAssignationModalComponent } from './containers/tab-trace/components/existing-trace-material-assignation-modal/existing-trace-material-assignation-modal.component';
import { TraceMaterialAssignationModalComponent } from './containers/tab-trace/components/trace-material-assignation-modal/trace-material-assignation-modal.component';
import { TabTraceComponent } from './containers/tab-trace/tab-trace.component';
import { OrderByItemsDirective } from './directives/order-by-items.directive';
import { DismountPanelComponent } from '@app/modules/mounting/containers/tab-overview/components/dismount-panel/dismount-panel.component';
import { MountedMaterialTableComponent } from './containers/tab-secondary/components/mounted-material-table/mounted-material-table.component';
import { ParallelConsumptionModalComponent } from './containers/tab-secondary/components/parallel-consumption-modal/parallel-consumption-modal.component';

@NgModule({
  declarations: [
    MountingComponent,
    TabPrimaryComponent,
    ConsumptionIndicatorComponent,
    TabOverviewComponent,
    ConsumptionIndicatorComponent,
    MountPalletCardItemComponent,
    MountPalletListItemComponent,
    OrderDetailsComponent,
    DummyPalletCreatingFormComponent,
    ActivePalletAnimationComponent,
    ContainerMaterialViewComponent,
    MountPalletListComponent,
    MountPalletCardsComponent,
    PrimaryMaterialConsumptionModalComponent,
    TabSecondaryComponent,
    MaterialOverviewTableComponent,
    WarehouseStockModalComponent,
    MountPalletListTemplateComponent,
    ConsumptionPalletListComponent,
    ConsumptionPalletItemComponent,
    ConsumptionPalletDummyComponent,
    ConsumptionPalletEmptyComponent,
    DummyContainerDirective,
    ItemContainerDirective,
    EmptyContainerDirective,
    OrderByItemsDirective,
    TabTraceComponent,
    MaterialTraceTableComponent,
    TraceMaterialAssignationModalComponent,
    ExistingTraceMaterialAssignationModalComponent,
    DismountPanelComponent,
    MountedMaterialTableComponent,
    ParallelConsumptionModalComponent
  ],
  imports: [SharedModule],
  providers: [
    MountingService,
    PrimaryMountedMaterialService,
    PrimaryMountedMaterialStore,
    PrimaryMountedMaterialQuery,
    RecentlyConsumedMaterialService,
    RecentlyConsumedMaterialStore,
    RecentlyConsumedMaterialQuery,
    ConsumedMaterialService,
    ConsumedMaterialStore,
    ConsumedMaterialQuery,
    TabPrimaryService,
    TabSecondaryService,
    WarehouseStockModalService,
    MountPalletCardsService,
    MountPalletListService,
    MountPalletItemService,
    DecimalPipe,
    TabTraceService,
    TabOverviewService
  ]
})
export class MountingModule {}
