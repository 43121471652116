import { QaCheckService } from '@app/core/services/qa-check/qa-check.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import { CreatePalletModalComponent } from '@app/modules/manual-mode/components/create-pallet-modal/create-pallet-modal.component';
import { OrderFinishModalComponent } from '@app/modules/manual-mode/components/order-finish-modal/order-finish-modal.component';
import { OrderFinishSortingModalComponent } from '@app/modules/manual-mode/components/order-finish-sorting-modal/order-finish-sorting-modal.component';
import { ManualModeService } from '@app/modules/manual-mode/services/manual-mode/manual-mode.service';
import {
  OutputChangeBobbinQuantity,
  OutputChangeQuantity,
  OutputFinish,
  OutputFinishCheck,
  OutputLastMaterial
} from '@app/modules/run-phase/models';
import { CollapseExpandNotificationService, OutputContainerListService, OutputContainerService } from '@app/modules/run-phase/services';
import { OutputPalletsQuery, OutputPalletsService } from '@app/modules/run-phase/state';
import { TranslateService } from '@ngx-translate/core';
import {
  ActiveProductionOrder,
  CreateManualPalletData,
  ProducedMaterial,
  ProducedMaterialStatus,
  ProductionOrderManualModeSortingValues,
  ProductionOrderStatus,
  QaCheck,
  Quantity,
  WorkCenterProductionMode,
  PeriodicQaCheck,
  KpiIdentifier,
  ProductionOrderOutputStationService,
  ProductionOrderOutputStation
} from 'chronos-core-client';
import { AppSettingsQuery, ListValue, LoadingNotificationService, ModalConfirmComponent } from 'chronos-shared';
import { DialogService } from 'primeng/dynamicdialog';
import * as R from 'ramda';
import { clone } from 'ramda';
import { Observable, Subscription, forkJoin, of, Subject, combineLatest } from 'rxjs';
import { catchError, finalize, map, tap, takeUntil } from 'rxjs/operators';
import { OutputQuantityChangeComponent } from '../output-quantity-change';
import { nav, notificationTopic } from '@app/shared/utils';
import { ContainerCollapseGroup } from '@app/modules/run-phase/components';
import { elastic } from '@app/shared/utils/elastic';
import { ParametersDsService, ProductionOrderDsService } from '@app/core/data-services';
import { Router } from '@angular/router';
import { PeriodicQaCheckModalComponent } from '../periodic-qa-check-modal/periodic-qa-check-modal.component';
import { ProducedMaterialLoadingMode } from 'projects/chronos-core-client/src/public-api';
import { ChangeBobbinQuantityComponent } from '@app/shared/modals';
import { ActiveOrderQuery } from '@app/core/global-state';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-output-container-list',
  templateUrl: './output-container-list.component.html',
  styleUrls: ['./output-container-list.component.scss']
})
export class OutputContainerListComponent implements OnInit, OnDestroy {
  @Input() public reasonOptions: ListValue[];
  @Input() public activeProductionOrder: ActiveProductionOrder;
  @Input() public grossQuantity: Quantity;
  @Input() public outputStationQuantities: { [key: string]: Quantity };
  @Output() public finishClicked = new EventEmitter<OutputFinish>();

  public arePalletsExpanded: boolean;
  public manualMode = false;
  public manualModeCutting = false;
  public manualModeSorting = false;
  private shiftDuration: string;
  public visiblePallets$: Observable<ProducedMaterial[]>;
  public otherPallets$: Observable<ProducedMaterial[]>;
  public allPallets$: ProducedMaterial[];
  public otherPalletGroups$: Observable<ContainerCollapseGroup>;
  public isManualModeForContainer$: Observable<boolean>;
  public isCancelProductionOrder = false;
  public isManualCheckAvailable;
  public slitCount: number;

  public isStationTabsVisible = false;
  public outputStationTabs: MenuItem[] = [];
  public activeTab: MenuItem;

  private readonly quantityUnit = 'M';
  public quantityPool: Quantity = { value: 0, unitId: this.quantityUnit };
  public targetQuantity: Quantity = { value: 0, unitId: this.quantityUnit };
  public isPalletVisible = true;
  public activeOutputStationId: number;

  private activeProductionOrderId: number;
  private outputContainerGroup: ContainerCollapseGroup = {
    name: 'COLLAPSE.FINISHED',
    value: 0,
    of: 0
  };
  private subscriptions = new Subscription();
  private palletLoadingMode: ProducedMaterialLoadingMode;
  private readonly MAX_PALLET_QUANTITY = 1000000;
  private readonly QUANTITY_POOL_LOADING_TOPIC = notificationTopic.quantityPoolAction;
  private readonly LOADING_TOPIC = notificationTopic.modalCancelProductionOrder;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private activeWorkCenterService: ActiveWorkCenterService,
    private outputContainerService: OutputContainerService,
    private outputPalletsQuery: OutputPalletsQuery,
    private outputPalletsService: OutputPalletsService,
    private collapseExpandNotificationService: CollapseExpandNotificationService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private outputContainerListService: OutputContainerListService,
    private manualModeService: ManualModeService,
    private productionOrderDsService: ProductionOrderDsService,
    private router: Router,
    private qaCheckService: QaCheckService,
    private parametersDsService: ParametersDsService,
    private activeOrderQuery: ActiveOrderQuery,
    private appSettingsQuery: AppSettingsQuery,
    private outputStationService: ProductionOrderOutputStationService
  ) {}

  public ngOnInit(): void {
    this.palletLoadingMode = ProducedMaterialLoadingMode.ALL;
    this.activeProductionOrderId = this.activeProductionOrder.productionOrderId;
    this.slitCount = this.activeProductionOrder.slitCount;
    this.isManualModeForContainer$ = this.outputContainerListService.isManualModeForContainer();

    this.subscriptions.add(
      this.outputStationService.getProductionOrderOutputStationList(this.activeProductionOrderId).subscribe((result) => {
        this.setOutputStations(result);
        this.isStationTabsVisible = this.outputStationTabs.length > 1 ? true : false;

        if (this.outputStationTabs.length) {
          this.toggleSpinner(false);
          this.activeOutputStationId = this.outputStationTabs[0].automationId;
          this.storeActiveOutputStation(this.activeOutputStationId);
          this.getStationPallets(this.activeOutputStationId);
        } else {
          this.commonSubscribe();
        }
      })
    );

    this.subscriptions.add(
      this.outputContainerService.getOutputPallets(this.activeProductionOrderId, ProducedMaterialLoadingMode.ALL).subscribe()
    );

    this.outputContainerListService.getProductionMode().subscribe((mode) => {
      this.checkManualMode(mode);
    });

    this.subscriptions.add(
      this.manualModeService.getAllKpis().subscribe((kpiData) => {
        this.shiftDuration = kpiData.find((kpi) => kpi.kpiIdentifier === KpiIdentifier.DURATION)?.targetTime;
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    localStorage.removeItem('activeOutputStationId');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private isPossibleToCancel(pallets: ProducedMaterial[]): boolean {
    return pallets.every((pallet) => pallet.runId !== this.activeProductionOrder.runId);
  }

  private setupOutputPallets(setupQaChecks: QaCheck[]): void {
    const outputPallets = combineLatest([this.outputPalletsQuery.outputPallets$, this.activeOrderQuery.activeOrderId$]).pipe(
      tap(([pallets]) => (this.outputContainerGroup.of = pallets.length || 0)),
      tap(([pallets]) => (this.isCancelProductionOrder = this.isPossibleToCancel(pallets))),
      map(([pallets]) => {
        if (this.activeOutputStationId) {
          pallets = pallets.filter((pallet) => pallet.outputStationId === this.activeOutputStationId);
        }
        return pallets.map((pallet) => ({
          ...pallet,
          qaChecks: pallet.qaChecks.map((qaCheckItem) => ({
            ...qaCheckItem,
            qaCheck: this.getQaCheckHeader(setupQaChecks, qaCheckItem.qaCheckId)
          }))
        }));
      })
    );

    this.visiblePallets$ = outputPallets.pipe(
      takeUntil(this.unsubscribe$),
      map((pallets: ProducedMaterial[]) => pallets.filter((pallet) => this.isContainerVisible(pallet)))
    );

    outputPallets.subscribe((pallets) => {
      if (this.palletLoadingMode !== ProducedMaterialLoadingMode.OPEN && this.checkStatusChanged(pallets)) {
        this.allPallets$ = pallets;
        this.otherPallets$ = of(this.allPallets$.filter((pallet) => !this.isContainerVisible(pallet))).pipe(takeUntil(this.unsubscribe$));
        this.outputContainerGroup.of = this.allPallets$.length;
        this.otherPalletGroups$ = this.otherPallets$.pipe(
          takeUntil(this.unsubscribe$),
          map((othePallets: ProducedMaterial[]) => this.collectPalletCounts(othePallets))
        );
      } else {
        let visiblePalletLength = 0;
        let finishedPalletLength = 0;
        this.visiblePallets$?.subscribe((x) => (visiblePalletLength = x.length));
        this.otherPallets$?.subscribe((x) => (finishedPalletLength = x.length));
        this.outputContainerGroup.of = visiblePalletLength + finishedPalletLength;
        this.otherPalletGroups$ = this.otherPallets$.pipe(
          takeUntil(this.unsubscribe$),
          map((othePallets: ProducedMaterial[]) => this.collectPalletCounts(othePallets))
        );
      }
    });
  }

  private manualProductionQAChecks(setupQaChecks: PeriodicQaCheck): void {
    this.isManualCheckAvailable = setupQaChecks.productionQaChecks.length > 0 ?? false;
  }

  private checkStatusChanged(pallets: ProducedMaterial[]): boolean {
    let isStatusChange =
      this.allPallets$?.length > 0
        ? pallets.some((pallet) =>
            this.allPallets$.some((x) => x.producedMaterialId === pallet.producedMaterialId && x.status !== pallet.status)
          )
        : true;
    if (!isStatusChange && this.allPallets$.length < pallets.length) {
      isStatusChange = true;
    }
    return isStatusChange;
  }

  public createPallet(): void {
    if (this.manualModeCutting) {
      this.openPalletCreationModal();
    } else {
      LoadingNotificationService.publish(this.QUANTITY_POOL_LOADING_TOPIC, true);
      this.outputContainerService
        .createOutputPallet(this.activeProductionOrderId)
        .pipe(
          finalize(() => {
            LoadingNotificationService.publish(this.QUANTITY_POOL_LOADING_TOPIC, false);
          })
        )
        .subscribe();
    }
  }

  public trackByFunction(index: number, outputPallet: ProducedMaterial): number {
    return outputPallet.containerId;
  }

  public onLastClick(lastMaterial: OutputLastMaterial): void {
    LoadingNotificationService.publish(lastMaterial.loadingTopic, true);
    this.outputContainerService
      .markPalletAsLast(lastMaterial.producedMaterialId, this.activeWorkCenterService.getWorkCenterId())
      .pipe(finalize(() => LoadingNotificationService.publish(lastMaterial.loadingTopic, false)))
      .subscribe(() => {
        this.otherPallets$ = this.otherPallets$.pipe(
          takeUntil(this.unsubscribe$),
          map((pallets: ProducedMaterial[]) => pallets.filter((pallet) => pallet.producedMaterialId !== lastMaterial.producedMaterialId))
        );
      });
  }

  public handleFinishedCheckChange(event: OutputFinishCheck): void {
    const trace = elastic.traceUiActivity('OutputContainerListComponent.handleFinishedCheckChange');
    LoadingNotificationService.publish(event.loadingTopic, true);

    let action: Observable<any>;
    if (event.isFinished) {
      action = this.outputContainerService.setPalletAsFinished(event.producedMaterialId, this.activeWorkCenterService.getWorkCenterId());
      this.palletLoadingMode = ProducedMaterialLoadingMode.FINISHED;
    } else {
      action = this.outputContainerService.setPalletAsOpened(event.producedMaterialId, this.activeWorkCenterService.getWorkCenterId());
      this.otherPallets$ = this.otherPallets$.pipe(
        takeUntil(this.unsubscribe$),
        map((pallets: ProducedMaterial[]) => pallets.filter((pallet) => pallet.producedMaterialId !== event.producedMaterialId))
      );
      const index = this.allPallets$.findIndex((x) => x.producedMaterialId === event.producedMaterialId);
      this.allPallets$[index].status = ProducedMaterialStatus.OPEN;
      this.palletLoadingMode = ProducedMaterialLoadingMode.OPEN;
    }

    action
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(event.loadingTopic, false);
          trace.end();
        }),
        catchError((): any => {
          const index = this.allPallets$.findIndex((x) => x.producedMaterialId === event.producedMaterialId);
          if (event.isFinished && index > -1) {
            this.allPallets$[index].status = ProducedMaterialStatus.OPEN;
            this.visiblePallets$ = of(this.allPallets$.filter((pallet) => this.isContainerVisible(pallet))).pipe(
              takeUntil(this.unsubscribe$)
            );
          } else if (index > -1) {
            this.allPallets$[index].status = ProducedMaterialStatus.FINISHED;
            this.otherPallets$ = of(this.allPallets$.filter((pallet) => !this.isContainerVisible(pallet))).pipe(
              takeUntil(this.unsubscribe$)
            );
          }
          this.palletLoadingMode = ProducedMaterialLoadingMode.OPEN;
          this.outputContainerService.getOutputPallets(this.activeProductionOrderId, ProducedMaterialLoadingMode.ALL);
          this.outputContainerService.getOutputStationPallets(
            this.activeProductionOrderId,
            this.activeOutputStationId,
            ProducedMaterialLoadingMode.ALL
          );
        })
      )
      .subscribe({
        error: () => {
          this.outputPalletsService.updateStatus(event.producedMaterialId, event.producedMaterialStatus);
        }
      });
  }

  private collectPalletCounts(pallets: ProducedMaterial[]): ContainerCollapseGroup {
    return pallets.reduce((result, pallet) => {
      if (pallet.status === ProductionOrderStatus.FINISHED) {
        result.value += 1;
      }
      return result;
    }, clone(this.outputContainerGroup));
  }

  public onFinishClicked(outputFinish: OutputFinish): void {
    if (this.manualModeCutting) {
      this.openFinishManualOrderModal(outputFinish);
      return;
    }

    if (this.manualModeSorting) {
      this.finishManualSortingOrder(outputFinish);
      return;
    }

    if (this.outputPalletsQuery.areAllPalletsFinished()) {
      this.finishClicked.emit(outputFinish);
      return;
    }
  }

  public changeContainerQuantity(): void {
    const trace = elastic.traceUiActivity('OutputContainerListComponent.changeContainerQuantity');
    LoadingNotificationService.publish(this.QUANTITY_POOL_LOADING_TOPIC, true);
    this.outputContainerService
      .getQuantityPerPallet(this.activeProductionOrder.productionOrderId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.QUANTITY_POOL_LOADING_TOPIC, false);
          trace.end();
        })
      )
      .subscribe((model) =>
        this.openChangeQuantityModal({
          productionOrderId: this.activeProductionOrder.productionOrderId,
          quantity: model.quantityPerPallet,
          classification: this.allPallets$[0]?.article?.classification // article classification
        })
      );
  }

  public changePalletQuantity(changeQuantityEvent: OutputChangeQuantity): void {
    if (this.manualModeCutting) {
      this.openPalletCreationModal(changeQuantityEvent);
    } else {
      this.openChangeQuantityModal(changeQuantityEvent);
    }
  }

  public cancelProductionOrder(): void {
    this.openCancelProductionOrderModal();
  }

  public openPeriodicQACheckDialog(): void {
    this.dialogService.open(PeriodicQaCheckModalComponent, {
      header: this.translateService.instant('RUN_PHASE.PERIODIC_QA_CHECK'),
      data: {
        productionOrderId: this.activeProductionOrderId
      }
    });
  }

  public openChangeBobbinQuantityDialog(outputBobbinChangeQuantity?: OutputChangeBobbinQuantity): void {
    const trace = elastic.traceUiActivity('OutputContainerListComponent.changeBobbinQuantity');

    forkJoin({
      containerQuantity: this.parametersDsService.getPalletQuantity(this.activeProductionOrder.productionOrderId),
      bobbinQuantity: this.parametersDsService.getBobbinQuantity(this.activeProductionOrder.productionOrderId)
    })
      .pipe(
        finalize(() => {
          trace.end();
        })
      )
      .subscribe(({ containerQuantity, bobbinQuantity }) => {
        this.dialogService.open(ChangeBobbinQuantityComponent, {
          header: this.translateService.instant('CHANGE_BOBBIN_LENGTH_DIALOG.CHANGE_BOBBIN_LENGTH'),
          data: {
            productionOrderId: this.activeProductionOrderId,
            bobbinQuantity: bobbinQuantity.bobbinQuantity,
            bobbinDetails: outputBobbinChangeQuantity,
            containerQuantity: containerQuantity.quantityPerPallet
          }
        });
      });
  }

  private openChangeQuantityModal(changeQuantityEvent: OutputChangeQuantity): void {
    const articleClassification = changeQuantityEvent.classification?.toLowerCase();
    this.dialogService.open(OutputQuantityChangeComponent, {
      header: this.translateService.instant('PALLET_CARD.CHANGE_QTY_PER_PALLET'),
      data: {
        changeQuantity: changeQuantityEvent,
        isManualSorting: this.manualModeSorting,
        articleClassification
      }
    });
  }

  private isContainerVisible(outputPallet: ProducedMaterial): boolean {
    return outputPallet.status !== ProducedMaterialStatus.FINISHED || outputPallet.isLastContainer;
  }

  private getQaCheckHeader(setupQaChecks: QaCheck[], qaCheckId: number): QaCheck {
    return setupQaChecks.find((header) => header.id === qaCheckId);
  }

  public getIsExpandedWithDefaultValue(): boolean {
    return R.isNil(this.arePalletsExpanded) ? true : this.arePalletsExpanded;
  }

  private checkManualMode(mode: WorkCenterProductionMode): void {
    if (mode === WorkCenterProductionMode.MANUAL) {
      this.manualMode = true;
      this.subscriptions.add(
        this.outputContainerListService
          .hasWorkCenterManualSorting()
          .pipe(
            tap((hasManualSorting) => {
              this.setManualMode(hasManualSorting);
            })
          )
          .subscribe()
      );
    }
  }

  private setManualMode(isSorting: boolean): void {
    if (isSorting) {
      this.manualModeSorting = true;
    } else {
      this.manualModeCutting = true;
    }
  }

  private openPalletCreationModal(container?: OutputChangeQuantity): void {
    this.manualModeService.getPalletCreationData(this.activeProductionOrderId).subscribe((articleData) => {
      const palletQuantity = container?.producedMaterialId ? container?.quantity : articleData.containerQuantity;
      const modalAction = container?.producedMaterialId
        ? 'CREATE_PALLET_MODAL.CHANGE_PALLET_QUANTITY'
        : 'CREATE_PALLET_MODAL.CREATE_PALLET';
      const totalQuantity = container?.producedMaterialId ? this.getMaxValueAsQuantity() : this.getComparedQuantity();

      this.dialogService.open(CreatePalletModalComponent, {
        header: this.translateService.instant(modalAction, { articleClassification: container?.classification }),
        data: {
          article: this.manualModeService.mapArticleFromManualData(articleData),
          quantity: palletQuantity,
          maxQuantity: totalQuantity,
          ssccCode: container?.ssccCode || articleData.ssccCode || '-',
          sequenceNumber: container?.sequenceNumber || articleData.producedMaterialSequenceNumber,
          productionOrderId: this.activeProductionOrderId,
          producedMaterialId: container?.producedMaterialId,
          submitButtonLabel: modalAction
        }
      });
    });
  }

  private getComparedQuantity(): Quantity {
    return this.MAX_PALLET_QUANTITY > this.grossQuantity.value ? this.grossQuantity : this.getMaxValueAsQuantity();
  }

  private getMaxValueAsQuantity(): Quantity {
    return { value: this.MAX_PALLET_QUANTITY };
  }

  private openFinishManualOrderModal(outputFinish: OutputFinish): void {
    this.dialogService.open(OrderFinishModalComponent, {
      header: this.translateService.instant('MANUAL_MODE.CONFIRM_TIMES'),
      data: {
        activeProductionOrder: this.activeProductionOrder,
        outputFinish
      }
    });
  }

  private openFinishManualSortingOrderModal(
    articleData: CreateManualPalletData,
    sortingValues: ProductionOrderManualModeSortingValues,
    outputFinish: OutputFinish
  ): void {
    this.dialogService.open(OrderFinishSortingModalComponent, {
      header: this.translateService.instant('MANUAL_MODE.FINISH_JOB'),
      data: {
        activeProductionOrder: this.activeProductionOrder,
        article: this.manualModeService.mapArticleFromManualData(articleData),
        submitLabel: 'MANUAL_MODE.FINISH_JOB',
        sortingValues,
        outputFinish,
        shiftDuration: this.shiftDuration
      }
    });
  }

  private finishManualSortingOrder(outputFinish: OutputFinish): void {
    LoadingNotificationService.publish(outputFinish.loadingTopic, true);
    this.manualModeService
      .getCombinedSortingData(this.activeProductionOrderId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(outputFinish.loadingTopic, false);
        })
      )
      .subscribe(([articleData, sortingData]) => {
        this.openFinishManualSortingOrderModal(articleData, sortingData, outputFinish);
      });
  }

  private openCancelProductionOrderModal(): void {
    if (this.manualMode) {
      this.dialogService
        .open(ModalConfirmComponent, {
          header: this.translateService.instant('MANUAL_CANCEL_MODAL.CANCEL_PRODUCTION_ORDER'),
          data: { question: this.translateService.instant('MANUAL_CANCEL_MODAL.CANCEL_PRODUCTION_ORDER_QUESTION'), acceptable: true }
        })
        .onClose.subscribe((isDialogClosed: boolean) => {
          this.submitCancelProductionOrderModal(isDialogClosed);
        });
    }
  }

  public submitCancelProductionOrderModal(isDialogClosed: boolean): void {
    if (isDialogClosed) {
      LoadingNotificationService.publish(this.LOADING_TOPIC, true);
      this.productionOrderDsService
        .cancelProductionOrder(this.activeProductionOrderId)
        .pipe(
          finalize(() => {
            LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          })
        )
        .subscribe(() => {
          this.navigateAfterCancel();
        });
    }
  }

  private navigateAfterCancel(): void {
    this.router.navigate([nav.ordersList]);
  }

  //#region "output station"

  private setOutputStations(outputStations: ProductionOrderOutputStation[]) {
    if (outputStations.length) {
      this.setQuantityValues(outputStations[0].outputQuantityPool, outputStations[0].targetContainerQuantity);
      outputStations.forEach((stationData, index) => {
        const activeLink = index === 0 ? nav.routes.run : false;
        this.outputStationTabs.push({
          label: stationData.patternStationCode ? stationData.patternStationCode : (index + 1).toString(),
          automationId: stationData.outputStationId,
          routerLink: activeLink,
          command: () => {
            this.toggleSpinner(false);
            this.activeOutputStationId = stationData.outputStationId;
            this.storeActiveOutputStation(stationData.outputStationId);
            this.setQuantityValues(stationData.outputQuantityPool, stationData.targetContainerQuantity);
            this.getStationPallets(stationData.outputStationId);
          }
        });
      });

      if (this.outputStationTabs.length) {
        this.getActiveTab(this.outputStationTabs);
      }
    }
  }

  private storeActiveOutputStation(outputStationId: number) {
    localStorage.setItem('activeOutputStationId', outputStationId.toString());
  }

  private getActiveTab(tabs: MenuItem[]) {
    this.activeTab = tabs.find((tab) => this.router.isActive(tab.routerLink, true));
  }

  private getStationPallets(ouputStationId: number): void {
    this.outputContainerService
      .getOutputStationPallets(this.activeProductionOrderId, ouputStationId, this.palletLoadingMode)
      .subscribe(() => {
        this.toggleSpinner(true);
      });

    this.commonSubscribe();
  }

  private commonSubscribe(): void {
    this.subscriptions.add(
      this.outputContainerListService
        .getQaSetupValues(this.activeProductionOrderId)
        .subscribe((setupValues) => this.setupOutputPallets(setupValues))
    );

    this.subscriptions.add(this.outputContainerService.getProducedMaterialNotifications(this.activeOutputStationId).subscribe());

    this.subscriptions.add(
      this.qaCheckService.getManualProductionQaChecks(this.activeProductionOrderId).subscribe((data) => this.manualProductionQAChecks(data))
    );

    this.subscriptions.add(
      this.collapseExpandNotificationService.eventOutput$.subscribe((isExpanded) => (this.arePalletsExpanded = isExpanded))
    );
    this.subscriptions.add(this.outputContainerListService.getProductionMode().subscribe((mode) => this.checkManualMode(mode)));
  }

  private toggleSpinner(isVisible: boolean) {
    this.isPalletVisible = isVisible;
  }

  private setQuantityValues(outputQuantity: number, targetQuantity: number) {
    this.quantityPool = { value: outputQuantity, unitId: this.quantityUnit };
    this.targetQuantity = { value: targetQuantity, unitId: this.quantityUnit };
  }

  //#endregion
}
