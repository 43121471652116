import { Injectable } from '@angular/core';
import { ActiveOrderDsService, QaHistoryDsService } from '@app/core/data-services';
import { CreateNonConformance, FinishedGoodArticleItem, NonConformanceItem, NonConformanceModel } from 'chronos-core-client';
import { LogService } from 'chronos-shared';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QaHistoryService {
  constructor(private activeOrderDsService: ActiveOrderDsService, private qaHistoryDsService: QaHistoryDsService) {}
  private sortingWasteQuantity: number;
  private internalMemo: string;
  private createNCModeSubject = new BehaviorSubject<boolean>(false);
  public createNCMode$: Observable<boolean> = this.createNCModeSubject.asObservable();
  public cancelNCModeSubject = false;

  public getFinishedGoodArticles(loadOnlyForOneOrder): Observable<FinishedGoodArticleItem[]> {
    return this.qaHistoryDsService.getFinishedGoodArticles(loadOnlyForOneOrder);
  }

  public getNonConformanceList(articleId: number): Observable<NonConformanceItem[]> {
    return this.qaHistoryDsService.getNonConformanceList(articleId);
  }

  public getNonConformance(nonConformanceId: number): Observable<NonConformanceModel> {
    return this.qaHistoryDsService.getNonConformance(nonConformanceId);
  }

  public setQaHistoryCheck(isChecked: boolean): Observable<null> {
    return this.qaHistoryDsService.setQaHistoryCheck(this.activeOrderDsService.getActiveOrderId(), isChecked).pipe(
      tap(() => {
        this.activeOrderDsService.setActiveOrderQaHistoryCheck(isChecked);
      })
    );
  }

  public isActiveOrderQaHistoryChecked(): Observable<boolean> {
    return this.activeOrderDsService.isActiveOrderQaHistoryChecked();
  }

  public createNonConformancePath(): Observable<CreateNonConformance> {
    return this.qaHistoryDsService.createNonConformancePath(this.activeOrderDsService.getActiveOrderId());
  }

  public updateInternalMemo(internalMemo: string): void {
    this.internalMemo = internalMemo;
  }

  public updateSortingWasteQuantity(sortingWasteQuantity: number): void {
    this.sortingWasteQuantity = sortingWasteQuantity;
  }

  public createNonConformance(): Observable<null> {
    return this.qaHistoryDsService
      .createNonConformance(this.activeOrderDsService.getActiveOrderId(), this.sortingWasteQuantity, this.internalMemo)
      .pipe(
        tap(() => {
          LogService.success('SUCCESS_MESSAGE.NC_CREATED');
        }),
        tap(() => {
          this.createNCModeSubject.next(false);
          this.cancelNCModeSubject = false;
        })
      );
  }

  public enableNCCreateMode(): void {
    this.createNCModeSubject.next(true);
    this.cancelNCModeSubject = false;
  }

  public disableNCCreateMode(): void {
    this.cancelNCModeSubject = true;
    this.createNCModeSubject.next(false);
  }
}
