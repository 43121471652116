import { SetupFooterCommandBase, SetupFooterService } from '@app/shared/components';

export class PreviousFinishingSubphaseCommandService implements SetupFooterCommandBase {
  public enabled = true;
  public buttonText: string;
  public required = false;
  public route: string;

  constructor(private setupFooterService: SetupFooterService) {}

  public execute(): void {
    this.setupFooterService.goToPreviousSubPhase(this.route);
  }

  public setNavigationParams(route: string): void {
    this.route = route;
  }
}
