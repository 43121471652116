import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  MachineSchedule,
  PrintLabelForOuterEntry,
  ProductionOrderDocumentInfo,
  ProductionOrderDocuments,
  ProductionOrderPrintedLabelInfo,
  OuterLabelType
} from 'chronos-core-client';
import { filter, switchMap, tap } from 'rxjs/operators';
import {
  ContainersDsService,
  DocumentsDsService,
  MachineScheduleDsService,
  OutputUnitDsService,
  ProducedMaterialDsService
} from '@app/core/data-services';
import { BobbinPrintLabel } from '@app/shared/models/bobbin-print-label';

@Injectable()
export class DocumentsService {
  private productionOrderIdSubject = new BehaviorSubject<number>(undefined);
  public productionOrderId$ = this.productionOrderIdSubject.asObservable().pipe(filter((id) => !!id));

  constructor(
    private machineScheduleDsService: MachineScheduleDsService,
    private documentsDsService: DocumentsDsService,
    private outputUnitDsService: OutputUnitDsService,
    private containersDsService: ContainersDsService,
    private producedMaterialDsService: ProducedMaterialDsService
  ) {}

  public getAllDocumentsForOrder(): Observable<ProductionOrderDocuments> {
    return this.productionOrderId$.pipe(switchMap((orderId) => this.documentsDsService.getDocumentList(orderId)));
  }

  public getAllProductionOrders(): Observable<MachineSchedule[]> {
    return this.machineScheduleDsService.showAllMachineScheduleList();
  }

  public setProductionOrderId(productionOrderId: number): void {
    this.productionOrderIdSubject.next(productionOrderId);
  }

  public getProductionOrderInformation(): Observable<ProductionOrderDocumentInfo> {
    return this.productionOrderId$.pipe(switchMap((orderId) => this.documentsDsService.getProductionOrderInfo(orderId)));
  }

  public saveEditedRemarkInfo(productionOrderId: number, documentInfo: string): Observable<null> {
    return this.documentsDsService.saveRemark(productionOrderId, documentInfo);
  }

  public getPrintedLabelListData(): Observable<ProductionOrderPrintedLabelInfo> {
    return this.productionOrderId$.pipe(
      switchMap((productionOrderId) => this.documentsDsService.getProductionOrderLabelInfo(productionOrderId))
    );
  }

  public refreshSelectedOrder(): void {
    this.productionOrderIdSubject.next(this.productionOrderIdSubject.getValue());
  }

  public printOuterLabel(printLabel: PrintLabelForOuterEntry): Observable<any> {
    const productionOrderId = this.productionOrderIdSubject.getValue();
    return this.outputUnitDsService.printOutputUnitLabel(productionOrderId, printLabel).pipe(
      tap(() => {
        this.refreshSelectedOrder();
      })
    );
  }

  public saveQuantityPerOuterAndCopies(outerLabelType: OuterLabelType, quantityPerOuter: number, numberOfCopies: number): Observable<null> {
    const productionOrderId = this.productionOrderIdSubject.getValue();
    return this.outputUnitDsService.saveQuantityPerOuterAndCopies(productionOrderId, outerLabelType, quantityPerOuter, numberOfCopies);
  }

  public sendPrintLabel(containerId: number, isReprint: boolean): Observable<null> {
    return this.containersDsService.printLabel(containerId, isReprint);
  }

  public markAsDisplayed(documentId: number): Observable<null> {
    const productionOrderId = this.productionOrderIdSubject.getValue();
    return this.documentsDsService.markAsDisplayed(productionOrderId, documentId);
  }

  public printBobbinCoreLabel(printLabel: BobbinPrintLabel): Observable<any> {
    return this.producedMaterialDsService.printBobbinCoreLabel(printLabel).pipe(tap(() => this.refreshSelectedOrder()));
  }
}
