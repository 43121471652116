import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ButtonItem } from './split-button.model';

@Component({
  selector: 'app-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss']
})
export class SplitButtonComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public loadingTopic = '';
  @Input() public collapsed = false;
  @Input() public appendTo: string = null;
  @Input() public items: ButtonItem[] = [];
  @Input() public approveTable = false;

  public optionButtons: ButtonItem[];
  public primaryButton: ButtonItem;
  public singleItem = false;
  public loading = false;
  private subscriptions = new Subscription();

  constructor(private translateService: TranslateService) {}

  public ngOnInit(): void {

    if(this.items && this.approveTable) {
      this.constructButtons(this.items);
    }

    this.subscriptions.add(
      this.translateService.onLangChange.subscribe(() => {
        this.constructButtons(this.items);
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes.items || changes.collapsed) && !this.approveTable) {
      this.constructButtons(this.items);
    }
  }

  public onButtonClick(): void {
    if (this.primaryButton?.disabled || this.loading) {
      return;
    }
    this.primaryButton.command();
  }

  private constructButtons(buttons: ButtonItem[]): void {
    const mappedButtons = buttons.map((button) => this.translateLabel(button));

    this.primaryButton = this.getPrimaryButton(mappedButtons);

    if (this.collapsed) {
      this.optionButtons = mappedButtons;
    } else {
      this.optionButtons = mappedButtons.filter((button) => this.isOptionVisible(button));
      this.singleItem = this.optionButtons.length === 0;
    }
  }

  private translateLabel(button: ButtonItem): ButtonItem {
    return {
      ...button,
      label: this.translateService.instant(button.label)
    };
  }

  private isOptionVisible(button: ButtonItem): boolean {
    return button !== this.primaryButton && this.isButtonVisible(button);
  }

  private isButtonVisible(button: ButtonItem): boolean {
    return button?.visible === true || button?.visible === undefined;
  }

  private getPrimaryButton(buttons: ButtonItem[]): ButtonItem {
    return (
      buttons.find((button) => button.primary && this.isButtonVisible(button)) || buttons.find((button) => this.isButtonVisible(button))
    );
  }
}
