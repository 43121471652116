import { ArticleDescription } from 'chronos-core-client';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AppSettingsQuery, CalendarDate, DismountingPalletInformation, ListValue, LogService } from 'chronos-shared';
import { tap } from 'rxjs/operators';
import { SelectItem, TreeNode } from 'primeng/api';
import * as R from 'ramda';
import { ApproveService } from '@app/modules/approve/services';
import moment from 'moment-mini';
import { ConfirmInformationCommand, ConfirmSendApproveReportCommand, SendApproveReportCommand } from '@app/modules/approve/commands';
import {
  ApprovalReportData,
  ModalChangeQuantityBetweenOrdersComponentData,
  ModalChangeQuantityComponentData
} from '@app/modules/approve/models';
import {
  ApprovalReportConsumption,
  ApprovalReportConsumptionItem,
  ApprovalReportConsumptionItemDetail,
  ApprovalReportItem,
  ApprovalReportItemType,
  ApprovalReportProductionDowntimeItem,
  ApprovalReportProductionOrderPhaseType,
  ApprovalReportRunPhaseItem,
  DowntimeType,
  KpiIdentifier,
  MaterialType,
  ProductionOrder,
  Quantity
} from 'chronos-core-client';
import { ButtonItem, IMachineChartDialogConfiguration, MachineChartDialogComponent } from '@app/shared/components';
import { TaskAssignationService } from '@app/core/services';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import {
  ModalAdditionalInfoComponent,
  ModalChangeQuantityComponent,
  ModalMaterialStockComponent,
  ModalSetupParametersComponent
} from './components';

import { ReduceConsumptionModalComponent } from '@app/shared/modals';
import { DismountContainerModalComponent } from '@app/modules/finish-phase/containers/overview/components/dismount-container-modal';
import { UiStateQuery, UiStateService } from '@app/core/global-state';
import { ModalChangeQuantityBetweenOrdersComponent } from './components/modal-change-quantity-between-orders';
import { DowntimeViewData } from '@app/shared/modals/task-assign-modal/models';
import { DismountDummyPalletModalComponent } from '@app/shared/components/dismount-dummy-pallet-modal/dismount-dummy-pallet-modal.component';
import { EmployeeRegistrationComponent } from '@app/modules/employee-registration/components/employee-registration/employee-registration.component';

@Component({
  selector: 'app-approve',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.scss']
})
export class ApproveComponent implements OnInit, OnDestroy {
  public technologyOptions$: Observable<ListValue[]>;
  public selectedTechnology: number;
  public selectedDate: Date;
  public maxDate = new Date();
  public approvalWorkCenterOptions: SelectItem[];
  public selectedApprovalWorkCenter: number;
  public approveReportData: ApprovalReportData;
  public downtimeViewData: DowntimeViewData;
  public openDates: Date[] = [];
  public approvedDates: Date[] = [];
  public calendarStyleClass = '';

  public sendApproveReportCommand: SendApproveReportCommand;
  public confirmInformationCommand: ConfirmInformationCommand;
  public confirmSendApproveReportCommand: ConfirmSendApproveReportCommand;

  public readonly ITEM_TYPE = ApprovalReportItemType;
  public readonly PHASE_TYPE = ApprovalReportProductionOrderPhaseType;
  public readonly DOWNTIME_TYPE = DowntimeType;
  public readonly MATERIAL_TYPE = MaterialType;
  public readonly KPI_IDENTIFIER = KpiIdentifier;

  private subscriptions = new Subscription();
  private isApprovalChangeQuantitiesBetweenOrdersEnabled = false;
  private isApprovalEditProducedQuantitiesEnabled = false;
  private isApprovalEditSetupEnabled = false;
  private isApprovalEditSetupParameterEnabled = false;

  constructor(
    private approveService: ApproveService,
    private taskAssignationService: TaskAssignationService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private uiStateQuery: UiStateQuery,
    private uiStateService: UiStateService,
    private appSettingsQuery: AppSettingsQuery
  ) {}

  public ngOnInit(): void {
    this.technologyOptions$ = this.approveService.getTechnologyOptions().pipe(
      tap((options) => {
        this.setDefaultTechnology(options);
      })
    );
    this.setCommands();
    this.setDefaultDate();

    this.subscriptions.add(
      this.approveService.approveReportData$
        .pipe(
          tap(() => {
            this.getWorkCentersForApproval();
          })
        )
        .subscribe((data) => {
          const items = this.setExpandFlag(data.items);
          this.approveReportData = { ...data, items };
        })
    );

    this.subscriptions.add(this.approveService.downtimeViewData$.subscribe((data) => (this.downtimeViewData = data)));
    this.subscriptions.add(
      this.approveService.approveReportOpenDates$.subscribe(([openDates, approvedDates]) => {
        this.updateOpenDays(openDates);
        this.approvedDates = approvedDates;
      })
    );
    this.subscriptions.add(
      this.appSettingsQuery.isApprovalChangeQuantitiesBetweenOrdersEnabled$.subscribe(
        (isEnabled) => (this.isApprovalChangeQuantitiesBetweenOrdersEnabled = isEnabled)
      )
    );
    this.subscriptions.add(
      this.appSettingsQuery.isApprovalEditProducedQuantitiesEnabled$.subscribe(
        (isEnabled) => (this.isApprovalEditProducedQuantitiesEnabled = isEnabled)
      )
    );
    this.subscriptions.add(
      this.appSettingsQuery.isApprovalEditSetupEnabled$.subscribe((isEnabled) => (this.isApprovalEditSetupEnabled = isEnabled))
    );
    this.subscriptions.add(
      this.appSettingsQuery.isApprovalEditSetupParameterEnabled$.subscribe(
        (isEnabled) => (this.isApprovalEditSetupParameterEnabled = isEnabled)
      )
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public initWorkCentersForApproval(): void {
    this.uiStateService.setApproveStateSelectedTechnology(this.selectedTechnology);
    this.approveService
      .getApprovalWorkCenterOptions(this.selectedTechnology, this.selectedDate)
      .pipe(
        tap((approvalWorkCenters) => {
          this.setDefaultWorkCentersForApproval(approvalWorkCenters);
        })
      )
      .subscribe((options) => (this.approvalWorkCenterOptions = options));
  }

  public selectApprovalReport(): void {
    this.uiStateService.setApproveStateSelectedDate(this.selectedDate);
    this.uiStateService.setApproveStateSelectedWorkCenter(this.selectedApprovalWorkCenter);
    this.approveService.updateApprovalReportParams(this.selectedApprovalWorkCenter, this.selectedDate);
  }

  public constructDowntimeActions(item: ApprovalReportItem | ApprovalReportProductionDowntimeItem): ButtonItem[] {
    return [
      {
        command: () => {
          this.showDowntimeModal(item.itemId);
        },
        label: 'APPROVE.ASSIGN_DOWNTIME',
        primary: true
      },
      {
        command: () => {
          this.openMachineChart(item);
        },
        label: 'APPROVE.SPLIT_DOWNTIME',
        visible: !R.isNil(item.endTime)
      }
    ];
  }

  public constructOrderActions(item: ApprovalReportItem): ButtonItem[] {
    return [
      {
        command: () => {
          this.showChangeQuantityBetweenOrders(item);
        },
        label: 'APPROVE.CHANGE_QUANTITIES_BETWEEN_ORDERS',
        primary: true,
        visible: this.isApprovalChangeQuantitiesBetweenOrdersEnabled
      },
      {
        command: () => {
          this.setOpenCloseOrder(item);
        },
        label: item.isFinished ? 'APPROVE.OPEN_ORDER' : 'APPROVE.CLOSE_ORDER'
      }
    ];
  }

  public constructRunActions(item: ApprovalReportItem): ButtonItem[] | null {
    if (!this.isApprovalEditProducedQuantitiesEnabled) {
      return null;
    }

    return [
      {
        command: () => {
          this.showChangeQuantitiesModal(item);
        },
        label: 'APPROVE.CHANGE_QUANTITIES',
        primary: true
      }
    ];
  }

  public constructSetupActions(item: ApprovalReportRunPhaseItem): ButtonItem[] | null {
    if (!(this.isApprovalEditSetupEnabled || this.isApprovalEditSetupParameterEnabled)) {
      return null;
    }

    return [
      {
        command: () => {
          this.showChangeTimesAndQuantitiesModal(item);
        },
        label: 'APPROVE.CHANGE',
        primary: true,
        visible: this.isApprovalEditSetupEnabled
      },
      {
        command: () => {
          this.showChangeSetupParametersModal(item);
        },
        label: 'APPROVE.CHANGE_SETUP_PARAMETERS',
        visible: this.isApprovalEditSetupParameterEnabled
      }
    ];
  }

  public constructConsumptionPrimaryActions(item: ApprovalReportConsumptionItem, rowNode: TreeNode): ButtonItem[] {
    return [
      {
        command: () => {
          this.openMaterialStockModal(item, rowNode, true);
        },
        label: 'APPROVE.CONSUME'
      }
    ];
  }

  public constructConsumptionSecondaryActions(item: ApprovalReportConsumptionItem, rowNode: TreeNode): ButtonItem[] {
    return [
      {
        command: () => {
          this.openMaterialStockModal(item, rowNode, false);
        },
        label: 'APPROVE.CONSUME'
      }
    ];
  }

  public constructArticleActions(item: ApprovalReportConsumptionItem, rowNode: TreeNode): ButtonItem[] {
    return [
      {
        command: () => {
          this.openMaterialStockModal(item, rowNode, true);
        },
        label: 'APPROVE.CONSUME'
      }
    ];
  }

  public setOpenCloseOrder(item: ApprovalReportItem): void {
    this.approveService.setFinishedInterruptedOrder(item.isFinished, item.approvalReportLineId);
  }

  public isDateOpen(dateToCheck: CalendarDate): boolean {
    return this.openDates.some(
      (openDate) =>
        openDate.getFullYear() === dateToCheck.year && openDate.getMonth() === dateToCheck.month && openDate.getDate() === dateToCheck.day
    );
  }

  public getCalendarDateStyleClass(dateToCheck: CalendarDate): string {
    if (this.isDateInArray(dateToCheck, this.openDates)) {
      return 'calendar__open-day';
    } else if (this.isDateInArray(dateToCheck, this.approvedDates)) {
      return 'calendar__approved-day';
    } else {
      return '';
    }
  }

  public onDismountClick(item: ApprovalReportConsumptionItemDetail, rowNode: TreeNode): void {
    if (rowNode.parent.data.materialType === this.MATERIAL_TYPE.PRIMARY) {
      if (!item.isVirtualContainer) {
        this.dismountPrimaryMaterial(item, rowNode);
      } else {
        this.openDummyPalletDismountingModal(item, rowNode, true);
      }
    } else if (rowNode.parent.data.materialType === this.MATERIAL_TYPE.SECONDARY) {
      if (!item.isVirtualContainer) {
        this.dismountSecondaryMaterial(item, rowNode);
      } else {
        this.openDummyPalletDismountingModal(item, rowNode, false);
      }
    }
  }

  private openDummyPalletDismountingModal(item: ApprovalReportConsumptionItemDetail, rowNode: TreeNode, isPrimary: boolean): void {
    const modal = this.dialogService.open(DismountDummyPalletModalComponent, {
      header:
        isPrimary === true
          ? this.translateService.instant('DISMOUNT_DUMMY_PALLET_FORM.DISMOUNT_DUMMY_PALLET')
          : this.translateService.instant('DISMOUNT_DUMMY_PALLET_FORM.DISMOUNT_DUMMY'),
      data: {
        containerId: item.containerId,
        dismountingPalletInformation: {
          ssccCode: item.virtualContainerIdentCode,
          mounted: item.mounted,
          article: rowNode.parent.data.articleDescription,
          virtualContainerInfo: item.virtualContainerInfo,
          mountedMaterialId: item.mountedMaterialId,
          reason: item.virtualContainerReason,
          runId: rowNode.parent.data.runId,
          workCenterId: this.selectedApprovalWorkCenter
        } as DismountingPalletInformation
      }
    });

    this.subscriptions.add(
      modal.onClose.subscribe((materialDataChanged) => {
        if (materialDataChanged) {
          this.selectApprovalReport();
        }
      })
    );
  }

  private dismountPrimaryMaterial(item: ApprovalReportConsumptionItemDetail, rowNode: TreeNode): void {
    const modal = this.dialogService.open(DismountContainerModalComponent, {
      header: this.translateService.instant('DISMOUNT_CONTAINER_FORM.DISMOUNTING'),
      data: {
        containerId: item.containerId,
        dismountingPalletInformation: {
          mounted: item.mounted,
          consumed: item.consumed,
          initialQuantity: item.initialQuantity,
          ssccCode: item.ssccCode,
          mountedTime: new Date(item.mountedTime),
          mountedUser: item.mountedUser,
          mountedMaterialStatus: item.mountedMaterialStatus,
          mountedMaterialId: item.mountedMaterialId,
          printLabel: item.labelAutoPrint,
          runId: rowNode.parent.data.runId,
          internalBatchId: item.internalBatchId,
          externalBatchId: item.externalBatchId,
          workCenterId: this.selectedApprovalWorkCenter,
          inventoryGoodQuantity: item.inventoryGoodQuantity,
          grammage: item.grammage,
          thickness: item.thickness,
          bomUnitFactor: item.bomUnitFactor,
          article: {
            articleName: item.articleName,
            externalArticleId: item.externalArticleId,
            externalConfigurationId: item.configurationId
          } as ArticleDescription
        } as DismountingPalletInformation
      }
    });
    this.subscriptions.add(
      modal.onClose.subscribe((materialDataChanged) => {
        if (materialDataChanged) {
          this.selectApprovalReport();
        }
      })
    );
  }

  private dismountSecondaryMaterial(item: ApprovalReportConsumptionItemDetail, rowNode: TreeNode): void {
    const modal = this.dialogService.open(ReduceConsumptionModalComponent, {
      header: this.translateService.instant('MOUNTING.DISMOUNT'),
      data: {
        article: rowNode.parent.data.articleDescription,
        containerId: item.containerId,
        bomUnitFactor: item.bomUnitFactor,
        inventoryUnitId: item.inventoryUnitId,
        reduceInformation: {
          mounted: item.mounted,
          consumed: item.consumed,
          initialQuantity: item.initialQuantity,
          ssccCode: item.ssccCode,
          mountedMaterialStatus: item.mountedMaterialStatus,
          mountedMaterialId: item.mountedMaterialId,
          mountedTime: new Date(item.mountedTime),
          mountedUser: item.mountedUser,
          runId: rowNode.parent.data.runId,
          printLabel: item.labelAutoPrint,
          article: {
            articleName: item.articleName,
            externalArticleId: item.externalArticleId,
            externalConfigurationId: item.configurationId
          } as ArticleDescription,
          workCenterId: this.selectedApprovalWorkCenter
        } as DismountingPalletInformation
      }
    });
    this.subscriptions.add(
      modal.onClose.subscribe((materialDataChanged) => {
        if (materialDataChanged) {
          this.selectApprovalReport();
        }
      })
    );
  }

  private openMaterialStockModal(item: ApprovalReportConsumptionItem, rowNode: TreeNode, primary: boolean): void {
    const parent = rowNode.parent.data;
    const tabName = 'Secondary';
    this.dialogService
      .open(ModalMaterialStockComponent, {
        header: this.translateService.instant('APPROVE.CONSUMPTION'),
        data: {
          productionOrderId: parent.productionOrderId,
          runId: item.runId,
          articleId: item.articleId,
          isPrimary: primary,
          billOfMaterialId: item.billOfMaterialId,
          workCenterId: this.selectedApprovalWorkCenter,
          tabName
        }
      })
      .onClose.subscribe((isSubmitted: boolean) => {
        this.onCloseTableModal(isSubmitted);
      });
  }

  private onCloseTableModal(isSubmitted: boolean): void {
    if (isSubmitted) {
      this.selectApprovalReport();
    }
  }

  public updateCalendarStyleClass(newDate: CalendarDate): void {
    if (this.openDates) {
      this.calendarStyleClass = '';
      const dateToCheck = new Date(newDate.year, newDate.month - 1);
      if (this.openDates.some((date) => date < dateToCheck && date.getMonth() !== dateToCheck.getMonth())) {
        this.calendarStyleClass += 'nav-prev-danger ';
      }
      if (this.openDates.some((date) => date > dateToCheck && date.getMonth() !== dateToCheck.getMonth())) {
        this.calendarStyleClass += 'nav-next-danger ';
      }
    }
  }

  public showShiftInfo(): void {
    this.dialogService.open(ModalAdditionalInfoComponent, {
      header: this.translateService.instant('APPROVE.SHIFT_INFO_LABEL'),
      data: {
        workCenterId: this.selectedApprovalWorkCenter,
        approvalReportDate: this.approveService.extractApprovalDate(this.selectedDate)
      }
    });
  }

  public showAdditionalItemInfo(item: ApprovalReportItem | ApprovalReportProductionDowntimeItem): void {
    let modalLabel: string;
    if (item.itemType === ApprovalReportItemType.PRODUCTION_ORDER) {
      modalLabel = 'APPROVE.PRODUCTION_ORDER_INFO_LABEL';
    } else {
      modalLabel = 'APPROVE.DOWNTIME_INFO_LABEL';
    }

    this.dialogService.open(ModalAdditionalInfoComponent, {
      header: this.translateService.instant(modalLabel),
      data: {
        workCenterId: this.selectedApprovalWorkCenter,
        approvalReportDate: this.approveService.extractApprovalDate(this.selectedDate),
        itemId: item.itemId,
        itemType: item.itemType
      }
    });
  }

  public loadNode(event: any): void {
    if (event?.node?.data?.runPhaseType === ApprovalReportProductionOrderPhaseType.CONSUMPTION) {
      this.approveService
        .getApprovalConsumptionData(
          this.selectedApprovalWorkCenter,
          this.approveService.extractApprovalDate(this.selectedDate),
          event?.node?.data?.approvalReportLineId
        )
        .subscribe((data) => {
          this.addConsumptionDataToApprovalTable(data);
        });
    }
  }

  public expandTable(isExpanded: boolean): void {
    this.approveReportData.items = this.approveReportData.items.map((row) => this.rowExpand(row, { expanded: isExpanded }));
  }

  public onEmployeeRegistrationKPIClick(): void {
    this.showEmployeeRegistrationPopup();
  }

  private setExpandFlag(newTableData: TreeNode[]): TreeNode[] {
    if (R.isNil(newTableData)) {
      return [];
    }

    if (R.isNil(this.approveReportData) || R.isEmpty(this.approveReportData.items)) {
      return newTableData;
    }

    return newTableData.map((node) => {
      const row = this.approveReportData.items.find((item) => item.data.approvalReportLineId === node.data.approvalReportLineId);

      node.children = this.mapChildren(node.children, row?.children);

      return this.rowExpand(node, row);
    });
  }

  private rowExpand(newRow: TreeNode, oldRow?: TreeNode): TreeNode {
    const row = {
      ...newRow,
      expanded: oldRow?.expanded
    };

    if (typeof row.leaf === 'boolean' && !row.leaf) {
      row.children = oldRow?.children;
    }

    return row;
  }

  private mapChildren(newRow: TreeNode[], oldRow?: TreeNode[]): TreeNode[] {
    return newRow.map((newNode) => {
      const row = oldRow?.find((oldNode) => newNode.data.orderPhaseId === oldNode.data.orderPhaseId);

      if (row?.expanded && !row.leaf) {
        this.loadNode({ node: newNode });
      }

      return this.rowExpand(newNode, row);
    });
  }

  private addConsumptionDataToApprovalTable(data: ApprovalReportConsumption): void {
    this.approveReportData.items = this.approveService.getConsumptionToApprovalData(this.approveReportData.items, data);
  }

  private getWorkCentersForApproval(): void {
    this.approveService
      .getApprovalWorkCenterOptions(this.selectedTechnology, this.selectedDate)
      .subscribe((options) => (this.approvalWorkCenterOptions = options));
  }

  private setDefaultDate(): void {
    const storedDate = this.uiStateQuery.getApproveStateSelectedDate();
    if (storedDate) {
      this.selectedDate = storedDate;
    } else {
      this.selectedDate = moment(new Date()).subtract(1, 'days').toDate();
    }
  }

  private setDefaultTechnology(workCenterGroups: ListValue[]): void {
    if (workCenterGroups.length) {
      const storedTechnologyId = this.uiStateQuery.getApproveStateSelectedTechnology();
      const isAvailable = workCenterGroups.some((item) => (item.value as number) === storedTechnologyId);

      this.selectedTechnology = isAvailable ? storedTechnologyId : (R.head(workCenterGroups).value as number);
      this.initWorkCentersForApproval();
    }
  }

  private setDefaultWorkCentersForApproval(approvalWorkCenters: SelectItem[]): void {
    if (approvalWorkCenters.length) {
      const storedWorkCenterId = this.uiStateQuery.getApproveStateSelectedWorkCenter();
      const isAvailable = approvalWorkCenters.some((item) => item.value === storedWorkCenterId);

      this.selectedApprovalWorkCenter = isAvailable ? storedWorkCenterId : R.head(approvalWorkCenters).value;
      this.selectApprovalReport();
    }
  }

  private showDowntimeModal(downtimeId: number): void {
    this.approveService
      .getDowntime(downtimeId)
      .pipe(
        tap((downtime) =>
          this.taskAssignationService.openAssignTaskModal(downtime, this.downtimeViewData, false, this.selectedApprovalWorkCenter)
        )
      )
      .subscribe();
  }

  private openMachineChart(item: ApprovalReportItem | ApprovalReportProductionDowntimeItem): void {
    this.dialogService
      .open(MachineChartDialogComponent, {
        width: '75%',
        styleClass: 'no-scroll-dialog',
        header: this.translateService.instant('MACHINE_CHART.EDIT_CHART_TITLE'),
        data: {
          workCenterId: this.selectedApprovalWorkCenter,
          productionOrderId: item.itemId,
          isMachineChartInSetupPhase: false,
          isChartForSplitDowntime: true,
          isChartInApproveSetup: false,
          startTime: new Date(item.startTime),
          endTime: !!item.endTime ? new Date(item.endTime) : null,
          downTimeId: item.itemId,
          quantity: null
        } as IMachineChartDialogConfiguration
      })
      .onClose.subscribe((isSubmitted: boolean) => {
        this.onCloseTableModal(isSubmitted);
      });
  }

  private setCommands(): void {
    this.confirmInformationCommand = new ConfirmInformationCommand(this.approveService);
    this.confirmInformationCommand.buttonText = 'APPROVE.CONFIRM_INFO';

    this.sendApproveReportCommand = new SendApproveReportCommand(this.approveService);
    this.sendApproveReportCommand.buttonText = 'APPROVE.APPROVE_PROFILE_DAY';

    this.confirmSendApproveReportCommand = new ConfirmSendApproveReportCommand(this.approveService);
    this.confirmSendApproveReportCommand.buttonText = 'APPROVE.APPROVE_PROFILE_DAY';

    this.approveService.setCommands(this.confirmInformationCommand, this.sendApproveReportCommand, this.confirmSendApproveReportCommand);
  }

  private showChangeQuantityBetweenOrders(item: ApprovalReportItem): void {
    const approveReports: TreeNode[] = this.approveReportData.items.filter((approveReport) => {
      if (approveReport.data.itemType === ApprovalReportItemType.PRODUCTION_ORDER) {
        return approveReport;
      }
    });

    let nextApprovalReportLineId = 0;
    const nextGoodQuantityValue: Quantity = { value: 0, unitId: 'SHT' };
    let secondProdOrder = '';
    let counter = 0;
    // TODO-MR: MK,RS Better if Backend sends the data
    approveReports.forEach((element) => {
      if (element.data.approvalReportLineId === item.approvalReportLineId && counter < approveReports.length) {
        nextGoodQuantityValue.value = approveReports[counter + 1]?.data.goodQuantity.value ?? 0;
        nextGoodQuantityValue.unitId = approveReports[counter + 1]?.data.goodQuantity.unitId ?? 'SHT';
        secondProdOrder = approveReports[counter + 1]?.data.productionOrderIdOrDowntimeType;
        nextApprovalReportLineId = approveReports[counter + 1]?.data.approvalReportLineId ?? 0;
      }
      counter++;
    });

    if (secondProdOrder !== undefined) {
      const data: ModalChangeQuantityBetweenOrdersComponentData = {
        workCenterId: this.selectedApprovalWorkCenter,
        approvalReportDate: this.approveService.extractApprovalDate(this.selectedDate),
        approvalReportLineId: item.approvalReportLineId,
        firstProductionOrder: item?.productionOrderIdOrDowntimeType,
        secondProductionOrder: secondProdOrder,
        goodQuantityFirstProductionOrder: item?.goodQuantity,
        goodQuantitySecondProductionOrder: nextGoodQuantityValue,
        secondApproveReportLineId: nextApprovalReportLineId
      };

      this.dialogService
        .open(ModalChangeQuantityBetweenOrdersComponent, {
          data,
          header: this.translateService.instant('APPROVE.MODAL_CHANGE_QUANTITY_HEADER')
        })
        .onClose.subscribe((isSubmitted) => {
          this.onCloseTableModal(isSubmitted);
        });
    } else {
      LogService.warn('APPROVE.NO_SECOND_ORDER');
    }
  }

  private showChangeQuantitiesModal(item: ApprovalReportItem): void {
    const data: ModalChangeQuantityComponentData = {
      workCenterId: this.selectedApprovalWorkCenter,
      approvalReportDate: this.approveService.extractApprovalDate(this.selectedDate),
      approvalReportLineId: item.approvalReportLineId,
      goodQuantity: item.goodQuantity,
      waste: item.wasteQuantity,
      maculature: item.maculatureQuantity
    };

    this.dialogService
      .open(ModalChangeQuantityComponent, {
        width: '75%',
        styleClass: 'no-scroll-dialog',
        data,
        header: this.translateService.instant('APPROVE.MODAL_CHANGE_QUANTITY_HEADER')
      })
      .onClose.subscribe((isSubmitted) => {
        this.onCloseTableModal(isSubmitted);
      });
  }

  private showChangeTimesAndQuantitiesModal(item: ApprovalReportRunPhaseItem): void {
    this.dialogService.open(MachineChartDialogComponent, {
      width: '75%',
      styleClass: 'no-scroll-dialog',
      header: this.translateService.instant('MACHINE_CHART.EDIT_CHART_TITLE'),
      data: {
        workCenterId: this.selectedApprovalWorkCenter,
        runId: item.runId,
        productionOrderId: item.productionOrderId,
        isMachineChartInSetupPhase: true,
        isChartForSplitDowntime: false,
        isChartInApproveSetup: true,
        startTime: new Date(item.startTime),
        endTime: !!item.endTime ? new Date(item.endTime) : null,
        waste: item.wasteQuantity,
        maculature: item.maculatureQuantity,
        quantity: {
          value: item.wasteQuantity?.value + item.maculatureQuantity?.value ?? 0,
          unitId: item.wasteQuantity?.unitId
        } as Quantity,
        approvalReportLineId: item.approvalReportLineId,
        approvalReportDate: this.approveService.extractApprovalDate(this.selectedDate)
      } as IMachineChartDialogConfiguration
    });
  }

  private showChangeSetupParametersModal(item: ApprovalReportRunPhaseItem): void {
    this.dialogService
      .open(ModalSetupParametersComponent, {
        header: this.translateService.instant('APPROVE.CHANGE_SETUP_PARAMETERS'),
        data: {
          workCenterId: this.selectedApprovalWorkCenter,
          approvalReportLineId: item.approvalReportLineId,
          approvalReportDate: this.approveService.extractApprovalDate(this.selectedDate),
          orderItem: {
            productionOrderId: item.productionOrderId
          } as ProductionOrder
        }
      })
      .onClose.subscribe((isSubmitted) => {
        this.onCloseTableModal(isSubmitted);
      });
  }

  private updateOpenDays(openDates: Date[]): void {
    this.openDates = openDates;
    const newDate: CalendarDate = {
      year: this.selectedDate.getFullYear(),
      month: this.selectedDate.getMonth() + 1
    };
    this.updateCalendarStyleClass(newDate);
  }

  private isDateInArray(date: CalendarDate, dateArray: Date[]): boolean {
    return dateArray.some(
      (openDate) => openDate.getFullYear() === date.year && openDate.getMonth() === date.month && openDate.getDate() === date.day
    );
  }

  private showEmployeeRegistrationPopup(): void {
    this.dialogService.open(EmployeeRegistrationComponent, {
      header: this.translateService.instant('SHIFT_REPORT.OPEN_TASK_EMPLOYEEREGISTRATION'),
      closable: false,
      data: {
        isLoginDisabled: true,
        approvalReportDate: this.approveService.extractApprovalDate(this.selectedDate),
        approvalWorkCenterId: this.selectedApprovalWorkCenter,
        enableSumbit: true
      }
    });
  }
}
