import { Component, OnDestroy, OnInit } from '@angular/core';
import { FinishFinishingCommandService, PreviousFinishingSubphaseCommandService } from '@app/modules/finish-phase/commands';
import { SetupFooterService } from '@app/shared/components';
import { nav } from '@app/shared/utils';
import { Subscription } from 'rxjs';
import { KpiQuery } from '@app/modules/finish-phase/state/kpi/kpi.query';
import { FinishPhaseService } from '@app/modules/finish-phase/services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.scss']
})
export class EndComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public infoToKpi: string;
  public infoToProductionOrder: string;
  public commentValidation = false;
  private backCommand: PreviousFinishingSubphaseCommandService;
  private finishCommand: FinishFinishingCommandService;
  public isInvalidForm = true;
  private finishedInfo: Subscription;
  private subscriptions = new Subscription();
  private isKpiCommentMandatory: boolean;
  private isKpiViolated: boolean;

  private readonly KPI_INFO_LENGTH = 3;
  private readonly KPI_INFO = 'kpiInfo';

  constructor(
    public kpiQuery: KpiQuery,
    private finishPhaseService: FinishPhaseService,
    private setupFooterService: SetupFooterService,
    private formBuilder: UntypedFormBuilder
  ) {}

  public ngOnInit(): void {
    this.finishedInfo = this.finishPhaseService.getFinishedInfo().subscribe((finishedInfo) => {
      this.infoToKpi = finishedInfo.kpiInfo ?? '';
      this.infoToProductionOrder = finishedInfo.productionJobInfo;
      this.isKpiCommentMandatory = finishedInfo.isKpiCommentMandatorySetting;

      this.kpiQuery.warningKpis$.subscribe((items) => {
        this.isKpiViolated = items.length > 0;
        this.commentValidation = this.isKpiCommentMandatory === true && items.length > 0 && this.infoToKpi.length < this.KPI_INFO_LENGTH;

        this.form.get(this.KPI_INFO).setValue(this.infoToKpi);
        this.form
          .get(this.KPI_INFO)
          .setValidators(this.isKpiCommentMandatory === true && items.length > 0 ? [Validators.required, Validators.minLength(3)] : []);

        this.form.updateValueAndValidity();
      });
    });

    this.initForm(this.infoToKpi);

    this.subscriptions.add(
      this.form.get(this.KPI_INFO).valueChanges.subscribe((newValue) => {
        this.commentValidation = this.isKpiCommentMandatory === true && this.isKpiViolated && newValue.length < this.KPI_INFO_LENGTH;
        this.setCommands(this.commentValidation);
      })
    );
  }

  private initForm(kpiInfo: string): void {
    this.form = this.formBuilder.group({
      kpiInfo: [kpiInfo, []]
    });
  }

  public ngOnDestroy(): void {
    this.finishedInfo.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  public saveKpiInfo(): void {
    this.infoToKpi = this.form.get(this.KPI_INFO).value;
    this.finishPhaseService.setFinishedInfos(this.infoToProductionOrder, this.infoToKpi).subscribe();
  }

  private setCommands(isInvalidForm: boolean): void {
    this.backCommand = new PreviousFinishingSubphaseCommandService(this.setupFooterService);
    this.backCommand.setNavigationParams(nav.routes.finishOverview);

    this.finishCommand = new FinishFinishingCommandService(this.finishPhaseService);
    this.finishCommand.buttonText = 'FINISH_PHASE.FINISH';
    this.finishCommand.enabled = !isInvalidForm;
    this.finishPhaseService.setCommands(this.backCommand, this.finishCommand);
  }
}
