import { Injectable } from '@angular/core';
import { ProductionOrderListStore } from './production-order-list.store';
import { MachineSchedule, ProductionOrderStatus } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class ProductionOrderListService {
  constructor(private productionOrderListStore: ProductionOrderListStore) {}

  public resetProductionOrdersStore(): void {
    this.productionOrderListStore.reset();
  }

  public endProductionOrder(productionOrderId: number): void {
    this.productionOrderListStore.remove(productionOrderId);
  }

  public reopenProductionOrder(id: number): void {
    this.productionOrderListStore.update(id, { status: ProductionOrderStatus.REOPENED, isNextProductionOrder: false });
  }

  public setProductionOrders(productionOrders: MachineSchedule[]): void {
    this.productionOrderListStore.set(productionOrders);
  }

  public setCheckedProductionOrder(id: number): void {
    this.productionOrderListStore.update(id, { status: ProductionOrderStatus.CHECKED });
  }

  public setUncheckedProductionOrder(id: number): void {
    this.productionOrderListStore.update(id, { status: ProductionOrderStatus.OPEN });
  }
}
