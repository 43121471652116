import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveWorkCenterService } from '@app/core/workcenter';
import {
  ChangeMountedMaterialSequenceEntry,
  DismountPrimaryContainerEntry,
  MaterialAvailableAtLocation,
  MountAndConsumePrimaryContainerEntry,
  MountAndConsumePrimaryContainersEntry,
  MountPrimaryContainerResponse,
  PrimaryMaterial,
  PrimaryMaterialBlockConsumed,
  PrimaryMaterialMounted,
  PrimaryMaterialService,
  ReplaceVirtualPrimaryContainerEntity,
  WhiteWasteEntry,
  WorkCenterIdEntry,
  CreateAndMountVirtualPrimaryContainerEntry,
  MaterialService,
  MountContainerEntry,
  MountContainerResponse,
  MaterialType
} from 'chronos-core-client';
import { MaterialFilterCriteria, MaterialLocationsMode, MaterialLocationsSelectedValueMode } from 'projects/chronos-shared/src/lib/models';
import { Quantity } from 'projects/chronos-core-client/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class PrimaryMaterialDsService {
  constructor(
    private primaryMaterialService: PrimaryMaterialService,
    private activeWorkCenterService: ActiveWorkCenterService,
    private materialService: MaterialService
  ) {}

  public getPrimaryMaterialBlocks(): Observable<PrimaryMaterial> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.primaryMaterialService.getCardViewGroupByMaterial(workCenterId);
  }

  public getMaterialListAtMachine(productionOrderId?: number, isOverviewTab?: boolean): Observable<MaterialAvailableAtLocation> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    if (productionOrderId && isOverviewTab) {
      return this.materialService.getAvailableMaterials(this.setParameters(productionOrderId));
    }

    if (productionOrderId && !isOverviewTab) {
      const params: PrimaryMaterialService.GetMaterialAvailableAtMachineForProductionOrderParams = {
        workCenterId,
        productionOrderId
      };
      return this.primaryMaterialService.getMaterialAvailableAtMachineForProductionOrder(params);
    }

    return this.primaryMaterialService.getMaterialAvailableAtMachine(workCenterId);
  }

  private setParameters(productionOrderId: number): MaterialService.GetAvailableMaterialsParams {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    const params: MaterialService.GetAvailableMaterialsParams = {
      workCenterId,
      ProductionOrderId: productionOrderId,
      MaterialLocationsMode: MaterialLocationsMode.MachineLocation,
      MaterialFilterCriteria: MaterialFilterCriteria.PrimaryAndSecondary,
      MaterialLocationsSelectedValueMode: MaterialLocationsSelectedValueMode.CountAndSumAndContainers
    };

    return params;
  }

  public consumeContainer(
    workCenterId: number,
    containerId: number,
    quantity: number,
    runId: number,
    billOfMaterialId: number
  ): Observable<null> {
    const entry: MountAndConsumePrimaryContainerEntry = {
      containerId,
      quantity,
      runId,
      billOfMaterialId
    };
    const params: PrimaryMaterialService.ConsumePrimaryContainerParams = {
      workCenterId,
      entry
    };
    return this.primaryMaterialService.consumePrimaryContainer(params);
  }

  public getMaterialListAtWarehouse(productionOrderId?: number): Observable<MaterialAvailableAtLocation> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();

    if (productionOrderId) {
      const params: PrimaryMaterialService.GetMaterialAvailableAtWarehouseForProductionOrderParams = {
        workCenterId,
        productionOrderId
      };
      return this.primaryMaterialService.getMaterialAvailableAtWarehouseForProductionOrder(params);
    } else {
      return this.primaryMaterialService.getMaterialAvailableAtWarehouse(workCenterId);
    }
  }

  public getMaterialListForProductionOrder(workCenterId: number, productionOrderId: number): Observable<MaterialAvailableAtLocation> {
    const params: PrimaryMaterialService.GetMaterialAvailableForProductionOrderParams = {
      workCenterId,
      productionOrderId
    };
    return this.primaryMaterialService.getMaterialAvailableForProductionOrder(params);
  }

  public getNextContainerForDismount(): Observable<PrimaryMaterialMounted> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.primaryMaterialService.getNextPrimaryContainerForDismount(workCenterId);
  }

  public dismountContainer(
    containerId: number,
    mountedMaterialId: number,
    remainingQuantity: number,
    shouldPrintLabel: boolean,
    runId: number = null,
    workCenterId: number = null
  ): Observable<null> {
    workCenterId = workCenterId ?? this.activeWorkCenterService.getWorkCenterId();
    const entry: DismountPrimaryContainerEntry = {
      mountedMaterialId,
      remainingQuantity,
      shouldPrintLabel,
      workCenterId,
      runId
    };
    const params: PrimaryMaterialService.DismountContainerParams = {
      containerId,
      entry
    };
    return this.primaryMaterialService.dismountContainer(params);
  }

  public changeMountedMaterialSequence(
    materialBlockId: number,
    mountedMaterialId: number,
    insertBeforeMountedMaterialId?: number
  ): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: ChangeMountedMaterialSequenceEntry = {
      insertBeforeMountedMaterialId,
      mountedMaterialId,
      workCenterId
    };
    const params: PrimaryMaterialService.ChangeMountedMaterialSequenceParams = {
      materialBlockId,
      entry
    };
    return this.primaryMaterialService.changeMountedMaterialSequence(params);
  }

  public getConsumption(): Observable<PrimaryMaterialBlockConsumed> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    return this.primaryMaterialService.getConsumption(workCenterId);
  }

  public mountContainerBySSCC(identificationCode: string, whiteWasteQuantity?: Quantity): Observable<MountPrimaryContainerResponse> {
    return this.mountPrimaryContainer({ identificationCode, whiteWasteQuantity });
  }

  public mountPrimaryContainerByIdAndProductionOrder(
    containerId: number,
    productionOrderId: number,
    whiteWasteQuantity?: Quantity
  ): Observable<MountPrimaryContainerResponse> {
    return this.mountPrimaryContainer({ containerId, productionOrderId, whiteWasteQuantity });
  }

  public mountPrimaryContainerByContainerAndProductionOrder(
    identificationCode: string,
    productionOrderId: number,
    whiteWasteQuantity?: Quantity
  ): Observable<MountPrimaryContainerResponse> {
    return this.mountPrimaryContainer({ identificationCode, productionOrderId, whiteWasteQuantity });
  }

  private mountPrimaryContainer(containerEntry: MountContainerEntry): Observable<MountContainerResponse> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    containerEntry.materialType = MaterialType.PRIMARY;
    const params: MaterialService.MountContainerParams = {
      workCenterId,
      entry: containerEntry
    };
    return this.materialService.mountContainer(params);
  }

  public replaceContainer(mountedMaterialId: number, virtualContainerId: number, newContainerId: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: ReplaceVirtualPrimaryContainerEntity = {
      newContainerId,
      virtualContainerId,
      workCenterId
    };
    const params: PrimaryMaterialService.ReplaceVirtualPrimaryContainerParams = {
      entry,
      mountedMaterialId
    };
    return this.primaryMaterialService.replaceVirtualPrimaryContainer(params);
  }

  public addWhiteWasteToMountedMaterial(mountedMaterialId: number, whiteWaste: Quantity): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: WhiteWasteEntry = {
      workCenterId,
      waste: whiteWaste
    };
    const params: PrimaryMaterialService.AddWhiteWasteToMountedMaterialParams = {
      entry,
      mountedMaterialId
    };
    return this.primaryMaterialService.addWhiteWasteToMountedMaterial(params);
  }

  public removeWhiteWasteFromMountedMaterial(mountedMaterialId: number): Observable<null> {
    const workCenterId = this.activeWorkCenterService.getWorkCenterId();
    const entry: WorkCenterIdEntry = {
      workCenterId
    };
    const params: PrimaryMaterialService.DeleteWhiteWasteAssignmentParams = {
      mountedMaterialId,
      entry
    };
    return this.primaryMaterialService.deleteWhiteWasteAssignment(params);
  }

  public consumeContainers(workCenterId: number, containerIds: number[], runId: any, billOfMaterialId: number): Observable<null> {
    const entry: MountAndConsumePrimaryContainersEntry = {
      containerIds,
      runId,
      billOfMaterialId
    };
    const params: PrimaryMaterialService.ConsumePrimaryContainer2Params = {
      workCenterId,
      entry
    };
    return this.primaryMaterialService.consumePrimaryContainer2(params);
  }

  public addVirtualContainer(virtualContainerEntry: CreateAndMountVirtualPrimaryContainerEntry): Observable<null> {
    const params: PrimaryMaterialService.CreateAndMountVirtualPrimaryContainerParams = {
      workCenterId: this.activeWorkCenterService.getWorkCenterId(),
      entry: virtualContainerEntry
    };
    return this.primaryMaterialService.createAndMountVirtualPrimaryContainer(params);
  }
}
