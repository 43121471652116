import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ActiveOrderDsService } from '@app/core/data-services';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { nav } from '../utils/nav';

@Injectable()
export class ActiveOrderMountGuard implements CanActivate {
  constructor(private activeOrderDsService: ActiveOrderDsService, private router: Router) {}

  public canActivate(): Observable<boolean> {
    return this.activeOrderDsService.selectActiveOrder().pipe(
      map((activeOrder$) => !!activeOrder$),
      tap((isOrderActive) => {
        if (!isOrderActive) {
          return this.router.navigate([nav.routes.mountingOverview]);
        }
      })
    );
  }
}
