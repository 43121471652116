import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveOrderDsService } from '@app/core/data-services';
import { PatternDetail } from 'chronos-core-client';
import { PatternInfoService } from 'chronos-core-client';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pattern-info',
  templateUrl: './pattern-info.component.html',
  styleUrls: ['./pattern-info.component.scss']
})
export class PatternInfoComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  public patternDetail: PatternDetail;
  public isPatternAvailable = false;

  constructor(private patternInfoService: PatternInfoService, private activeOrderDsService: ActiveOrderDsService) {}

  public ngOnInit(): void {
    const productionOrderId = this.activeOrderDsService.getActiveOrderId();
    this.subscriptions.add(
      this.patternInfoService.getProductionOrderPatternInfo(productionOrderId).subscribe((selectedNcModel) => {
        this.patternDetail = selectedNcModel;
        this.isPatternAvailable = this.patternDetail.patternInfo.length === 0 ? false : true;
      })
    );
  }

  public lastButtonClick() {
    console.info('last button click !!');
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
