import { Component, OnInit, OnDestroy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SettingFieldType, SettingModel } from '../../models';
import { SettingHistoryViewModel } from 'chronos-core-client';
import { Subscription } from 'rxjs';
import { ResourceConfigurationService } from '@app/modules/resource-configuration/services';

@Component({
  selector: 'app-configuration-history',
  templateUrl: './configuration-history.component.html',
  styleUrls: ['./configuration-history.component.scss']
})
export class ConfigurationHistoryComponent implements OnInit, OnDestroy {
  public readonly COLUMNS = [
    { label: 'RESOURCE_CONFIGURATION.LEVEL', width: '140px' },
    { label: 'RESOURCE_CONFIGURATION.PREVIOUS_VALUE', width: '140px' },
    { label: 'RESOURCE_CONFIGURATION.VALUE', width: '140px' },
    { label: 'RESOURCE_CONFIGURATION.CHANGED_BY', width: '140px' },
    { label: 'RESOURCE_CONFIGURATION.CHANGE_TIME', width: '140px' }
  ];
  public value: SettingHistoryViewModel[] = [];
  public tenantName: string;
  public workCenterGroupName: string;
  public workCenterName: string;
  public hirarchyLevel: string;
  public setting: SettingModel;
  public currentValue: string;
  public scrollHeight = 'auto';

  private subscriptions = new Subscription();
  private readonly DEFAULT_HIERARCHY_TYPE = 'WorkCenter';
  private entityId: string;
  private settingType: string;

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private resourceConfigurationService: ResourceConfigurationService
  ) {}

  public ngOnInit(): void {
    this.tenantName = this.config.data.tenantName;
    this.workCenterGroupName = this.config.data.workCenterGroupName;
    this.workCenterName = this.config.data.workCenterName;
    this.hirarchyLevel = this.config.data.hirarchyLevel;
    this.setting = this.config.data.historySetting;
    this.entityId = this.workCenterName;
    this.settingType = this.setting.settingValueType;

    if (this.workCenterName.length === 0 && this.workCenterGroupName.length > 0) {
      this.entityId = this.workCenterGroupName;
    } else if (this.workCenterGroupName.length === 0) {
      this.entityId = this.tenantName;
    }

    this.subscriptions.add(
      this.resourceConfigurationService
        .getSettingHistory(this.setting.settingId, this.DEFAULT_HIERARCHY_TYPE, this.hirarchyLevel, this.entityId)
        .subscribe((historySetting) => {
          this.value = historySetting;
          if(this.value.length > 10) {
            this.scrollHeight = '400px';
          }
        })
    );

    this.alignHeaderText();
  }

  public alignHeaderText(){
    document.getElementsByClassName('p-dialog-title')[0].setAttribute('style', 'padding-left: 0.8em !important;');
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onCloseModal(): void {
    this.ref.close(false);
  }

  public getCurrentSettingValue() {
    let currentValue = this.setting.stringValue;
    switch (this.settingType) {
      case SettingFieldType.BooleanType:
        currentValue = this.isNullCheck(this.setting.boolValue);
        break;
      case SettingFieldType.DateTimeType:
        currentValue = this.setting.dateTimeValue;
        break;
      case SettingFieldType.EnumMultiSelectType:
        currentValue = this.setting.enumValueMultiselect;
        break;
      case SettingFieldType.EnumType:
        currentValue = this.setting.enumValue;
        break;
      case SettingFieldType.IntegerType:
        currentValue = this.isNullCheck(this.setting.intValue);
        break;
      case SettingFieldType.TimeSpanType:
        currentValue = this.setting.timeSpanValue;
        break;
    }
    return currentValue;
  }

  public getHistorySettingValue(settingHistory: SettingHistoryViewModel, settingValue: string) {
    let currentValue = settingValue === 'current' ? settingHistory.stringValue : settingHistory.stringValuePrev;
    switch (this.settingType) {
      case SettingFieldType.BooleanType:
        currentValue =
          settingValue === 'current' ? this.isNullCheck(settingHistory.boolValue) : this.isNullCheck(settingHistory.boolValuePrev);
        break;
      case SettingFieldType.DateTimeType:
        currentValue = settingValue === 'current' ? settingHistory.dateTimeValue : settingHistory.dateTimeValuePrev;
        break;
      case SettingFieldType.IntegerType:
        currentValue =
          settingValue === 'current' ? this.isNullCheck(settingHistory.intValue) : this.isNullCheck(settingHistory.intValuePrev);
        break;
      case SettingFieldType.TimeSpanType:
        currentValue = settingValue === 'current' ? settingHistory.timeSpanValue : settingHistory.timeSpanValuePrev;
        break;
    }
    return currentValue;
  }

  private isNullCheck(value: any) {
    if (value) {
      return value.toString();
    }
    return value;
  }
}
