import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { nav } from '@app/shared/utils';
import { AppSettingsQuery } from 'chronos-shared';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QaLogGuard implements CanActivate, CanActivateChild {
  constructor(private appSettingsQuery: AppSettingsQuery) {}

  public canActivate(): Observable<boolean> {
    return this.appSettingsQuery.isQaHistoryTabEnabled$;
  }

  public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (state.url === nav.routes.qaLogHistory) {
      return this.appSettingsQuery.isQaHistoryTabEnabled$;
    } else {
      return of(false);
    }
  }
}
