<div class="output-container-list">
  <app-pattern-info></app-pattern-info>
  <ng-container *ngIf="isStationTabsVisible">
    <app-output-station-tab [outputStationTabs]="outputStationTabs"></app-output-station-tab>
  </ng-container>
  <app-output-quantity-pool
    [manualModeCutting]="manualModeCutting"
    [manualCreation]="isManualModeForContainer$ | async"
    [grossQuantity]="grossQuantity"
    (createPalletClicked)="createPallet()"
    (changePalletQuantityClicked)="changeContainerQuantity()"
    (cancelProductionOrderClicked)="cancelProductionOrder()"
    (openPeriodicQaCheckClicked)="openPeriodicQACheckDialog()"
    (openChangeBobbinQuantityClicked)="openChangeBobbinQuantityDialog()"
    [isCancelProductionOrder]="isCancelProductionOrder"
    [isManualCheckAvailable]="isManualCheckAvailable"
    [slitCount]="slitCount"
    [quantityPool]="quantityPool"
    [targetQuantity]="targetQuantity"
    [outputStationQuantities]="outputStationQuantities"
    [activeOutputStationId]="activeOutputStationId"
  ></app-output-quantity-pool>
  <ng-container *ngIf="isPalletVisible">
    <div *ngFor="let activePallet of visiblePallets$ | async; trackBy: trackByFunction">
      <app-pallet
        [manualMode]="manualMode"
        [outputPallet]="activePallet"
        [reasonOptions]="reasonOptions"
        [grossQuantity]="grossQuantity"
        (finishedCheck)="handleFinishedCheckChange($event)"
        (finishClicked)="onFinishClicked($event)"
        (changeContainerQuantityClicked)="changePalletQuantity($event)"
        (changePalletBobbinQuantityClicked)="openChangeBobbinQuantityDialog($event)"
        [isExpanded]="getIsExpandedWithDefaultValue()"
        (lastClicked)="onLastClick($event)"
        [slitCount]="slitCount"
      ></app-pallet>
    </div>
  </ng-container>
  <app-container-collapse [marginLeft]="45" [collapseGroup]="otherPalletGroups$ | async" [isExpanded]="arePalletsExpanded">
    <ng-container *ngFor="let otherPallet of otherPallets$ | async; trackBy: trackByFunction">
      <app-pallet
        [manualMode]="manualMode"
        [outputPallet]="otherPallet"
        [reasonOptions]="reasonOptions"
        [grossQuantity]="grossQuantity"
        (finishedCheck)="handleFinishedCheckChange($event)"
        (finishClicked)="onFinishClicked($event)"
        (changeContainerQuantityClicked)="changePalletQuantity($event)"
        (changePalletBobbinQuantityClicked)="openChangeBobbinQuantityDialog($event)"
        [isExpanded]="arePalletsExpanded"
        (lastClicked)="onLastClick($event)"
        [slitCount]="slitCount"
      ></app-pallet>
    </ng-container>
  </app-container-collapse>
</div>
<div class="spinner" *ngIf="!isPalletVisible">
  {{ 'TRACEABILITY.LOADING' | translate }}&nbsp;
  <i class="pi pi-spin pi-spinner"></i>
</div>
