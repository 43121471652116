<div class="status-bar">
  <app-status-bar-item [itemIcon]="STATUS_ICON.Waste" [itemStatus]="wasteStatus"></app-status-bar-item>
  <app-status-bar-item
    [itemIcon]="STATUS_ICON.ForwardWaste"
    [itemStatus]="forwardWaste"
    *ngIf="isForwardWasteCheck()"
  ></app-status-bar-item>
  <app-status-bar-item [itemIcon]="STATUS_ICON.Qa" [itemStatus]="qaStatus"></app-status-bar-item>
  <app-status-bar-item [itemIcon]="STATUS_ICON.Print" [itemStatus]="printStatus"></app-status-bar-item>
</div>
