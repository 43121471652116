import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { ApprovalReportItemType, InfoEntryType, InfoList, ShiftInfoList } from 'chronos-core-client';
import { ApproveDsService } from '@app/core/data-services';

@Component({
  selector: 'app-additional-shift-info',
  templateUrl: './modal-additional-info.component.html',
  styleUrls: ['./modal-additional-info.component.scss']
})
export class ModalAdditionalInfoComponent implements OnInit {
  public additionalInfo$: Observable<InfoList | ShiftInfoList>;

  public readonly INFO_TYPE = InfoEntryType;

  private workCenterId: number;
  private approvalReportDate: string;
  private itemType: ApprovalReportItemType;
  private itemId: number;

  constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef, private approveDsService: ApproveDsService) {}

  public ngOnInit(): void {
    this.workCenterId = this.config.data?.workCenterId;
    this.approvalReportDate = this.config.data?.approvalReportDate;
    this.itemId = this.config.data?.itemId;
    this.itemType = this.config.data?.itemType;
    this.additionalInfo$ = this.getAdditionalInfo();
  }

  public onSubmitClick(): void {
    this.ref.close();
  }

  private getAdditionalInfo(): Observable<InfoList | ShiftInfoList> {
    switch (this.itemType) {
      case ApprovalReportItemType.PRODUCTION_ORDER: {
        return this.approveDsService.getProductionOrderInfo(this.workCenterId, this.approvalReportDate, this.itemId);
      }
      case ApprovalReportItemType.DOWNTIME: {
        return this.approveDsService.getDowntimeInfo(this.workCenterId, this.approvalReportDate, this.itemId);
      }
      case ApprovalReportItemType.DOWNTIME_DETAIL: {
        return this.approveDsService.getDowntimeInfo(this.workCenterId, this.approvalReportDate, this.itemId);
      }
      case ApprovalReportItemType.SETUP_DETAIL: {
        return this.approveDsService.getDowntimeInfo(this.workCenterId, this.approvalReportDate, this.itemId);
      }
      default: {
        return this.approveDsService.getShiftInfo(this.workCenterId, this.approvalReportDate);
      }
    }
  }
}
