import { Component, OnInit } from '@angular/core';
import { ActiveOrderPanelService } from '@app/core/services';
import { nav } from '@app/shared/utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setup-default-redirect',
  templateUrl: './setup-default-redirect.component.html',
  styleUrls: ['./setup-default-redirect.component.scss']
})
export class SetupDefaultRedirectComponent implements OnInit {
  constructor(private activeOrderPanelService: ActiveOrderPanelService, private router: Router) {}

  public ngOnInit(): void {
    let isQaHistoryAvailable;
    this.activeOrderPanelService.isQaHistoryAvailableForSetup().subscribe((val) => (isQaHistoryAvailable = val));

    let isLineClearanceEnable;
    this.activeOrderPanelService.isLineClearanceAvailableForSetup().subscribe((val) => (isLineClearanceEnable = val));

    let isPatternAvailable;
    this.activeOrderPanelService.isPatternInfoAvailableForOrder().subscribe((val) => (isPatternAvailable = val));

    if (isLineClearanceEnable) {
      this.router.navigate([nav.routes.setupLineClearance]);
    } else if (isQaHistoryAvailable) {
      this.router.navigate([nav.routes.setupQaHistory]);
    } else if (isPatternAvailable) {
      this.router.navigate([nav.routes.setupPatternInfo]);
    } else {
      this.router.navigate([nav.routes.setupParameters]);
    }
  }
}
