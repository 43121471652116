import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-output-station-tab',
  templateUrl: './output-station-tab.component.html',
  styleUrls: ['./output-station-tab.component.scss']
})
export class OutputStationTabComponent implements OnInit {
  @Input() public outputStationTabs: MenuItem[] = [];

  constructor() {}

  public ngOnInit(): void {}
}
