import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { setupPhaseFooterRoutes } from '../utils/nav';
import { GuardsUtils } from './guards-utils';
import { RunPhaseType } from 'chronos-core-client';

@Injectable()
export class SetupSubPhaseNavigationGuard implements CanActivateChild {
  constructor(private guardsUtils: GuardsUtils) {}

  public canActivateChild(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.guardsUtils.navigateToSubPhase(next, RunPhaseType.SETUP, setupPhaseFooterRoutes);
  }
}
