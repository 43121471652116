<div class="knife-container" *ngIf="isKnifeEmpty">
  <div class="knife-parent">
    <div class="knife-child">
      <div class="knife-grand-child upper" *ngFor="let item of outputKnife; last as isLast" [class.knife-border]="isLast === true">
        <div class="knife-text">{{ item.knifeName }}</div>
        <div class="knife-grand-child-child">
          <div *ngFor="let info of item.patternInfo; last as isLast" [class.knife-border]="isLast === false" class="knife-text">
            <div>{{ info.trackWidth?.value }} x {{ info.sheetLength?.value }}</div>
            <div>{{ info.stationCode }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
