import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewEncapsulation,
  AfterViewInit,
  ChangeDetectorRef,
  HostListener
} from '@angular/core';
import moment from 'moment-mini';
import {
  WorkCenterService,
  MachineScheduleService,
  ProductionPeriodDetailData,
  WorkCenterTask,
  ShiftReportService,
  WorkCenterData,
  ProductionOrderSetupPhaseState
} from 'chronos-core-client';
import { WorkCenterCachedService } from 'chronos-core-client';
import { TelemetryService, TelemetryQueryDto, MeasurementService, TelemetryResultDto } from 'chronos-panda-client';
import { Subscription, Observable, combineLatest } from 'rxjs';
import * as Highcharts from 'highcharts/highstock';
import hc_patternFill from 'highcharts/modules/pattern-fill';
import hc_export from 'highcharts/modules/exporting';
import HighchartsMore from 'highcharts/highcharts-more';
// import exportData from 'highcharts/modules/export-data';
import hc_offline_export from 'highcharts/modules/offline-exporting';
import draggable from 'highcharts/modules/draggable-points';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MachineChartConfigurationService } from './machine-chart-configuration.service';
import { SelectItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { WorkCenterMachineData, ProductionOrderService } from 'chronos-core-client';
import { MatTableDataSource } from '@angular/material/table';
import { MachineData, MetadataProperties, SignalType } from './machine-data';
import { ChartType } from '../models/chart-type.model';
import { Location } from '@angular/common';
import { OrganizationsCachedService, MetaModelService, OrganizationsService } from 'chronos-live-client';
import { Router } from '@angular/router';
@Component({
  selector: 'lib-machine-chart',
  templateUrl: './machine-chart.component.html',
  styleUrls: ['./machine-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MachineChartComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  public runSHTSeries: string;
  public runPCSSeries: string;
  constructor(
    private translate: TranslateService,
    private productionOrderService: ProductionOrderService,
    private workCenterService: WorkCenterService,
    private workCenterCachedService: WorkCenterCachedService,
    private formBuilder: UntypedFormBuilder,
    private machineScheduleService: MachineScheduleService,
    private telemetryService: TelemetryService,
    private machineChartConfigurationService: MachineChartConfigurationService,
    private location: Location,
    private measurementService: MeasurementService,
    private organizationsCachedService: OrganizationsCachedService,
    private organizationService: OrganizationsService,
    private metamodelService: MetaModelService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private shiftReportService: ShiftReportService
  ) {
    this.translate.stream(['MACHINE_CHART.SHT_UNIT', 'MACHINE_CHART.PCS_UNIT']).subscribe((values) => {
      Object.keys(values).map((key) => {
        switch (key) {
          case 'MACHINE_CHART.SHT_UNIT':
            this.runSHTSeries = values[key];
            break;
          case 'MACHINE_CHART.PCS_UNIT':
            this.runPCSSeries = values[key];
            break;
        }
      });
    });
    HighchartsMore(Highcharts);
    hc_patternFill(Highcharts);
    hc_export(Highcharts);
    hc_offline_export(Highcharts);
    draggable(Highcharts);
    // exportData(Highcharts);
    this.formGroup = this.formBuilder.group({
      startDate: [null],
      endDate: [null],
      machineId: [null],
      period: [null]
    });

    this.chartCallback = (chart) => {
      this.chart = chart;
      const values = this.chart.yAxis[0].getExtremes();
      this.targetSpeedForLines = values.max;
      this.updateSeriesLines();
    };
    this.dataSource = new MatTableDataSource(this.machineData);
  }
  public isEnterKey = false;
  @Input() public workCenterId: number;
  @Input() public showMachineList = false;
  @Input() public isAutoUpdate = false;
  @Input() public isFullViewMode = false;
  @Input() public showDatetimeFilter = false;
  @Input() public start: string;
  @Input() public rest: string;
  @Output() public taskChange = new EventEmitter<WorkCenterTask>();
  @Input() public isMachineChartInSetupPhase = false;
  @Input() public setupStartTime: Date;
  @Input() public earlistDateLimit: Date;
  @Input() public latestDateLimit: Date;
  @Input() public setupEndTime: Date;
  @Input() public splitTime: Date;
  @Output()
  public setupStartTimeChanged: EventEmitter<Date> = new EventEmitter();
  @Output()
  public setupEndTimeChanged: EventEmitter<Date> = new EventEmitter();

  @Input() public taskList: WorkCenterTask[];
  @Input() public task: WorkCenterTask;

  @Output()
  public splitTimeChanged: EventEmitter<Date> = new EventEmitter();

  @Input() public isChartIsInSplitDowntime = false;

  @Input() public chartMode: ChartType;
  @Input() public orderSetupPhaseState?: ProductionOrderSetupPhaseState;

  public targetSpeed: number;
  public targetSpeed2: number;
  public externalWorkCenterID: string;
  public workCenterName = '';
  public highcharts = Highcharts;
  public chartDetails: Highcharts.Options;
  public periods: ProductionPeriodDetailData[];
  public selectedPeriodHours = '8Hrs';
  public periodStartTime: Date;
  public periodEndTime: Date;
  public periodStart: Date;
  public periodEnd: Date;
  public downtimeComment: string;
  public startTime: string;
  public timeout: any;
  // TODO WTF
  public chart;
  public startDate: string;
  public orderScheduleId: string;
  public formGroup: UntypedFormGroup;
  public machineList: WorkCenterData[] = [];
  private activeSubscriptions: Subscription[] = [];
  public updateFromInput: boolean;
  public chartCallback;
  public chartConstructor = 'chart';
  public isMobileView = false;
  public downtime: string;
  public timeRemaining: string;
  public timeFilters: SelectItem[];
  public isAddScrollBar = false;
  public maxDate: Date;
  public plotBandHeight: number;
  public machineType: string;
  public exludedSignal: string;

  public isDetailedView = false;
  public allMachinesData: WorkCenterMachineData[] = [];
  public machineData: MachineData[] = [];
  public selectedMachine: MachineData;
  public selectedMachineData: any;
  public dataSource: MatTableDataSource<MachineData>;
  public displayedColumns: string[] = [
    'activeProductionOrderCode',
    'salesOrderAndPosition',
    'externalFinishedGoodArticleId',
    'finishedGoodArticleConfigurationId',
    'customerName',
    'finishedGoodArticleName',
    'goodQuantity',
    'waste',
    'maculature',
    'numberOfStoppers'
  ];
  public displayedColumnsNames: string[] = [];
  public months: string[] = [];
  public days: string[] = [];
  public customPeriod: string;
  public eightHrs: string;
  public twelveHrs: string;
  public oneDay: string;
  public twoDays: string;
  public threeDays: string;
  public oneWeek: string;
  public loading: string;
  public contextMenu: string;
  public viewFullscreen: string;
  public printChart: string;
  public downloadPNG: string;
  public downloadJPEG: string;
  public downloadPDF: string;
  public downloadSVG: string;
  public targetSpeedForLines: number;
  public isCollapsed = false;
  public sign = 'up';
  public chartHeight = 500;
  public counterValue: string;
  public isForwardDisabled = true;
  public activeWorkcenters: WorkCenterData[] = [];
  public activeWorkcenterMachineData: WorkCenterMachineData[] = [];
  public netQuantity = 0;
  public totalWaste = 0;
  public totalMaculature = 0;
  public grossQuantity = 0;

  public isLiveviewEnabled = true;
  public showPeriod = false;
  public showEndDatetime = true;
  public isChartZoomed = false;
  public liveState = false;
  public isMarkerEnabled = false;
  public minX;
  public maxX;
  public isPeriodSelected = false;
  public workCenterChartYAxisSpace: number;
  public noWorkCenterSelectedFlag = false;
  public noWorkCenterAccessFlag = false;
  public firstWorkCenterFromList = false;
  public isDefaultWorkCenterNotAvailable = false;
  public isFromDashboardMachineList = false;

  @HostListener('window:keydown', ['$event'])
  public keyboardInput(event: any) {
    if (event.key === 'Enter') {
      this.isEnterKey = true;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.setupStartTime) {
      this.updateStartTime(this.setupStartTime);
    }
    if (changes.splitTime) {
      this.updateSplitTime(this.splitTime);
    }
    if (changes.setupEndTime) {
      this.updateEndTime(this.setupEndTime);
    }
    if (changes.task && this.task !== undefined) {
      this.updateChart(this.task);
    }
    if (changes.taskList && this.taskList !== undefined) {
      this.updateDowntime(this.taskList);
    }
  }

  public ngAfterViewInit() {
    this.setIsMobileView(window.screen.width);
    this.cdr.detectChanges();
  }

  public onResize(event) {
    this.setIsMobileView(event.target.innerWidth);
  }

  private setIsMobileView(width: number): void {
    this.isMobileView = width <= 700;
  }

  public updateSeriesLines() {
    if (this.chartMode === ChartType.editSetup || this.chartMode === ChartType.editDowntime) {
      this.chart.series[5].data[0].y = this.targetSpeedForLines;
      this.chart.series[6].data[0].y = this.targetSpeedForLines;
      this.chart.series[5].update();
      this.chart.series[6].update();
    } else if (this.chartMode === ChartType.splitDowntime) {
      this.chart.series[4].data[0].y = this.targetSpeedForLines;
      this.chart.series[4].update();
    }
  }

  public updateDowntime(taskItemDowntime: WorkCenterTask[]) {
    if (this.chart !== null && this.chart !== undefined) {
      taskItemDowntime.forEach((taskItem) => {
        this.chart.series[3].data.forEach((downElement) => {
          if (
            moment.utc(taskItem.downtime.startTime).format('YYYY-MM-DDTHH:mm:ss') ===
              moment.utc(downElement.x).format('YYYY-MM-DDTHH:mm:ss') &&
            downElement.y > 0
          ) {
            const regExp = /\(([^)]+)\)/;
            const matches = regExp.exec(taskItem.downtime.downtimeReasonLocalDescription);
            downElement.externalDowntimeLocalCode = matches[1];
          }
        });
      });
      this.chart.series[3].update();
    }
  }

  private updateStartTime(setupStartTime: Date): void {
    const x = moment.utc(setupStartTime).valueOf();
    if (this.chart != null && this.chart !== undefined) {
      this.chart.series[4].data[0].x = x;
      this.chart.series[4].data[0].y = this.targetSpeed - this.plotBandHeight;
      this.chart.series[4].data[3].x = x;
      this.chart.series[4].data[3].y = this.targetSpeed;
      this.chart.series[5].data[0].x = x;
      this.chart.series[5].data[1].x = x;
      this.chart.series[4].update();
      this.chart.series[5].update();
    }
  }

  private updateSplitTime(splitTime: Date): void {
    if (this.chart != null && this.chart !== undefined) {
      const x = moment.utc(splitTime).valueOf();
      this.chart.series[4].data[0].x = x;
      this.chart.series[4].data[1].x = x;
      this.chart.series[4].update();
    }
  }

  private updateEndTime(setupEndTime: Date): void {
    const x = moment.utc(setupEndTime).valueOf();
    if (this.chart != null && this.chart !== undefined) {
      this.chart.series[4].data[1].x = x;
      this.chart.series[4].data[1].y = this.targetSpeed - this.plotBandHeight;
      this.chart.series[4].data[2].x = x;
      this.chart.series[4].data[2].y = this.targetSpeed;
      this.chart.series[6].data[0].x = x;
      this.chart.series[6].data[1].x = x;
      this.chart.series[4].update();
      this.chart.series[6].update();
    }
  }

  public ngOnInit(): void {
    if (!this.workCenterId) {
      this.isDefaultWorkCenterNotAvailable = true;
    }

    this.maxDate = new Date();
    if (history.state.flag) {
      this.workCenterId = history.state.workCenterId;
      this.isFullViewMode = history.state.isFullViewMode;
      this.showMachineList = true;
      this.isAutoUpdate = true;
      this.externalWorkCenterID = history.state.externalWorkCenterId;
      this.isFromDashboardMachineList = history.state.fromDashboardMachineList;

      if (history.state.isDetailedView) {
        this.isDetailedView = history.state.isDetailedView;
        this.chartMode = history.state.chartMode;
      }
    }

    const id = this.router.url.substring(this.router.url.lastIndexOf('/') + 1, this.router.url.length);
    if (!isNaN(Number(id))) {
      this.externalWorkCenterID = id;
      this.setIsMobileView(window.screen.width);
      this.chartMode = ChartType.fullView;
      this.isFullViewMode = true;
      this.showMachineList = true;
      this.isDetailedView = true;
    }

    if (ChartType.fullView === this.chartMode) {
      this.machineList = [];
      this.activeWorkcenters = [];
      this.organizationService.getActiveOrganizationsForUser(0).subscribe((workCenters) => {
        if (workCenters && workCenters.length > 0) {
          const activeSubscription = this.workCenterCachedService.workCenterGetWorkCenterList().subscribe((workCenterList) => {
            if (workCenterList && workCenterList.length > 0) {
              workCenters.forEach((activeWorkcenter) => {
                workCenterList.forEach((workcenterelement) => {
                  if (activeWorkcenter.orname === workcenterelement.externalWorkCenterId) {
                    this.activeWorkcenters.push(workcenterelement);

                    if (this.isDefaultWorkCenterNotAvailable) {
                      if (!this.firstWorkCenterFromList && !this.isFromDashboardMachineList) {
                        this.workCenterId = workcenterelement.id;
                        this.firstWorkCenterFromList = true;
                      }
                    }
                  }

                  if (workcenterelement.externalWorkCenterId === this.workCenterId?.toString() && !this.isDefaultWorkCenterNotAvailable) {
                    this.workCenterId = workcenterelement.id;
                    this.externalWorkCenterID = workcenterelement.externalWorkCenterId;
                  }
                });
              });
              this.setMachineAdditionalInfo(this.workCenterId);
              this.loadWorkCenterDetails();
              if (this.showMachineList) {
                this.machineList = this.activeWorkcenters;
                this.formGroup.patchValue({
                  machineId: Number(this.workCenterId)
                });
              }
            }
          });

          this.activeSubscriptions.push(activeSubscription);
        } else {
          this.workCenterId = null;
          this.noWorkCenterAccessFlag = true;
        }
      });
    } else {
      this.loadWorkCenterDetails();
    }

    if (this.isAutoUpdate && this.isLiveviewEnabled) {
      this.setAutoUpdate();
    }

    this.configureGlobalChartSettings();
    this.getAllMachineDetails();
  }

  public setTimePeriods() {
    this.timeFilters = [
      { label: this.eightHrs, value: '8Hrs' },
      { label: this.twelveHrs, value: '12Hrs' },
      { label: this.oneDay, value: '24Hrs' },
      { label: this.twoDays, value: '2Days' },
      { label: this.threeDays, value: '3Days' },
      { label: this.oneWeek, value: '1Week' }
    ];
  }

  public toggleFilters() {
    if (!this.isEnterKey) {
      this.isCollapsed = !this.isCollapsed;
      if (this.isCollapsed) {
        this.chartHeight = this.chartHeight + 125;
        if (this.sign === 'up') {
          this.sign = 'down';
        }
      } else {
        this.chartHeight = this.chartHeight - 125;
        if (this.sign === 'down') {
          this.sign = 'up';
        }
      }
    }
    this.isEnterKey = false;
  }

  public setMachineAdditionalInfo(workCenterId: number) {
    let selectedStartDate;
    let selectedEndDate;
    if (this.periodStart !== undefined && this.periodEnd !== undefined) {
      selectedStartDate = moment.utc(this.periodStart).set('second', 0).set('millisecond', 0).toISOString();
      selectedEndDate = moment.utc(this.periodEnd).set('second', 0).set('millisecond', 0).toISOString();
    } else {
      selectedStartDate = moment.utc().subtract(8, 'hours').set('second', 0).set('millisecond', 0).toISOString();
      selectedEndDate = moment.utc().set('second', 0).set('millisecond', 0).toISOString();
    }
    const request = {
      startTime: selectedStartDate,
      endTime: selectedEndDate
    };

    const params: ProductionOrderService.GetProductionOrderDetailsParams = {
      workCenterId,
      request
    };
    this.machineData = [];
    const activeSubscription = this.productionOrderService.getProductionOrderDetails(params).subscribe((result) => {
      if (result) {
        result.forEach((resultelement) => {
          const selectedMachine: MachineData = {};
          selectedMachine.numberOfStoppers = {
            unitId: resultelement.numberOfStoppers?.value?.unitId,
            value: resultelement.numberOfStoppers?.value?.value
          };
          selectedMachine.activeProductionOrderCode = resultelement.externalProductionOrderId;
          selectedMachine.externalFinishedGoodArticleId = resultelement.externalFinishedGoodArticleId;
          selectedMachine.finishedGoodArticleConfigurationId = resultelement.finishedGoodArticleConfigurationId;
          selectedMachine.customerName = resultelement.customerName;
          selectedMachine.finishedGoodArticleName = resultelement.finishedGoodArticleName;
          selectedMachine.goodQuantity = {
            unitId: resultelement.goodQuantity.unitId,
            value: resultelement.goodQuantity.value
          };
          selectedMachine.waste = {
            unitId: resultelement.waste.unitId,
            value: resultelement.waste.value
          };
          selectedMachine.maculature = {
            unitId: resultelement.maculature.unitId,
            value: resultelement.maculature.value
          };
          selectedMachine.salesOrderAndPosition = resultelement.salesOrderAndPosition;
          this.machineData.push(selectedMachine);
        });
        this.dataSource.data = this.machineData;
      }

      this.netQuantity = 0;
      this.totalWaste = 0;
      this.totalMaculature = 0;

      this.dataSource.data.forEach((element) => {
        this.netQuantity = this.netQuantity + element.goodQuantity?.value;
        this.totalWaste = this.totalWaste + element.waste?.value;
        this.totalMaculature = this.totalMaculature + element.maculature?.value;
      });

      const totalGross = this.netQuantity + this.totalWaste + this.totalMaculature;
      this.grossQuantity = totalGross;
      this.activeSubscriptions.push(activeSubscription);
    });
  }

  public getAllMachineDetails() {
    this.organizationsCachedService.getActiveOrganizations(0).subscribe((workCenters) => {
      if (workCenters) {
        this.workCenterCachedService.getWorkCenterMachineDataList().subscribe((result) => {
          workCenters.forEach((activeWorkcenter) => {
            result.forEach((workcenterelement) => {
              if (activeWorkcenter.orname === workcenterelement.externalWorkCenterId) {
                this.activeWorkcenterMachineData.push(workcenterelement);
              }
            });
          });
        });
        this.allMachinesData = this.activeWorkcenterMachineData;
      }
    });
  }

  public configureGlobalChartSettings(): void {
    const filterSubscription = combineLatest([
      this.configureGlobalTableHeaders(),
      this.configureGlobalChartMonths(),
      this.configureGlobalChartDays(),
      this.configureGlobalChartFilterDurations(),
      this.configureGlobalChartOptions()
    ]).subscribe(([tableHeadersResult, chartMonthResult, chartDaysResult, chartFilterDurationsResult, chartOptionsResult]) => {
      this.displayedColumnsNames = Object.keys(tableHeadersResult).map((key) => tableHeadersResult[key]);
      this.months = Object.keys(chartMonthResult).map((key) => chartMonthResult[key]);
      this.days = Object.keys(chartDaysResult).map((key) => chartDaysResult[key]);

      Object.keys(chartFilterDurationsResult).map((key) => {
        switch (key) {
          case 'MACHINE_CHART.8_HRS':
            this.eightHrs = chartFilterDurationsResult[key];
            break;
          case 'MACHINE_CHART.12_HRS':
            this.twelveHrs = chartFilterDurationsResult[key];
            break;
          case 'MACHINE_CHART.1_DAY':
            this.oneDay = chartFilterDurationsResult[key];
            break;
          case 'MACHINE_CHART.2_DAYS':
            this.twoDays = chartFilterDurationsResult[key];
            break;
          case 'MACHINE_CHART.3_DAYS':
            this.threeDays = chartFilterDurationsResult[key];
            break;
          case 'MACHINE_CHART.1_WEEK':
            this.oneWeek = chartFilterDurationsResult[key];
            break;
          case 'MACHINE_CHART.CUSTOM':
            this.customPeriod = chartFilterDurationsResult[key];
            break;
          default:
            this.eightHrs = chartFilterDurationsResult[key];
            break;
        }
      });
      Object.keys(chartOptionsResult).map((key) => {
        switch (key) {
          case 'MACHINE_CHART.LOADING':
            this.loading = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.CONTEXT_MENU':
            this.contextMenu = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.VIEW_FULLSCREEN':
            this.viewFullscreen = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.PRINT_CHART':
            this.printChart = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.DOWNLOAD_PNG':
            this.downloadPNG = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.DOWNLOAD_JPEG':
            this.downloadJPEG = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.DOWNLOAD_PDF':
            this.downloadPDF = chartOptionsResult[key];
            break;
          case 'MACHINE_CHART.DOWNLOAD_SVG':
            this.downloadSVG = chartOptionsResult[key];
            break;
        }
      });
      this.setTimePeriods();
      this.setHighchartOptions();
    });
    this.activeSubscriptions.push(filterSubscription);
  }

  public setHighchartOptions(): void {
    Highcharts.setOptions({
      lang: {
        numericSymbols: null,
        shortMonths: this.months,
        weekdays: this.days,
        loading: this.loading,
        contextButtonTitle: this.contextMenu,
        viewFullscreen: this.viewFullscreen,
        printChart: this.printChart,
        downloadPNG: this.downloadPNG,
        downloadJPEG: this.downloadJPEG,
        downloadPDF: this.downloadPDF,
        downloadSVG: this.downloadSVG
      }
    });
  }

  public configureGlobalTableHeaders = (): Observable<any> =>
    this.translate.stream([
      'MACHINE_LIST.ACTIVE_JOB',
      'MACHINE_LIST.SALES_ORDER_AND_POSITION',
      'MACHINE_LIST.CUSTOMER_NAME',
      'MACHINE_LIST.ARTICLE_ID',
      'MACHINE_LIST.NET_QUANTITY',
      'MACHINE_LIST.WASTE',
      'MACHINE_LIST.MACULATURE',
      'MACHINE_LIST.NUMBEROFSTOPPERS'
    ]);

  public configureGlobalChartMonths = (): Observable<any> =>
    this.translate.stream([
      'MACHINE_CHART.JAN',
      'MACHINE_CHART.FEB',
      'MACHINE_CHART.MAR',
      'MACHINE_CHART.APR',
      'MACHINE_CHART.MAY',
      'MACHINE_CHART.JUN',
      'MACHINE_CHART.JUL',
      'MACHINE_CHART.AUG',
      'MACHINE_CHART.SEP',
      'MACHINE_CHART.OCT',
      'MACHINE_CHART.NOV',
      'MACHINE_CHART.DEC'
    ]);

  public showPeriodInfo(): void {
    this.showPeriod = true;
    this.showEndDatetime = false;
    const start = new Date(this.periodStart);
    if (this.formGroup.controls.period.value === null) {
      this.formGroup.patchValue({
        period: '8Hrs'
      });
    }
    if (this.showPeriod) {
      switch (this.formGroup.controls.period.value) {
        case '8Hrs':
          this.periodEnd = moment.utc(start).add(8, 'hours').toDate();
          break;
        case '12Hrs':
          this.periodEnd = moment.utc(start).add(12, 'hours').toDate();
          break;
        case '24Hrs':
          this.periodEnd = moment.utc(start).add(24, 'hours').toDate();
          break;
        case '2Days':
          this.periodEnd = moment.utc(start).add(2, 'days').toDate();
          break;
        case '3Days':
          this.periodEnd = moment.utc(start).add(3, 'days').toDate();
          break;
        case '1Week':
          this.periodEnd = moment.utc(start).add(7, 'days').toDate();
          break;
      }
    }
    this.getPeriods(this.workCenterId, start, this.periodEnd);
  }

  public showEndDate(): void {
    this.showPeriod = false;
    this.showEndDatetime = true;
  }

  public configureGlobalChartDays = (): Observable<any> =>
    this.translate.stream([
      'MACHINE_CHART.MONDAY',
      'MACHINE_CHART.TUESDAY',
      'MACHINE_CHART.WEDNESDAY',
      'MACHINE_CHART.THURSDAY',
      'MACHINE_CHART.FRIDAY',
      'MACHINE_CHART.SATURDAY',
      'MACHINE_CHART.SUNDAY'
    ]);

  public configureGlobalChartFilterDurations = (): Observable<any> =>
    this.translate.stream([
      'MACHINE_CHART.8_HRS',
      'MACHINE_CHART.12_HRS',
      'MACHINE_CHART.1_DAY',
      'MACHINE_CHART.2_DAYS',
      'MACHINE_CHART.3_DAYS',
      'MACHINE_CHART.1_WEEK',
      'MACHINE_CHART.CUSTOM'
    ]);

  public configureGlobalChartOptions = (): Observable<any> =>
    this.translate.stream([
      'MACHINE_CHART.LOADING',
      'MACHINE_CHART.CONTEXT_MENU',
      'MACHINE_CHART.VIEW_FULLSCREEN',
      'MACHINE_CHART.PRINT_CHART',
      'MACHINE_CHART.DOWNLOAD_PNG',
      'MACHINE_CHART.DOWNLOAD_JPEG',
      'MACHINE_CHART.DOWNLOAD_PDF',
      'MACHINE_CHART.DOWNLOAD_SVG'
    ]);

  public loadWorkCenterDetails() {
    this.workCenterCachedService.workCenterGetWorkCenter(this.workCenterId).subscribe((workCenterDetails) => {
      if (workCenterDetails) {
        this.workCenterName = workCenterDetails.name;
        this.externalWorkCenterID = workCenterDetails.externalWorkCenterId;

        this.metamodelService.getMetaModel(this.setProperty(MetadataProperties.workcenterHideSignal)).subscribe((hideSignalResult) => {
          if (hideSignalResult.length > 0) {
            this.exludedSignal = hideSignalResult[0].value;
          }
          this.metamodelService.getMetaModel(this.setProperty(MetadataProperties.operationTypeId)).subscribe((result) => {
            if (result.length > 0) {
              this.machineType = result[0].value;
            }
            this.metamodelService.getMetaModel(this.setProperty(MetadataProperties.workCenterChartYAxisSpace)).subscribe((res) => {
              if (res && res.length > 0) {
                this.workCenterChartYAxisSpace = Number(res[0].value);
              }

              this.workCenterService.getWorkCenterDetail(this.workCenterId).subscribe((workcenterDeatils) => {
                this.targetSpeed =
                  workcenterDeatils.declaredPerformance.value != null && workcenterDeatils.declaredPerformance.value > 0
                    ? workcenterDeatils.declaredPerformance.value
                    : 0;
                if (this.targetSpeed > 0) {
                  this.targetSpeed = this.calculateYAxisSpaceFactor(this.targetSpeed);
                }

                switch (this.chartMode) {
                  case ChartType.fullView:
                    this.periodStart = this.formGroup.value.startDate ?? moment.utc().subtract(8, 'hours').toDate();
                    this.periodEnd = this.formGroup.value.endDate ?? moment.utc().toDate();
                    this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
                    break;
                  case ChartType.donwtimeTask:
                    this.machineScheduleService.getActiveOrder(this.workCenterId).subscribe((activeProductionOrder) => {
                      if (activeProductionOrder) {
                        this.orderScheduleId = activeProductionOrder.externalProductionOrderId;
                        this.startTime = moment.utc(activeProductionOrder.productionStartTime).local().format('HH:mm');
                      } else {
                        this.orderScheduleId = null;
                        this.startTime = null;
                      }
                      this.shiftReportService.getShifts(this.workCenterId).subscribe((shifts) => {
                        this.periodStart = shifts.length > 0 ? new Date(shifts[0]?.startTime) : moment.utc().subtract(8, 'hours').toDate();
                        this.periodEnd = moment.utc().toDate();
                        this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
                      });
                    });
                    break;
                  case ChartType.finishPhase:
                    this.activeSubscriptions.push(
                      this.machineScheduleService.getActiveOrder(this.workCenterId).subscribe((activeProductionOrder) => {
                        if (activeProductionOrder) {
                          this.orderScheduleId = activeProductionOrder.externalProductionOrderId;
                          this.startTime = moment.utc(activeProductionOrder.productionStartTime).local().format('HH:mm');
                          this.productionOrderService
                            .getProjectedRunEnd(activeProductionOrder.productionOrderId)
                            .subscribe((orderWithLastDateTime) => {
                              if (orderWithLastDateTime) {
                                this.timeRemaining = moment
                                  .duration(activeProductionOrder.timeRemaining)
                                  .add(30, 'second')
                                  .minutes()
                                  .toLocaleString();
                                this.periodStart = new Date(activeProductionOrder.productionStartTime);
                                this.periodEnd = moment.utc(orderWithLastDateTime.lastProductionDateTime).toDate();
                                this.getPeriods(
                                  this.workCenterId,
                                  new Date(activeProductionOrder.productionStartTime),
                                  activeProductionOrder.productionEndTime
                                    ? new Date(activeProductionOrder.productionEndTime)
                                    : moment.utc(orderWithLastDateTime.lastProductionDateTime).toDate()
                                );
                              }
                            });
                        }
                      })
                    );
                    break;
                  case ChartType.editSetup:
                    const diffInTime = this.setupEndTime.getTime() - this.setupStartTime.getTime();
                    const diffInDays = diffInTime / (1000 * 3600 * 24);

                    this.periodStart = moment.utc(this.setupStartTime).subtract(1, 'hours').toDate();

                    this.periodEnd =
                      diffInDays > 2
                        ? moment.utc(this.setupEndTime).add(8, 'hours').toDate()
                        : moment.utc(this.setupEndTime).add(1, 'hours').toDate();

                    this.setupEndTime = this.setupEndTime == null ? this.periodEnd : this.setupEndTime;

                    this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
                    break;
                  case ChartType.editDowntime:
                    this.setEditDowntimePeriodRange();
                    this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
                    break;
                  case ChartType.splitDowntime:
                    this.periodStart = moment.utc(this.setupStartTime).subtract(1, 'hours').toDate();
                    this.periodEnd = moment.utc(this.setupEndTime).add(1, 'hours').toDate();
                    this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
                    break;
                }
              });
            });
          });
        });
      }
    });
  }

  public calculateYAxisSpaceFactor(value: number): number {
    if (isNaN(this.workCenterChartYAxisSpace)) {
      return 1.2 * value; // 20% default
    } else {
      return (1 + this.workCenterChartYAxisSpace / 100) * value;
    }
  }

  private setEditDowntimePeriodRange() {
    const downtimeDuration = this.setupEndTime.getTime() - this.setupStartTime.getTime();
    const minimumDateVal =
      moment.utc(this.setupEndTime).add(3, 'hours').toDate() > this.latestDateLimit
        ? this.latestDateLimit
        : moment.utc(this.setupEndTime).add(3, 'hours').toDate();
    const tenPerc = 0.1 * (this.setupStartTime.getTime() - (minimumDateVal.getTime() + downtimeDuration));

    this.periodStart = moment.utc(this.setupStartTime).add(tenPerc).toDate();
    this.periodEnd = moment.utc(this.setupEndTime).add(3, 'hours').toDate();
  }

  public setAutoUpdate() {
    this.timeout = setInterval(() => {
      if (this.isLiveviewEnabled) {
        this.isForwardDisabled = true;
        this.setLiveViewValues();
      } else {
        clearInterval(this.timeout);
      }
    }, 30 * 1000);
  }

  public openSpeedMonitor() {
    this.router.navigateByUrl(`/speedmonitor/${this.externalWorkCenterID}`, {
      state: {
        workCenterId: this.workCenterId,
        flag: true,
        externalWorkCenterId: this.externalWorkCenterID
      }
    });
  }

  public generateSignalQuery(speedQueryFactor, aggfun, wnsReport): TelemetryQueryDto {
    this.isMarkerEnabled = this.isChartZoomed === true ? true : false;
    if (speedQueryFactor < 10) {
      return {
        wellKnownSignal: wnsReport.wellKnownSignal,
        resource: this.externalWorkCenterID,
        from: moment.utc(this.periodStart).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'),
        to:
          this.periodEnd === null
            ? moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS')
            : moment.utc(this.periodEnd).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'),
        resultPoints: ''
      };
    } else {
      return {
        wellKnownSignal: wnsReport.wellKnownSignal,
        resource: this.externalWorkCenterID,
        from: moment.utc(this.periodStart).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'),
        to:
          this.periodEnd === null
            ? moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS')
            : moment.utc(this.periodEnd).format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'),
        aggFunction: aggfun,
        groupBy: `time(${speedQueryFactor}s) fill(none)`,
        resultPoints: ''
      };
    }
  }

  public getPeriods(workCenterId: number, start: Date, end: Date) {
    const periodStart: string = moment.utc(start).toISOString();
    const periodEnd: string = moment.utc(end).toISOString();
    if (!this.isChartZoomed) {
      this.formGroup.controls.startDate.setValue(start);
      this.formGroup.controls.endDate.setValue(end);
    }
    const params: WorkCenterService.GetPeriodsParams = {
      workCenterId,
      periodStart,
      periodEnd
    };
    this.periodStart = moment.utc(start).toDate();
    this.periodEnd = moment.utc(end).toDate();
    const subscription = this.workCenterService.getPeriods(params).subscribe((productionPeriods) => {
      let speedQueryFactor;
      if (productionPeriods) {
        this.periods = productionPeriods;
        if (start !== undefined && end !== undefined) {
          const timeInSeconds = Math.round(Math.abs(end.getTime() - start.getTime())) / 1000;
          const scaleFactor = (end.getTime() - start.getTime()) / (60 * 60 * 1000) <= 12 ? 2000 : 1000;
          speedQueryFactor = Math.round(timeInSeconds / scaleFactor);
        }
        const allSignalDatas: TelemetryResultDto[] = [];
        let index = 0;
        this.measurementService
          .apiMeasurementListOfWnsByResourcePost({
            body: this.externalWorkCenterID
          })
          .subscribe((signalData) => {
            if (signalData && signalData.length > 0) {
              signalData.forEach((wnsReport) => {
                index++;
                const aggfun =
                  wnsReport.wellKnownSignal === SignalType.machine_run || wnsReport.wellKnownSignal === SignalType.wash ? 'MAX' : 'MEAN';
                const signalQuery: TelemetryQueryDto = this.generateSignalQuery(speedQueryFactor, aggfun, wnsReport);

                this.telemetryService.apiTelemetryPost({ body: signalQuery }).subscribe((signalsData) => {
                  allSignalDatas.push(signalsData);
                  if (allSignalDatas.length === index) {
                    const maxResult: TelemetryResultDto[] = allSignalDatas.filter((speedSignal) => {
                      if (speedSignal.telemetryQuery.wellKnownSignal === 'speed') {
                        return speedSignal;
                      }
                    });
                    const speed2Result: TelemetryResultDto[] = allSignalDatas.filter((speedSignal) => {
                      if (speedSignal.telemetryQuery.wellKnownSignal === 'speed2') {
                        return speedSignal;
                      }
                    });

                    let maxSpeed;
                    let maxSpeed2;
                    if (maxResult[0]) {
                      maxSpeed = Math.max(...maxResult[0].telemetryData.map((o) => o.value));
                    }
                    if (speed2Result[0]) {
                      maxSpeed2 = Math.max(...speed2Result[0].telemetryData.map((o) => o.value));
                    }

                    // Set machine max speed for gluing machine that is gl.
                    if (this.machineType && this.machineType.toLowerCase() === 'gl' && maxSpeed !== 0) {
                      const input = maxSpeed * 1.05;
                      const magnitude = Math.pow(10, Math.ceil(Math.log10(input)) - 1);
                      this.targetSpeed = (Math.ceil(input / magnitude) + 1) * magnitude;
                      // this.targetSpeed = this.targetspeed + (15 / 100) * this.targetSpeed;
                      if (this.targetSpeed > 0) {
                        this.targetSpeed = this.calculateYAxisSpaceFactor(this.targetSpeed);
                      }
                    } else if (maxSpeed !== 0 && ChartType.editSetup === this.chartMode) {
                      const input = maxSpeed * 1.05;
                      const magnitude = Math.pow(10, Math.ceil(Math.log10(input)) - 1);
                      this.targetSpeed = (Math.ceil(input / magnitude) + 1) * magnitude;
                    }
                    this.plotBandHeight = (10 / 100) * this.targetSpeed;

                    if (maxSpeed2 !== undefined) {
                      this.targetSpeed2 = (15 / 100) * maxSpeed2 + maxSpeed2;
                    }
                    this.workCenterCachedService.getWorkCenterMachineDataList().subscribe((result) => {
                      if (result) {
                        const workcenterCounterDetail = result.filter((element) => element.id === this.workCenterId);
                        this.prepareChartObject(workcenterCounterDetail[0]);
                        this.setChartConfiguration(this.periods, allSignalDatas, workcenterCounterDetail[0]);
                      }
                    });
                  }
                });
              });
            } else {
              this.workCenterCachedService.getWorkCenterMachineDataList().subscribe((result) => {
                if (result) {
                  const workcenterCounterDetail = result.filter((element) => element.id === this.workCenterId);
                  this.prepareChartObject(workcenterCounterDetail[0]);
                  this.setChartConfiguration(this.periods, allSignalDatas, workcenterCounterDetail[0]);
                }
              });
            }
          });
      }
    });

    this.activeSubscriptions.push(subscription);
  }

  public backToDashboard() {
    this.location.back();
  }

  public onBackClick() {
    this.checkChartZoomMode();
    this.periodStartTime = this.periodStart;
    this.periodEndTime = this.periodEnd;
    if ((this.periodEndTime.getTime() - this.periodStartTime.getTime()) / (60 * 1000) > 8) {
      this.isLiveviewEnabled = false;
    }
    switch (this.selectedPeriodHours) {
      case '8Hrs':
        this.periodEndTime.setHours(this.periodEndTime.getHours() - 8);
        this.periodStartTime.setHours(this.periodStartTime.getHours() - 8);
        break;
      case '12Hrs':
        this.periodEndTime.setHours(this.periodEndTime.getHours() - 12);
        this.periodStartTime.setHours(this.periodStartTime.getHours() - 12);
        break;
      case '24Hrs':
        this.periodEndTime.setHours(this.periodEndTime.getHours() - 24);
        this.periodStartTime.setHours(this.periodStartTime.getHours() - 24);
        break;
      case '2Days':
        this.periodEndTime.setDate(this.periodEndTime.getDate() - 2);
        this.periodStartTime.setDate(this.periodStartTime.getDate() - 2);
        break;
      case '3Days':
        this.periodEndTime.setDate(this.periodEndTime.getDate() - 3);
        this.periodStartTime.setDate(this.periodStartTime.getDate() - 3);
        break;
      case '1Week':
        this.periodEndTime.setDate(this.periodEndTime.getDate() - 7);
        this.periodStartTime.setDate(this.periodStartTime.getDate() - 7);
        break;
    }
    this.periodStart = new Date(this.periodStartTime);
    this.periodEnd = new Date(this.periodEndTime);
    this.formGroup.controls.startDate.setValue(this.periodStart);
    this.formGroup.controls.endDate.setValue(this.periodEnd);
    this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
    this.isPeriodEndEqualsNow();
    this.setMachineAdditionalInfo(this.workCenterId);
  }

  public isPeriodEndEqualsNow() {
    const currentTime = new Date();
    this.periodEnd.setSeconds(this.periodEnd.getMinutes() + 2);
    if (this.periodEnd >= currentTime) {
      this.isForwardDisabled = true;
    } else {
      this.isForwardDisabled = false;
    }
  }

  public isNextPeriodEndAvailable() {
    const currentTime = new Date();
    const futureTime: Date = this.periodEnd;
    switch (this.selectedPeriodHours) {
      case '8Hrs':
        futureTime.setHours(this.periodEnd.getHours() + 8);
        break;
      case '12Hrs':
        futureTime.setHours(this.periodEnd.getHours() + 12);
        break;
      case '24Hrs':
        futureTime.setHours(this.periodEnd.getHours() + 24);
        break;
      case '2Days':
        futureTime.setDate(this.periodEnd.getDate() + 2);
        break;
      case '3Days':
        futureTime.setDate(this.periodEnd.getDate() + 3);
        break;
      case '1Week':
        futureTime.setDate(this.periodEnd.getDate() + 7);
        break;
    }
    if (futureTime >= currentTime) {
      this.isForwardDisabled = true;
    } else {
      this.isForwardDisabled = false;
    }
  }
  private setProperty(metaPropetry: string) {
    const signalAttribute: string[] = [metaPropetry];
    return {
      orid: Number(this.externalWorkCenterID),
      attributeNames: signalAttribute
    };
  }
  public onForwardClick() {
    this.checkChartZoomMode();
    this.isPeriodEndEqualsNow();
    if (!this.isForwardDisabled) {
      this.periodStartTime = this.periodStart;
      this.periodEndTime = this.periodEnd;
      this.periodStart = this.periodEnd;
      switch (this.selectedPeriodHours) {
        case '8Hrs':
          this.periodStartTime.setHours(this.periodStartTime.getHours() + 8);
          this.periodEndTime.setHours(this.periodEndTime.getHours() + 8);
          break;
        case '12Hrs':
          this.periodStartTime.setHours(this.periodStartTime.getHours() + 12);
          this.periodEndTime.setHours(this.periodEndTime.getHours() + 12);
          break;
        case '24Hrs':
          this.periodStartTime.setHours(this.periodStartTime.getHours() + 24);
          this.periodEndTime.setHours(this.periodEndTime.getHours() + 24);
          break;
        case '2Days':
          this.periodStartTime.setDate(this.periodStartTime.getDate() + 2);
          this.periodEndTime.setDate(this.periodEndTime.getDate() + 2);
          break;
        case '3Days':
          this.periodStartTime.setDate(this.periodStartTime.getDate() + 3);
          this.periodEndTime.setDate(this.periodEndTime.getDate() + 3);
          break;
        case '1Week':
          this.periodStartTime.setDate(this.periodStartTime.getDate() + 7);
          this.periodEndTime.setDate(this.periodEndTime.getDate() + 7);
          break;
      }
      this.periodStart = new Date(this.periodStartTime);
      this.periodEnd = new Date(this.periodEndTime);
      this.formGroup.controls.startDate.setValue(this.periodStart);
      this.formGroup.controls.endDate.setValue(this.periodEnd);
      this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
      this.isPeriodEndEqualsNow();
      this.isNextPeriodEndAvailable();
      this.setMachineAdditionalInfo(this.workCenterId);
    }
  }

  public prepareChartObject(workcenterCounterDetail: WorkCenterMachineData) {
    this.targetSpeed = this.targetSpeed === 0 ? 20000 : this.targetSpeed;
    switch (this.chartMode) {
      case ChartType.fullView:
        this.chartDetails = this.machineChartConfigurationService.getFullViewConfiguration(
          this.workCenterName,
          this.externalWorkCenterID,
          this.targetSpeed,
          this.targetSpeed2,
          workcenterCounterDetail.counterUnitId,
          this.chartHeight
        );
        break;
      case ChartType.donwtimeTask:
      case ChartType.finishPhase:
        this.chartDetails = this.machineChartConfigurationService.getFinishPhaseConfiguration(
          this.orderScheduleId,
          this.startTime,
          this.timeRemaining,
          workcenterCounterDetail.counterUnitId,
          this.chartMode
        ) as Highcharts.Options;
        break;
      case ChartType.splitDowntime:
        this.chartDetails = this.machineChartConfigurationService.getSplitOrEditDowntimeConfiguration(
          this.latestDateLimit,
          this.targetSpeed,
          workcenterCounterDetail.counterUnitId,
          true
        ) as Highcharts.Options;
        break;
      case ChartType.editDowntime:
        if (this.latestDateLimit > this.periodEnd) {
          this.latestDateLimit = this.periodEnd;
        }

        this.chartDetails = this.machineChartConfigurationService.getSplitOrEditDowntimeConfiguration(
          this.latestDateLimit,
          this.targetSpeed,
          workcenterCounterDetail.counterUnitId,
          false
        ) as Highcharts.Options;
        break;
      case ChartType.editSetup:
        if (this.earlistDateLimit < this.periodStart) {
          this.earlistDateLimit = this.periodStart;
        }

        if (this.latestDateLimit > this.periodEnd) {
          this.latestDateLimit = this.periodEnd;
        }

        if (this.orderSetupPhaseState.latestSetupEnd && this.latestDateLimit > new Date(this.orderSetupPhaseState.latestSetupEnd)) {
          this.latestDateLimit = new Date(this.orderSetupPhaseState.latestSetupEnd);
        }

        if (this.evaluateScrollBarRequirement()) {
          this.chartDetails = this.machineChartConfigurationService.getEditSetupConfiguration(
            this.latestDateLimit,
            this.targetSpeed,
            workcenterCounterDetail.counterUnitId
          ) as Highcharts.Options;
        } else {
          this.chartDetails = this.machineChartConfigurationService.getEditSetupConfigurationWithoutScroll(
            this.latestDateLimit,
            this.targetSpeed,
            workcenterCounterDetail.counterUnitId
          ) as Highcharts.Options;
        }
    }
  }

  private evaluateScrollBarRequirement() {
    const diffInTime = this.setupEndTime.getTime() - this.setupStartTime.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24);
    this.maxDate =
      diffInDays > 2 ? moment.utc(this.periodStart).add(8, 'hour').toDate() : moment.utc(this.periodStart).add(1, 'hours').toDate();
    return diffInDays > 1 ? true : false;
  }

  public setChartConfiguration(
    result: ProductionPeriodDetailData[],
    allSignalData: TelemetryResultDto[],
    workcenterCounterDetail: WorkCenterMachineData
  ): void {
    if (this.chartDetails != null && this.chartDetails !== undefined) {
      if (this.chartMode === ChartType.splitDowntime) {
        this.periods.forEach((periods) => {
          if (periods.isShiftApproved === true) {
            this.setupStartTime = new Date(periods.shiftEndTime);
          }
        });
      }

      const chartConfiguration = this.machineChartConfigurationService.getSeriesConfiguration(
        result,
        allSignalData,
        this.targetSpeed,
        this.setupStartTime,
        this.setupEndTime,
        this.chartMode,
        this.plotBandHeight,
        workcenterCounterDetail.counterUnitId,
        this.isMarkerEnabled,
        this.task,
        this.exludedSignal
      );
      chartConfiguration.forEach((chartConfigurationData) => {
        (this.chartDetails as any).series.push(chartConfigurationData);
      });
      if (this.chart !== null && this.chart !== undefined) {
        this.chart.hideLoading();
      }
      if (this.chartMode === ChartType.editSetup) {
        if (this.earlistDateLimit !== null && this.earlistDateLimit !== undefined) {
          (this.chartDetails as any).plotOptions = {
            series: {
              zoneAxis: 'x',
              zones: [
                {
                  value: moment.utc(this.earlistDateLimit).valueOf(),
                  className: 'zone-0'
                }
              ],
              point: {
                events: {
                  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
                  drop: (function(self) {
                    return function() {
                      if (this.series.name === 'setupStartSeries') {
                        self.setupStartTimeChanged.emit(new Date(this.x));
                      } else if (this.series.name === 'setupEndSeries') {
                        self.setupEndTimeChanged.emit(new Date(this.x));
                      }
                    };
                  })(this)
                }
              }
            }
          };
        } else {
          (this.chartDetails as any).plotOptions = {
            series: {
              point: {
                events: {
                  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
                  drop: (function(self) {
                    return function() {
                      if (this.series.name === 'setupStartSeries') {
                        self.setupStartTimeChanged.emit(new Date(this.x));
                      } else if (this.series.name === 'setupEndSeries') {
                        self.setupEndTimeChanged.emit(new Date(this.x));
                      }
                    };
                  })(this)
                }
              }
            }
          };
        }
      }
      if (this.chartMode === ChartType.splitDowntime) {
        (this.chartDetails as any).plotOptions = {
          series: {
            point: {
              events: {
                // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
                drop: (function(self) {
                  return function() {
                    self.splitTimeChanged.emit(new Date(this.x));
                  };
                })(this)
              }
            }
          }
        };
      }
      if (this.chartMode === ChartType.editDowntime) {
        (this.chartDetails as any).plotOptions = {
          series: {
            point: {
              events: {
                // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
                drop: (function(self) {
                  return function() {
                    if (this.series.name === 'enditDowntimeStartSeries') {
                      self.setupStartTimeChanged.emit(new Date(this.x));
                    } else if (this.series.name === 'enditDowntimeEndSeries') {
                      self.setupEndTimeChanged.emit(new Date(this.x));
                    }
                  };
                })(this)
              }
            }
          }
        };
      }
      if (this.chartMode === ChartType.fullView) {
        (this.chartDetails.chart as any).events = {
          selection: (e) => {
            if (e.xAxis) {
              this.liveState = this.isLiveviewEnabled;
              this.isLiveviewEnabled = false;
              this.getSelectedSection(e);
            } else {
              this.isChartZoomed = false;
              this.isLiveviewEnabled = this.liveState;
              if (this.isLiveviewEnabled) {
                this.periodStart = moment.utc().subtract(8, 'hours').toDate();
                this.periodEnd = moment.utc().toDate();
              }
              if (this.formGroup.controls.startDate.value !== undefined && this.formGroup.controls.endDate.value !== undefined) {
                this.periodStart = this.formGroup.controls.startDate.value;
                this.periodEnd = this.formGroup.controls.endDate.value;
                this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
              }

              this.chart.series.forEach((series) => {
                if (series.name === this.runSHTSeries || series.name === this.runPCSSeries) {
                  series.update(
                    {
                      marker: {
                        radius: 4
                      }
                    },
                    true
                  );
                }
              });
            }
          }
        };
      }
      if (this.chartMode !== ChartType.fullView) {
        (this.chartDetails.chart as any).events = {
          selection: (e) => {
            if (e.xAxis) {
              this.chart.series.forEach((allseries) => {
                if (allseries.name !== 'plotBandSeries') {
                  allseries.update(
                    {
                      marker: {
                        enabled: true
                      }
                    },
                    true
                  );
                }
              });
            } else {
              this.chart.series.forEach((allseries) => {
                allseries.update(
                  {
                    marker: {
                      enabled: false
                    }
                  },
                  true
                );
              });
            }
          }
        };
      }
    }
  }

  public getSelectedSection(e) {
    this.isChartZoomed = true;
    if (e.xAxis !== undefined) {
      this.minX = moment.utc(e.xAxis[0].min).toDate();
      this.maxX = moment.utc(e.xAxis[0].max).toDate();
      if (this.minX !== undefined && this.maxX !== undefined) {
        this.getPeriods(this.workCenterId, this.minX, this.maxX);
      }
    }
    this.chart.series.forEach((series) => {
      if (series.name === this.runSHTSeries || series.name === this.runPCSSeries) {
        series.update(
          {
            marker: {
              radius: 0
            }
          },
          true
        );
      }
    });
  }

  public updateChart(downtimeResult: WorkCenterTask) {
    this.shiftReportService.getShifts(this.workCenterId).subscribe((shifts) => {
      shifts.forEach((downtimeShift) => {
        if (downtimeResult.downtime.startTime >= downtimeShift.startTime && downtimeResult.downtime.endTime <= downtimeShift.endTime) {
          this.getPeriods(this.workCenterId, new Date(downtimeShift.startTime), new Date(downtimeShift.endTime));
        }
      });
    });
  }

  public checkChartZoomMode() {
    if (this.chart !== null && this.chart !== undefined && this.isChartZoomed) {
      this.chart.zoomOut();
      this.isChartZoomed = false;
    }
  }

  public onReset() {
    this.checkChartZoomMode();
    this.formGroup.reset();
    this.formGroup.patchValue({
      machineId: Number(this.workCenterId),
      period: '8Hrs'
    });
    this.isLiveviewEnabled = true;
    this.setAutoUpdate();
    this.periodStart = moment.utc().subtract(8, 'hours').toDate();
    this.periodEnd = moment.utc().toDate();
    this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
    this.isForwardDisabled = true;
    this.setMachineAdditionalInfo(this.workCenterId);
  }

  public ngOnDestroy() {
    clearTimeout(this.timeout);
    this.activeSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public onMachineSelect(event: any) {
    this.checkChartZoomMode();
    this.workCenterId = event.value;
    localStorage.setItem('selectedWorkcenterId', JSON.stringify(this.workCenterId));
    this.chart.legend.allItems.forEach((exludeLegend) => {
      exludeLegend.remove();
    });
    this.chart.showLoading();
    this.loadWorkCenterDetails();
    this.setMachineAdditionalInfo(this.workCenterId);

    if (this.isDetailedView) {
      this.workCenterCachedService.workCenterGetWorkCenter(this.workCenterId).subscribe((workCenterDetails) => {
        if (workCenterDetails) {
          this.externalWorkCenterID = workCenterDetails.externalWorkCenterId;
          this.changeMachineChartLoadUrl();
        }
      });
    }
  }

  public changeMachineChartLoadUrl() {
    this.chartMode = ChartType.fullView;
    this.router.navigateByUrl(`/machineChart/${this.externalWorkCenterID}`, {
      state: {
        workCenterId: this.workCenterId,
        flag: true,
        isFullViewMode: true,
        isDetailedView: true,
        chartMode: this.chartMode,
        externalWorkCenterId: this.externalWorkCenterID
      }
    });
  }

  public onPointSelect(event) {
    if (this.chartMode === ChartType.donwtimeTask) {
      this.downtime = moment.utc(event.point.options.x).format('YYYY-MM-DDTHH:mm:ss');
      const task = this.taskList.filter(
        (taskelement) =>
          moment.utc(taskelement.downtime.startTime).format('YYYY-MM-DDTHH:mm:ss') === this.downtime ||
          moment.utc(taskelement.downtime.endTime).format('YYYY-MM-DDTHH:mm:ss') === this.downtime
      );
      this.task = task[0];
      this.taskChange.emit(this.task);
    }
  }

  public onStartDateChanged(event) {
    if (event != null) {
      const newDate = new Date(event);
      const newDateTime = newDate.getTime();
      if (!isFinite(newDateTime)) {
        return;
      }

      if (newDateTime === this.periodStart.getTime()) {
        return;
      }
      this.checkChartZoomMode();
      this.isLiveviewEnabled = false;
      this.periodStart = newDate;
      const start = new Date(this.periodStart);
      if (this.formGroup.controls.period.value === null) {
        this.formGroup.patchValue({
          period: '8Hrs'
        });
      }
      if (this.showPeriod) {
        switch (this.formGroup.controls.period.value) {
          case '8Hrs':
            this.periodEnd = moment.utc(start).add(8, 'hours').toDate();
            break;
          case '12Hrs':
            this.periodEnd = moment.utc(start).add(12, 'hours').toDate();
            break;
          case '24Hrs':
            this.periodEnd = moment.utc(start).add(24, 'hours').toDate();
            break;
          case '2Days':
            this.periodEnd = moment.utc(start).add(2, 'days').toDate();
            break;
          case '3Days':
            this.periodEnd = moment.utc(start).add(3, 'days').toDate();
            break;
          case '1Week':
            this.periodEnd = moment.utc(start).add(7, 'days').toDate();
            break;
        }
      }
      this.getPeriods(this.workCenterId, start, this.periodEnd);
      this.isPeriodEndEqualsNow();
      this.setMachineAdditionalInfo(this.workCenterId);
    }
  }

  public onEndDateChanged(event): void {
    if (event != null) {
      const newDate = new Date(event);
      const newDateTime = newDate.getTime();
      if (!isFinite(newDateTime)) {
        return;
      }

      if (newDateTime === this.periodEnd.getTime()) {
        return;
      }
      this.checkChartZoomMode();
      this.isLiveviewEnabled = false;
      this.isPeriodSelected = false;
      this.periodEnd = newDate;
      const end = new Date(this.periodEnd);
      this.getPeriods(this.workCenterId, this.periodStart, end);
      this.isPeriodEndEqualsNow();
      this.setMachineAdditionalInfo(this.workCenterId);
    }
  }

  public onLiveChange(): void {
    this.checkChartZoomMode();
    this.formGroup.patchValue({
      machineId: Number(this.workCenterId),
      period: '8Hrs'
    });
    this.setLiveViewValues();
    this.isPeriodEndEqualsNow();
    this.setAutoUpdate();
    this.setMachineAdditionalInfo(this.workCenterId);
  }

  public setLiveViewValues() {
    if (!this.isPeriodSelected) {
      this.periodStart = moment.utc().subtract(8, 'hours').toDate();
      this.periodEnd = moment.utc().toDate();
    }
    this.formGroup.controls.startDate.setValue(this.periodStart);
    this.formGroup.controls.endDate.setValue(this.periodEnd);
    if (!this.isChartZoomed) {
      this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
    } else {
      this.getPeriods(this.workCenterId, this.minX, this.maxX);
    }
  }

  public onPeriodSelect(event) {
    this.isPeriodSelected = true;
    this.checkChartZoomMode();
    this.periodEnd = new Date(this.periodEnd) ?? moment.utc().toDate();
    switch (event.value) {
      case '8Hrs':
        this.periodEnd = moment.utc(this.periodStart).add(8, 'hours').toDate();
        this.selectedPeriodHours = '8Hrs';
        break;
      case '12Hrs':
        this.periodEnd = moment.utc(this.periodStart).add(12, 'hours').toDate();
        this.selectedPeriodHours = '12Hrs';
        break;
      case '24Hrs':
        this.periodEnd = moment.utc(this.periodStart).add(24, 'hours').toDate();
        this.selectedPeriodHours = '24Hrs';
        break;
      case '2Days':
        this.periodEnd = moment.utc(this.periodStart).add(2, 'days').toDate();
        this.selectedPeriodHours = '2Days';
        break;
      case '3Days':
        this.periodEnd = moment.utc(this.periodStart).add(3, 'days').toDate();
        this.selectedPeriodHours = '3Days';
        break;
      case '1Week':
        this.periodEnd = moment.utc(this.periodStart).add(7, 'days').toDate();
        this.selectedPeriodHours = '1Week';
        break;
    }
    if (event.value === 'CustomPeriod') {
      if (this.periodStart) {
        this.formGroup.controls.startDate.setValue(this.periodStart);
      }
      if (this.periodEnd) {
        this.formGroup.controls.endDate.setValue(this.periodEnd);
      }
    } else {
      this.formGroup.controls.startDate.setValue(this.periodStart);
      this.formGroup.controls.endDate.setValue(this.periodEnd);
    }
    if (this.isLiveviewEnabled) {
      this.setAutoUpdate();
    } else {
      this.getPeriods(this.workCenterId, this.periodStart, this.periodEnd);
    }
    this.setMachineAdditionalInfo(this.workCenterId);
    this.isForwardDisabled = true;
  }

  public getPeriodOnDateRange(hours: number): string {
    let period = '8Hrs';
    switch (true) {
      case hours > 8 && hours <= 12:
        period = '12Hrs';
        break;
      case hours > 12 && hours <= 24:
        period = '24Hrs';
        break;
      case hours > 24 && hours <= 48:
        period = '2Days';
        break;
      case hours > 48 && hours <= 72:
        period = '3Days';
        break;
      case hours > 72:
        period = '1Week';
        break;
    }
    return period;
  }
}
