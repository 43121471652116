import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorkCenterSelectionComponent } from '@app/modules/work-center-selection/containers/work-center-selection/work-center-selection.component';
import { nav } from '@app/shared/utils';
import { AuthorizeGuard } from 'chronos-shared';

const routes: Routes = [{ path: nav.workCenterSelection, component: WorkCenterSelectionComponent, canActivate: [AuthorizeGuard] }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
