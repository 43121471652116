import { Component, Input, OnInit } from '@angular/core';
import { PatternTrackInfo, PatternTrackType } from 'projects/chronos-core-client/src/public-api';

@Component({
  selector: 'app-pattern-info-table',
  templateUrl: './pattern-info-table.component.html',
  styleUrls: ['./pattern-info-table.component.scss']
})
export class PatternInfoTableComponent implements OnInit {
  constructor() {}

  public outputKnife: OutputKnife[] = [];
  public isKnifeEmpty = false;

  @Input()
  public set tableData(tableData: PatternTrackInfo[]) {
    if (tableData.length) {
      this.isKnifeEmpty = true;

      const tracks = tableData.filter((x) => x.type === PatternTrackType.TRACK);
      const knifeNames = tracks.filter((x) => x.crossKnifePositionId).map((x) => x.crossKnifePositionId);

      if (knifeNames) {
        const commonKnifes = knifeNames.filter((value, index) => knifeNames.indexOf(value) === index);

        if (commonKnifes) {
          commonKnifes.forEach((name) => {
            const knife = tracks.filter((p) => p.crossKnifePositionId === name);
            if (knife) {
              const oKnife: OutputKnife = {
                knifeName: name,
                patternInfo: knife
              };
              this.outputKnife.push(oKnife);
            }
          });
        }
      }
    }
  }

  public ngOnInit(): void {}
}

type OutputKnife = {
  knifeName: string;
  patternInfo: PatternTrackInfo[];
};
