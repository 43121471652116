import { RunPhaseType, RunSubPhaseType } from 'chronos-core-client';

export const nav = {
  root: '',
  ordersList: 'orders',
  setup: 'setup',
  parameters: 'parameters',
  waste: 'waste',
  quality: 'quality',
  run: 'run',
  finish: 'finish',
  end: 'end',
  tasks: 'tasks',
  shutdown: 'shutdown',
  mounting: 'mounting',
  overview: 'overview',
  primary: 'primary',
  secondary: 'secondary',
  trace: 'trace',
  speedMonitorHost: 'speedMonitor',
  machineChart: 'machineChart',
  documents: 'documents',
  files: 'files',
  information: 'information',
  labels: 'labels',
  shiftReport: 'report',
  workCenterSelection: 'work-center',
  qaLog: 'qa-log',
  qaNc: 'nc',
  qaChecks: 'checks',
  qaHistory: 'history',
  approve: 'approve',
  employeeRegistration: 'employee-registration',
  employeeLogin: 'login',
  employeeHistory: 'history',
  operationSupport: 'operation-support',
  traceability: 'traceability',
  lineClearance: 'line-clearance',
  pattern: 'pattern-info',
  resourceConfiguration: 'setting-configuration',

  routes: {
    root: '',
    ordersList: '/orders',
    setup: '/setup',
    setupParameters: '/setup/parameters',
    setupPatternInfo: '/setup/pattern-info',
    setupQaHistory: '/setup/history',
    wasteAssignment: '/setup/waste',
    qualityAssurance: '/setup/quality',
    setupLineClearance: '/setup/line-clearance',
    run: '/run',
    finish: '/finish',
    finishOverview: '/finish/overview',
    finishEnd: '/finish/end',
    tasks: '/tasks',
    shutdown: '/shutdown',
    mounting: '/mounting',
    mountingOverview: '/mounting/overview',
    mountingPrimary: '/mounting/primary',
    mountingSecondary: '/mounting/secondary',
    mountingTrace: '/mounting/trace',
    speedMonitorHost: '/speed-monitor',
    documents: '/documents',
    files: '/documents/files',
    information: '/documents/information',
    labels: '/documents/labels',
    shiftReport: '/report',
    workCenterSelection: '/workCenter',
    qaLog: '/qa-log',
    qaLogChecks: '/qa-log/checks',
    qaLogNc: '/qa-log/nc',
    qaLogHistory: '/qa-log/history',
    approve: '/approve',
    employeeRegistration: '/employee-registration',
    employeeLogin: '/employee-registration/login',
    employeeHistory: '/employee-registration/history',
    operationSupport: '/operation-support',
    traceability: '/operation-support/traceability',
    resourceConfiguration: '/setting-configuration'
  }
};

export interface SubPhaseRoute {
  route: string;
  subPhase: RunSubPhaseType;
}

export interface PhaseRoute {
  route: string;
  phase: RunPhaseType;
  subPhases?: SubPhaseRoute[];
}

export const setupPhaseFooterRoutes: SubPhaseRoute[] = [
  { route: nav.routes.setupLineClearance, subPhase: RunSubPhaseType.SETUP_LINE_CLEARANCE },
  { route: nav.routes.setupQaHistory, subPhase: RunSubPhaseType.SETUP_QA_HISTORY },
  { route: nav.routes.setupParameters, subPhase: RunSubPhaseType.SETUP_PARAMETER },
  { route: nav.routes.setupPatternInfo, subPhase: RunSubPhaseType.SETUP_PATTERN_INFO },
  { route: nav.routes.qualityAssurance, subPhase: RunSubPhaseType.SETUP_IN_PROGRESS_FINISHING_CHECK },
  { route: nav.routes.wasteAssignment, subPhase: RunSubPhaseType.SETUP_MATERIAL_WASTE }
];

export const finishPhaseFooterRoutes: SubPhaseRoute[] = [
  { route: nav.routes.finishOverview, subPhase: RunSubPhaseType.FINISHING_OVERVIEW },
  { route: nav.routes.finishEnd, subPhase: RunSubPhaseType.FINISHING_END }
];

export const phasesRoutes: PhaseRoute[] = [
  { route: nav.routes.setup, phase: RunPhaseType.SETUP, subPhases: setupPhaseFooterRoutes },
  { route: nav.routes.run, phase: RunPhaseType.RUN },
  { route: nav.routes.finish, phase: RunPhaseType.FINISHING, subPhases: finishPhaseFooterRoutes }
];
