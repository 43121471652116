import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { defaultDropdownValue, DowntimeCodeOptions, TaskFilters } from './task.model';
import { TaskState, TaskStore } from './task.store';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FilterDropdown, TaskType } from '@app/shared/models';
import * as R from 'ramda';
import { WorkCenterTask } from 'chronos-core-client';

@Injectable({ providedIn: 'root' })
export class TaskQuery extends QueryEntity<TaskState, WorkCenterTask> {
  public filters$: Observable<TaskFilters> = this.select((state) => state.filters);
  public typeOptions$: Observable<FilterDropdown[]> = this.selectAll()
    .pipe(map(this.collectDistinctValues(['type'])))
    .pipe(
      map((arr) =>
        arr.map((obj) => {
          if (obj.label !== defaultDropdownValue) {
            obj.label = `TASKS.${obj.label.toUpperCase()}`;
          }
          return obj;
        })
      )
    );
  public productionOrderOptions$: Observable<FilterDropdown[]> = this.selectAll().pipe(
    map(this.collectDistinctValues(['downtime', 'externalProductionOrderId']))
  );
  public allTasks$ = this.filters$.pipe(
    switchMap((filters) =>
      this.selectAll({
        filterBy: (task: WorkCenterTask) =>
          this.filterByTaskStatus(filters, task) &&
          this.filterByTaskType(filters, task) &&
          this.filterByProductionOrder(filters, task) &&
          this.filterBySearchKeyword(filters, task)
      })
    )
  );

  constructor(protected store: TaskStore) {
    super(store);
  }

  private filterByTaskStatus(filters: TaskFilters, task: WorkCenterTask): boolean {
    switch (filters.downtimeCodeAssignation) {
      case DowntimeCodeOptions.All:
        return true;
      case DowntimeCodeOptions.Ok:
        return task.status !== 'NotOk';
      default:
        return task.status === 'NotOk';
    }
  }

  private filterByTaskType(filters: TaskFilters, task: WorkCenterTask): boolean {
    const taskType = filters.taskType;

    if (taskType === TaskType.All || taskType === defaultDropdownValue) {
      return true;
    }

    return task.type === taskType;
  }

  private filterByProductionOrder(filters: TaskFilters, task: WorkCenterTask) {
    const productionOrder = filters.productionOrder;

    if (productionOrder === TaskType.All || productionOrder === defaultDropdownValue) {
      return true;
    } else if (task.downtime.externalProductionOrderId) {
      return task.downtime.externalProductionOrderId.toLowerCase() === productionOrder.toLowerCase();
    }

    return false;
  }

  private filterBySearchKeyword(filters: TaskFilters, task: WorkCenterTask) {
    if (!filters.textQuery) {
      return true;
    } else if (task.downtime.externalProductionOrderId) {
      return task.downtime.externalProductionOrderId.toLowerCase().includes(filters.textQuery.toLowerCase());
    }

    return false;
  }

  private collectDistinctValues(valuePath: string[]) {
    return (tasks) =>
      R.pipe(
        R.map((task: WorkCenterTask) => R.pathOr(null, valuePath, task)),
        R.concat([defaultDropdownValue]),
        R.filter((propertyValue: string) => !R.isNil(propertyValue)),
        R.uniq,
        R.map((propertyValue: string) => ({ value: propertyValue, label: propertyValue }))
      )(tasks);
  }
}
